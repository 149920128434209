import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {Cascader} from "antd";
import noImage from "../../assets/images/no-image.jpg";
import {toast} from "react-toastify";
import PostForResult from "../sections/PostForResult";

class CreateBox extends Component {

    state = {
        categories: null,
        posts: null,
        title: "",
        image: null,
        strImage: "",
        col: "",
        height: "",
        type: "main",
        url: "/",
        postModal: false
    };

    componentDidMount() {
        this.getCategories();
    }

    handleCategoryChange(selected) {
        let arr = selected.includes(",") ? selected.split(',') : selected;

        this.getCategoryData(arr[arr.length - 1]);
    }

    getCategoryData(category) {
        axios.get(`${config.api}/category?id=${category}&token=${config.token}`)
            .then(r => {
                let {title, image, slug} = r.data.category;
                this.setState({
                    title, strImage: r.data.category.image, url: "/category/" + slug
                });
            })
            .catch(err => {

            });
    }

    getLastPosts() {
        axios.get(`${config.api}/posts/last?token=${config.token}`)
            .then(r => {
                this.setState({
                    posts: r.data.posts
                });
            })
            .catch(err => {

            });
    }

    getCategories() {
        axios.get(`${config.apiV1}/categories`)
            .then(r => {
                this.setState({categories: r.data});
                this.getLastPosts();
            })
            .catch(err => {
                console.error(err);
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    handleImageChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({image: arrFiles[0]});

    }

    handleSubmit() {
        const {title, image, strImage, col, height, type, url} = this.state;
        let params = new FormData();
        params.append('title', title);
        params.append('col', col);
        params.append('height', height);
        params.append('type', type);
        params.append('url', url);

        if (image) {
            params.append('image', image);
        } else {
            params.append('strImage', strImage.replace(config.apiV1 + "/", "uploads"));
        }

        axios.post(`${config.api}/box?token=${config.token}`, params, {
            header: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(r => {
                toast.success("با موفقیت ذخیره شد.");
                this.setState({
                    categories: null,
                    title: "",
                    image: null,
                    strImage: "",
                    col: "",
                    height: "",
                    type: "main",
                    url: "/"
                });
                this.getCategories();
            })
            .catch(err => {
                console.error(err);
            });


    }

    render() {
        const {title, categories, posts, image, strImage, col, height, type, url, postModal} = this.state;

        return (
            <div className="create-box">
                {
                    categories && posts ?
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-control">
                                        <label htmlFor="category">وارد کردن از دسته بندی:</label>
                                        <Cascader options={categories.cascaderCategories}
                                                  id="category"
                                                  changeOnSelect
                                                  bordered={false}
                                                  multiple={false}
                                                  style={{width: '100%', borderBottom: "1px solid #cdcdcd"}}
                                                  onChange={(e) => this.handleCategoryChange(e)}
                                                  size={"large"}
                                                  placement={"bottomLeft"}
                                                  expandIcon={<span>&nbsp;</span>}
                                                  placeholder="دسته بندی ..."/>
                                    </div>
                                    <div className="form-control">
                                        <button type="button" onClick={() => {
                                            this.setState({
                                                postModal: true
                                            })
                                        }
                                        }>انتخاب از پست ها ...
                                        </button>
                                    </div>
                                    <br/><br/>
                                </div>
                                <div className="col-8">
                                    <div className="form-control">
                                        <label htmlFor="title">عنوان:</label>
                                        <input type="text"
                                               name="title"
                                               id="title"
                                               placeholder="عنوان"
                                               value={title}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-control">
                                        <label htmlFor="image">
                                            <div>عکس:</div>
                                            <div><img
                                                alt=""
                                                src={image ? URL.createObjectURL(image) : strImage.length > 0 ? strImage : noImage}/>
                                            </div>
                                        </label>
                                        <input type="file"
                                               name="image"
                                               style={{display: "none"}}
                                               onChange={this.handleImageChange.bind(this)}
                                               accept="image/*"
                                               id="image"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-control">
                                        <label htmlFor="col">ستون:</label>
                                        <input type="text"
                                               name="col"
                                               id="col"
                                               style={{direction: "ltr", textAlign: "left"}}
                                               placeholder="col-auto"
                                               value={col}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-control">
                                        <label htmlFor="height">ارتفاع:</label>
                                        <input type="text"
                                               name="height"
                                               id="height"
                                               style={{direction: "ltr", textAlign: "left"}}
                                               placeholder="50px"
                                               value={height}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-control">
                                        <label htmlFor="url">لینک:</label>
                                        <input type="text"
                                               name="url"
                                               id="url"
                                               style={{direction: "ltr", textAlign: "left"}}
                                               placeholder="/"
                                               value={url}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-control">
                                        <label htmlFor="type">بخش:</label>
                                        <input type="text"
                                               name="type"
                                               id="type"
                                               style={{direction: "ltr", textAlign: "left"}}
                                               placeholder="main"
                                               value={type}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <br/><br/><br/><br/>
                                    <div className="form-control">
                                        <button type="button" onClick={this.handleSubmit.bind(this)}>ذخیره و ارسال
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <PostForResult modal={postModal}
                                           posts={posts}
                                           onModalClose={
                                               () => {
                                                   this.setState({postModal: false})
                                               }
                                           }
                                           onPostClick={
                                               (post) => {
                                                   this.setState({
                                                       title: post.title,
                                                       strImage: post.image,
                                                       url: post.url,
                                                       postModal: false
                                                   })
                                               }
                                           }/>
                        </form>
                        : <div>در حال دریافت ...</div>
                }

            </div>
        );
    }
}

export default gpn(CreateBox);