import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/Field";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class NewAccounting extends Component {

    state = {
        subject: "",
        description: "",
        price: "",
        type: "بستانکار",
        clear: 'false'
    };

    handleInputChange(name, value) {
        this.setState({[name]: value});
    }

    submit() {
        const {subject, description, price, type, clear} = this.state;

        let params = new URLSearchParams();
        params.append('subject', subject);
        params.append('description', description);
        params.append('price', price !== "" ? price : "0");
        params.append('type', type);
        params.append('clear', clear);
        toast.info("در حال ارسال ...");

        axios.post(`${config.api}/accounting?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ثبت شد.");
                this.setState({
                    subject: "",
                    description: "",
                    price: "",
                    type: "بستانکار",
                    clear: 'false'
                });
            })
            .catch(err => {
                console.error(err.stack);
                toast.error("خطا!")
            });
    }

    render() {
        const {subject, description, price, type, clear} = this.state;

        return (
            <div className="new-accounting">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Field
                                type="text"
                                name="subject"
                                textAlign="center"
                                value={subject}
                                oc={this.handleInputChange.bind(this)}
                                label="عنوان"
                                placeholder="عنوان ..."/>
                        </div>
                        <div className="col-md-6">
                            <Field
                                type="number"
                                name="price"
                                textAlign="center"
                                value={price}
                                oc={this.handleInputChange.bind(this)}
                                label="مبلغ"
                                placeholder="به تومان"/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="type">نوع حساب:</label>
                                <select name="type" id="type" value={type}
                                        defaultValue="بستانکار"
                                        onChange={(e) => {
                                            const {name, value} = e.target;
                                            this.handleInputChange(name, value);
                                        }}>
                                    <option value="بستانکار">بستانکار</option>
                                    <option value="بدهکار">بدهکار</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="clear">تسویه شده:</label>
                                <select name="clear"
                                        id="clear"
                                        value={clear}
                                        defaultValue="false"
                                        onChange={(e) => {
                                            const {name, value} = e.target;
                                            this.handleInputChange(name, value);
                                        }}>
                                    <option value="false">خیر</option>
                                    <option value="true">بله</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            <Field
                                type="textarea"
                                name="description"
                                value={description}
                                oc={this.handleInputChange.bind(this)}
                                label="توضیحات"
                                placeholder="توضیحات ..."/>
                        </div>

                        <div className="col-md-6">
                            <div className="form-control">
                                <br/><br/><br/>
                                <button type="button"
                                        onClick={this.submit.bind(this)}>ثبت
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default gpn(NewAccounting);