import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/MUI/Field";
import Select from "../sections/MUI/Select";
import PostEditor from "../sections/PostEditor";
import Button from "../sections/MUI/Button";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";
import Icon from "../sections/MUI/Icon";
import VamTarhFields from "../sections/VamTarhFields";

class CreateVamTarh extends Component {

    state = {
        title: "",
        content: "",
        price: "",
        data: {
            shoghl: ""
        },

        enable: "true",

        fields: []
    };

    initState() {
        this.setState({
            title: "",
            content: "",
            price: "",
            data: {
                shoghl: ""
            },
            enable: "true"
        });
    }

    handleSubmit() {
        const {title, content, price, data, enable, fields} = this.state;
        if (title !== "" && price !== "") {
            let params = new URLSearchParams();
            params.append('title', title);
            params.append('content', content);
            params.append('price', price.replace(/,/g, "").toString().trim());
            params.append('data', JSON.stringify(data));
            params.append('enable', enable);
            params.append('fields', JSON.stringify(fields ? fields : []));

            toast.info("در حال ثبت ...");
            axios.post(`${config.api}/vam/tarh?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ثبت شد!");
                    this.initState();
                })
                .catch(err => {
                    config.errorHandler(err);
                });
        } else {
            toast.error("نام طرح و مبلغ را وارد کنید!");
        }
    }

    render() {
        const {title, content, price, data, enable, fields} = this.state;

        return (
            <div className="create-vam-tarh">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <Field type="text"
                                   name="title"
                                   value={title}
                                   label="نام طرح"
                                   onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>

                        <div className="col-md-6">
                            <Select simple
                                    value={enable}
                                    name="enable"
                                    options={[
                                        {t: "وضعیت: فعال", v: "true"},
                                        {t: "وضعیت: غیرفعال", v: "false"}
                                    ]}
                                    onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>

                        <div className="col-md-6">
                            <Field type="text"
                                   typePrice
                                   name="price"
                                   value={price}
                                   maxLength={12}
                                   style={{direction: "ltr"}}
                                   label="مبلغ"
                                   onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>
                        <div className="col-md-6">
                            <Field type="text"
                                   name="shoghl"
                                   value={data.shoghl}
                                   label="شغل"
                                   onChange={(name, value) => {
                                       let d = data;
                                       d[name] = value
                                       this.setState({data: d})
                                   }}/>
                        </div>


                        <div className="col-12">
                            <br/>
                            <VamTarhFields fields={fields} onChange={(value) => this.setState({fields: value})}/>
                            <br/>
                        </div>

                        <div className="col-12">
                            <PostEditor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onChange={(e) => {
                                    this.setState({content: e})
                                }}
                                toolbarClassName="toolbar-class"
                            />
                        </div>

                        <div className="col-12">
                            <br/>
                            <div className="row justify-content-end">
                                <div className="col-md-5">
                                    <Button submit={false} icon="check" onClick={() => this.handleSubmit()}>ثبت
                                        طرح</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default gpn(CreateVamTarh);