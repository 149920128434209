import Icon from "./Icon";
import {useState} from "react";
import React from "react";

function Select({name, label, icon, value, placeholder, options, onChange, simple}) {
    name = name ? name : simple ? "selectSimple" : "select";
    label = label ? label.toString().trim() : "";

    const [mio, setMio] = useState(false);

    if (simple) {
        return (
            <div className="form-control-select-simple">

                {label !== "" ?
                    <label htmlFor={name}>
                        {
                            icon ? <Icon i={icon}/> : null
                        }
                        <span>{label}:</span>
                    </label>
                    : null}


                <select name={name}
                        id={name}
                        value={value}
                        onChange={e => {
                            if (onChange) {
                                onChange(name, e.target.value);
                            }
                        }}>
                    {placeholder ?
                        <option value="">{placeholder}</option>
                        : null}
                    {
                        options?.map((o, i) =>
                            <option value={o.v} key={name + "-option-" + i}>{o.t}</option>
                        )
                    }
                </select>
            </div>
        )
    } else {


        let selectedIcon = "";
        let selectedTitle = "";

        options.map(o => {
            if (o.v === value) {
                if (o.i) {
                    selectedIcon = o.i;
                }
                selectedTitle = o.t;
            }
        });


        return (
            <div className="form-control-select">
                <label htmlFor={name}
                       onClick={() => setMio(!mio)}>
                    {
                        icon ? <Icon i={icon}/> : null
                    }
                    <span>{label}:</span>
                </label>

                <div className="input-select"
                     style={{
                         borderBottomLeftRadius: mio ? "0" : "24px",
                         borderBottomRightRadius: mio ? "0" : "24px"
                     }}
                     onClick={() => {
                         setMio(!mio);
                     }}>
                    <div className="row">
                        {selectedIcon !== "col-auto" ? <div><Icon i={selectedIcon}/></div> : null}
                        <div className="col">
                            <div className="title">{selectedTitle === "" ? placeholder : selectedTitle}</div>
                        </div>
                    </div>
                </div>

                {
                    mio ?
                        <div className="options">
                            {options?.map((o, i) =>
                                <div className="option"
                                     id={name}
                                     style={value === o.v ? {backgroundColor: "#ededed"} : {}}
                                     onClick={() => {
                                         setMio(false);
                                         if (onChange) {
                                             onChange(name, o.v);
                                         }
                                     }}
                                     key={name + "-option-" + i}>
                                    <div className="row">
                                        {
                                            o.i ? o.i !== "" ?
                                                <div className="col-auto">
                                                    <Icon i={o.i}
                                                          style={o.v === value ? {
                                                              marginLeft: "10px",
                                                              marginRight: "22px",
                                                              cursor: "default"
                                                          } : {}}/>
                                                </div>
                                                : null : null
                                        }
                                        <div className="col">
                                            <div className="title">{o.t}</div>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                        : null
                }

            </div>
        )
    }

}

export default Select;