import React, {Component} from 'react';
import {gpn} from "../GPN"
import axios from "axios";
import config from "../config";
import {toast} from "react-toastify";

class Profile extends Component {

    state = {
        fullName: "",
        newUsername: "",

        password: "",
        newPassword: ""
    };


    componentDidMount() {
        this.getInfo();
    }

    getInfo() {
        axios.get(`${config.api}/info?token=${config.token}`)
            .then(r => {
                const {fullName, username} = r.data;
                this.setState({
                    fullName,
                    newUsername: username
                });
            })
            .catch(err => {

            });
    }

    handleInputChanged(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    submitInfo(e) {
        e.preventDefault();
        const {fullName, newUsername} = this.state;

        let params = new URLSearchParams();
        params.append('username', newUsername);
        params.append('fullName', fullName);

        toast.info("در حال ذخیره کردن ...");

        axios.put(`${config.api}/info?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ذخیره شد.")
                this.getInfo();
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!")
            });

    }

    submitPassword(e) {
        e.preventDefault();
        const {password, newPassword} = this.state;
        if (password !== "" && newPassword !== "") {
            let params = new URLSearchParams();
            params.append('password', password);
            params.append('newPassword', newPassword);
            toast.info("در حال ذخیره کردن ...");
            axios.put(`${config.api}/info/password?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ذخیره شد.")
                    this.setState({
                        password: "",
                        newPassword: ""
                    });
                    localStorage.removeItem("token");

                    setTimeout(() => {
                        document.location.href = "/login";
                    }, 1500)
                })
                .catch(err => {
                    if (err.response) {
                        if (err.response.data) {
                            if (err.response.data.message) {
                                toast.error(err.response.data.message);
                            }
                        }
                    }
                });

        } else {
            toast.error("فیلدها را وارد کنید.");
        }
    }

    render() {
        const {fullName, newUsername, password, newPassword} = this.state;
        return (
            <div className="profile">

                <div className="card">
                    <h1>ویرایش مشخصات</h1>
                    <form onSubmit={this.submitInfo.bind(this)} autoComplete="off">

                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="fullName">نام و نام خانوادگی:</label>
                                    <input type="text"
                                           name="fullName"
                                           onChange={this.handleInputChanged.bind(this)}
                                           value={fullName}
                                           style={{textAlign: "center"}}
                                           id="fullName"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="newUsername">نام کاربری:</label>
                                    <input type="text"
                                           name="newUsername"
                                           onChange={this.handleInputChanged.bind(this)}
                                           value={newUsername}
                                           style={{textAlign: "center", direction: "ltr"}}
                                           id="newUsername"/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <br/><br/><br/>
                                <button type="submit">ویرایش مشخصات</button>
                            </div>
                        </div>

                    </form>

                    <br/><br/><br/>
                    <h1>ویرایش گذرواژه</h1>
                    <form onSubmit={this.submitPassword.bind(this)} autoComplete="off">

                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="form-control">
                                    <label htmlFor="password">گذرواژه فعلی:</label>
                                    <input type="password"
                                           name="password"
                                           onChange={this.handleInputChanged.bind(this)}
                                           value={password}
                                           style={{textAlign: "center", direction: "ltr"}}
                                           id="password"/>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-control">
                                    <label htmlFor="newPassword">گذرواژه جدید:</label>
                                    <input type="text"
                                           name="newPassword"
                                           onChange={this.handleInputChanged.bind(this)}
                                           value={newPassword}
                                           style={{textAlign: "center", direction: "ltr"}}
                                           id="newPassword"/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <br/><br/><br/>
                                <button type="submit">ذخیره گذرواژه</button>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        );
    }
}

export default gpn(Profile);