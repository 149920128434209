import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/Field";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";

class NewCustomer extends Component {

    state = {
        fullName: "",
        product: "",
        ticketNo: "",
        status: "ثبت اولیه",
        buyType: "خرید تهران",
        clear: "false",
        price: "",
        profit: "",
    };

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleInputChange(name, value) {
        this.setState({[name]: value});
    }

    submit() {
        const {fullName, product, ticketNo, status, buyType, clear, price, profit} = this.state;
        let params = new URLSearchParams();
        params.append('fullName', fullName);
        params.append('product', product);
        params.append('ticketNo', ticketNo);
        params.append('status', status);
        params.append('buyType', buyType);
        params.append('clear', clear);
        params.append('price', price);
        params.append('profit', profit);

        toast.info("در حال ثبت ...");
        axios.post(`${config.api}/customer?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ثبت شد.");
                this.setState({
                    fullName: "",
                    product: "",
                    ticketNo: "",
                    status: "ثبت اولیه",
                    buyType: "خرید تهران",
                    clear: "false",
                    price: "",
                    profit: ""
                });
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    render() {
        const {fullName, product, ticketNo, status, buyType, clear, price, profit} = this.state;
        return (<div className="new-customer">

            <form autoComplete="off" onSubmit={event => event.preventDefault()}>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Field
                            name="fullName"
                            value={fullName}
                            textAlign="center"
                            oc={this.handleInputChange.bind(this)}
                            label="نام و نام خانوادگی"
                            placeholder="نام و نام خانوادگی"/>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="product"
                            textAlign="center"
                            value={product}
                            dir="ltr"
                            oc={this.handleInputChange.bind(this)}
                            label="نام محصول"
                            placeholder="نام محصول"/>
                    </div>
                    <div className="col-md-7">
                        <br/>
                        <Field
                            name="ticketNo"
                            value={ticketNo}
                            textAlign="center"
                            dir="ltr"
                            oc={this.handleInputChange.bind(this)}
                            label="شماره تیکت"
                            placeholder="شماره تیکت"/>
                        <br/>
                    </div>
                    <div className="col-md-6">
                        <Field
                            type="number"
                            name="price"
                            value={price}
                            textAlign="center"
                            dir="ltr"
                            oc={this.handleInputChange.bind(this)}
                            label="مبلغ به تومان"
                            placeholder="مبلغ"/>
                    </div>
                    <div className="col-md-6">
                        <Field
                            type="number"
                            name="profit"
                            value={profit}
                            textAlign="center"
                            dir="ltr"
                            oc={this.handleInputChange.bind(this)}
                            label="سود به تومان"
                            placeholder="سود"/>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        <div className="form-control">
                            <label htmlFor="status">وضعیت:</label>
                            <select name="status"
                                    value={status}
                                    onChange={e => {
                                        const {name, value} = e.target;
                                        this.setState({[name]: value})
                                    }}
                                    id="status">
                                <option value="ثبت اولیه">ثبت اولیه</option>
                                <option value="تخصیص اعتبار">تخصیص اعتبار</option>
                                <option value="تکمیل سفارش">تکمیل سفارش</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        <div className="form-control">
                            <label htmlFor="buyType">نحوه خرید:</label>
                            <select name="buyType"
                                    value={buyType}
                                    onChange={e => {
                                        const {name, value} = e.target;
                                        this.setState({[name]: value})
                                    }}
                                    id="buyType">
                                <option value="خرید تهران">خرید تهران</option>
                                <option value="خرید از موجودی انبار">خرید از موجودی انبار</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-control">
                            <label htmlFor="clear">تسویه سود:</label>
                            <select name="clear"
                                    value={clear}
                                    onChange={e => {
                                        const {name, value} = e.target;
                                        this.setState({[name]: value})
                                    }}
                                    id="clear">
                                <option value="false">تسویه نشده</option>
                                <option value="true">تسویه شده</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <br/><br/><br/>
                        <div className="form-control">
                            <button type="button" onClick={() => this.submit()}>ثبت مشتری</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>);
    }
}

export default gpn(NewCustomer);