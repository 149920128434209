import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/global.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App/>
        <ToastContainer
            position="top-center"
            closeButton={false}
            closeOnClick
            rtl
            draggable
            pauseOnHover={false}
            autoClose={3000}/>

    </BrowserRouter>
);