import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/MUI/Field";
import File from "../sections/MUI/File";
import PostEditor from "../sections/PostEditor";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";
import Button from "../sections/MUI/Button";

class CreateHamkar extends Component {

    state = {
        title: "", description: "",

        image: null,
    };

    handleSubmit() {
        const {title, description, image} = this.state;
        if (title !== "" && image) {
            toast.info("در حال ثبت ...");
            let params = new FormData();
            params.append('title', title);
            params.append('description', description);
            params.append('image', image);

            axios.post(`${config.api}/hamkar?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    toast.success("با موفقیت ثبت شد.");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    config.errorHandler(err);
                });
        } else {
            toast.error("همه فیلد ها را وارد کنید");
        }
    }

    render() {
        const {title, description, image} = this.state;

        return (<div className="create-hamkar">
            <div className="row">
                <div className="col-md-6">
                    <Field name="title"
                           value={title}
                           onChange={(name, value) => this.setState({[name]: value})}
                           label="نام همکار، فروشگاه یا ..."/>

                </div>
                <div className="col-md-6">
                    <File name="image"
                          label="عکس یا لوگو"
                          value={image}
                          onChange={(name, value) => this.setState({[name]: value})}
                          multiple={false}
                          accept=".png,.bmp,.gif,.jpg,.jpeg"/>
                </div>

                <div className="col-12">
                    <PostEditor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        onChange={(e) => {
                            this.setState({description: e})
                        }}
                        toolbarClassName="toolbar-class"
                    />
                    <br/><br/>
                    <div className="row justify-content-end">
                        <div className="col-md-5">
                            <Button icon="check" submit={false} onClick={() => this.handleSubmit()}>ثبت کردن</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default gpn(CreateHamkar);