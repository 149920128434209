import {toast} from "react-toastify";

export default {
    appURL: "https://takinanoosh.ir",
    api: "https://api.takinanoosh.ir/main",
    apiV1: "https://api.takinanoosh.ir",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",

    priceFormatter: (price) => {
        let mPrice = typeof price === "string" ? Number(price) : price;
        let formatter = new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRT',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
        return formatter.format(mPrice);
    },
    random: (min, max) => {
        return Math.floor(Math.random() * max) + min;
    },

    isLTR: (str) => {
        let mStr = str.toLowerCase();
        let ltr = false;


        if (mStr.includes("a") || mStr.includes("b") || mStr.includes("c") ||
            mStr.includes("d") || mStr.includes("a") || mStr.includes("f") || mStr.includes("g")
            || mStr.includes("h") || mStr.includes("i") || mStr.includes("j") || mStr.includes("k")
            || mStr.includes("l") || mStr.includes("m") || mStr.includes("n") || mStr.includes("o")
            || mStr.includes("p") || mStr.includes("q") || mStr.includes("r") || mStr.includes("s")
            || mStr.includes("t") || mStr.includes("u") || mStr.includes("v") || mStr.includes("w")
            || mStr.includes("x") || mStr.includes("y") || mStr.includes("z")) {
            ltr = true;
        }

        return ltr;
    },

    errorHandler: (err) => {
        let errMessage = "خطایی رخ داده است!";
        if (err) {
            if (err.response) {
                if (err.response) {
                    if (err.response.data) {
                        const {message, error} = err.response.data;
                        if (message) {
                            errMessage = message;
                        } else if (error) {
                            errMessage = message;
                        }
                    }
                }
            }
        }
        toast.error(errMessage);

        return errMessage;
    }
}