import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import noImage from "../../assets/images/no-image.jpg";

class Agents extends Component {

    state = {
        agents: [],
        search: ""
    };

    componentDidMount() {
        this.getAgents();
    }

    getAgents(search = "") {
        axios.get(`${config.api}/agent/list?token=${config.token}&search=${search}`)
            .then(r => {
                this.setState({agents: r.data.agents});
            })
            .catch(err => {
            });
    }

    render() {
        const {agents} = this.state;
        return (
            <div className="agents">
                <div className="list">
                    {
                        agents?.map((a, i) =>
                            <article key={"agent" + i}
                                     onClick={() => this.props.navigate("/agent/" + a.id)}
                                     className={a.isVerified ? "item active" : "item"}>
                                <div className="row">
                                    <div className="col-auto">
                                        <img
                                            src={a.profilePhoto ? a.profilePhoto : a.storeImage ? a.storeImage : noImage}
                                            alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">

                                                <p className="full-name">{a.information.fullName ? a.information.fullName.value : ""}</p>
                                                <p className="national-code">{a.information.nationalCode ? a.information.nationalCode.value : ""}</p>
                                            </div>
                                            <div className="col-auto">
                                                <p className="count-documents">
                                                    <span>مدارک ارسالی:</span><span>{a.documents}</span></p>
                                                <p className="phone-number">{a.phoneNumber}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </article>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default gpn(Agents);