import React, {Component} from 'react';
import {gpn} from "../../GPN";
import noImage from "../../assets/images/no-image.jpg";
import * as htmlToImage from "html-to-image";
import config from "../../config";

class AdsImageMaker extends Component {

    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
    }

    state = {
        image: this.props.obj.images?.length > 0 ? this.props.obj.images[0] : noImage
    }

    createFileName = (extension = "", ...names) => {
        if (!extension) {
            return "";
        }

        return `${names.join("")}.${extension}`;
    };

    takeScreenShot = async () => {
        return await htmlToImage.toPng(this.imageRef.current, {
            quality: 1, style: {background: "white"},
        });
    };

    download = (image, {name = config.random(10000, 9999999).toString() + "-img", extension = "png"} = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = this.createFileName(extension, name);
        a.click();
    };

    toEnglishDigits = (str) => {
        return str.replace(/۰/g, "0")
            .replace(/۱/g, "1")
            .replace(/۲/g, "2")
            .replace(/۳/g, "3")
            .replace(/۴/g, "4")
            .replace(/۵/g, "5")
            .replace(/۶/g, "6")
            .replace(/۷/g, "7")
            .replace(/۸/g, "8")
            .replace(/۹/g, "9")
            .replace("صفر", "0")
            .replace("یک", "1")
            .replace("دو", "2")
            .replace("سه", "3")
            .replace("چهار", "4")
            .replace("پنج", "5")
            .replace("شش", "6")
            .replace("هقت", "7")
            .replace("هشت", "8")
            .replace("نه", "9")
            .replace("ده", "10")
            .replace("یازده", "11")
            .replace("دوازده", "12")
            .replace("سیزده", "13")
            .replace("چهارده", "14")
            .replace("پانزده", "15")
            .replace("شانزده", "16")
            .replace("هفده", "17")
            .replace("هجده", "18")
            .replace("نوزده", "19")
            .replace("بیست", "20")
            .replace("بیست و یک", "21")
            .replace("بیست و دو", "22")
            .replace("بیست و سه", "23")
            .replace("بیست و چهار", "24")
            .replace("بیست و پنج", "25")
            .replace("بیست و شش", "26")
            .replace("بیست و هفت", "27")
            .replace("بیست و هشت", "28")
            .replace("بیست و نه", "29")
            .replace("سی", "30")
            .replace("سی و یک", "31")
            .replace("سی و دو", "32")

    }

    changeImage(images) {
        let index = config.random(0, images?.length > 0 ? images?.length : 0);

        this.setState({
            image: images[index]
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                image: this.props.obj.images?.length > 0 ? this.props.obj.images[0] : noImage
            });
        }
    }

    render() {
        const {obj, type} = this.props;
        let {ram, cpu, hdd, images, memory, seriCPU} = obj;
        cpu = cpu ? cpu : seriCPU ? seriCPU : "";
        hdd = hdd ? hdd : memory ? memory : "";
        ram = ram.replace(/ /g, "").replace("گیگابایت", "") + "GB";
        ram = this.toEnglishDigits(ram);
        hdd = hdd.replace(/ /g, "")
            .replace("گیگابایت", "")
            .replace("GB", "")
            .replace("gb", "")
            .replace("ترابایت", "") + ((hdd.toLowerCase().includes("tb") || hdd.includes("ترابایت")) ? "TB" : "GB");

        hdd = this.toEnglishDigits(hdd);
        cpu = this.toEnglishDigits(cpu);

        const {image} = this.state;

        return (<div className="ads-image-maker">
            <div className="form-control">
                <button onClick={() => this.takeScreenShot().then(this.download)}>دانلود کاور</button>
                <br/><br/><br/><br/>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="image-container"
                         ref={this.imageRef}>
                        <img src={image} alt="" className="back"
                             onClick={this.changeImage.bind(this, images)}/>
                        <p className="subject"
                           style={{fontSize: obj.subject.toString().length >= 55 ? "12pt" : "13pt"}}>{obj.subject}</p>

                        <div className="contact">
                            <span className="material">call</span>
                            <span>021-91309562</span>
                        </div>
                        <div className="attrs">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="attr" style={{fontSize: cpu.length >= 35 ? "11pt" : "12pt"}}>
                                        <span>RAM:</span>
                                        <span>{ram}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="attr" style={{fontSize: cpu.length >= 35 ? "11pt" : "12pt"}}>
                                        <span>CPU:</span>
                                        <span>{cpu}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="attr" style={{fontSize: cpu.length >= 35 ? "11pt" : "12pt"}}>
                                        <span>HDD:</span>
                                        <span>{hdd}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="overlay"
                             onClick={this.changeImage.bind(this, images)}>
                            <p>TakinAnoosh.ir</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default gpn(AdsImageMaker);