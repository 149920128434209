import React, {Component} from 'react';
import {gpn} from "../GPN"
import axios from "axios";
import config from "../config";
import {toast} from "react-toastify";

class InitAdmin extends Component {

    state = {
        username: "", password: "", fullName: ""
    };

    componentDidMount() {
        this.props.setTitle("ایجاد ادمین");
        this.checkInit();
    }

    checkInit() {
        axios.get(`${config.api}/init/check`)
            .then(r => {

                if (r.data.success) {
                    if (r.data.false) {
                        document.location.href = "/login";
                    }
                }
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    init(e) {
        e.preventDefault();

        const {username, password, fullName} = this.state;
        let params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('fullName', fullName);

        axios.post(`${config.api}/init`, params)
            .then(r => {
                const {token, success} = r.data;
                if (success) {
                    localStorage.setItem("token", token);
                    toast.success("خوش آمدید ...");
                    setTimeout(() => {
                        document.location.href = "/";
                    }, 3000);
                }
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    render() {
        const {username, password, fullName} = this.state;

        return (
            <div className="init-admin">
                <form onSubmit={this.init.bind(this)} autoComplete="off">
                    <h1>ایجاد ادمین</h1>
                    <div className="form-control">
                        <label htmlFor="fullName">نام و نام خانوادگی:</label>
                        <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            style={{
                                textAlign: "center"
                            }}
                            value={fullName}
                            placeholder="نام و نام خانوادگی"
                            onChange={this.handleInputChange.bind(this)}/>
                    </div>
                    <div className="form-control">
                        <label htmlFor="username">نام کاربری:</label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            style={{
                                direction: "ltr",
                                textAlign: "center"
                            }}
                            value={username}
                            placeholder="username"
                            onChange={this.handleInputChange.bind(this)}/>
                    </div>
                    <div className="form-control">
                        <label htmlFor="password">گذرواژه:</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            style={{
                                direction: "ltr",
                                textAlign: "center"
                            }}
                            value={password}
                            placeholder="password"
                            onChange={this.handleInputChange.bind(this)}/>
                    </div>

                    <div className="form-control">
                        <br/>
                        <button type="submit">ایجاد کردن</button>
                    </div>

                </form>
            </div>
        );
    }
}

export default gpn(InitAdmin);