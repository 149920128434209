import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import Icon from "../sections/MUI/Icon";
import {toast} from "react-toastify";

class VamTarhs extends Component {

    state = {
        vamTarhs: null,
    };

    componentDidMount() {
        this.getVamTarhs();
    }

    getVamTarhs() {
        axios.get(`${config.apiV1}/vam/tarhs`)
            .then(r => {
                this.setState({vamTarhs: r.data.vamTarhs});
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleDelete(id) {
        toast.info("در حال حذف ...");
        axios.delete(`${config.api}/vam/tarh?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                this.getVamTarhs();
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    render() {
        const {vamTarhs} = this.state;
        return (
            <div className="vam-tarhs">
                {vamTarhs ?
                    vamTarhs?.map((vt, i) =>
                        <article key={"vt" + i}>

                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="title">{vt.title + ` (${vt.enable ? "فعال" : "غیرفعال"})`}</p>
                                </div>
                                <div className="col-auto">
                                    <p className="price">
                                        {config.priceFormatter(vt.price).replace("IRT", "تومان")}
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <div>

                                        <Icon i="edit" className="edit"
                                              onClick={() => this.props.navigate(`/vam/tarh/${vt.id}`)}/>

                                        <Icon i="trash" className="delete"
                                              onDoubleClick={() => this.handleDelete(vt.id)}/>
                                    </div>
                                </div>
                            </div>
                        </article>
                    )
                    : <p>در حال دریافت ...</p>
                }
            </div>
        );
    }
}

export default gpn(VamTarhs);