import React, {Component} from 'react';
import {gpn} from "../../GPN"
import PostEditor from "../sections/PostEditor";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import {EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import Modal from "../sections/Modal";
import Fab from "../sections/Fab";

class About extends Component {

    state = {
        about: "",

        uploadModal: false,
        img: null,
        uploadUrl: ""
    };

    componentDidMount() {
        this.getAbout();
    }

    getAbout() {
        axios.get(`${config.apiV1}/about`)
            .then(r => {
                this.setState({about: r.data.about ? r.data.about : ""});
            })
            .catch(err => {
                console.error(err);
            });
    }

    setAbout() {
        const {about} = this.state;
        let params = new URLSearchParams();
        params.append('content', about);
        axios.post(`${config.api}/about?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ذخیره شد.");
                this.getAbout();
            })
            .catch(err => {

            });
    }

    uploadImage(img) {
        if (img) {
            toast.success("در حال آپلود ...")
            let params = new FormData();
            params.append('img', img);

            axios.post(`${config.api}/upload/image?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    this.setState({uploadUrl: r.data.url});
                    toast.success("با موفقیت آپلود شد.")
                })
                .catch(err => {
                    toast.error("خطا!")
                });
        }
    }


    render() {
        const {about, uploadUrl} = this.state;
        return (
            <div className="about">
                <PostEditor
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    content={about}
                    editorState={EditorState.createWithContent(ContentState.createFromText(about))}
                    onChange={(e) => {
                        this.setState({about: e})
                    }}
                    toolbarClassName="toolbar-class"
                />
                <div className="row">
                    <div className="col-md-6">
                        <button type="button" onClick={this.setAbout.bind(this)}>ذخیره</button>
                    </div>
                </div>
                <Fab icon="cloud_upload" size={14} onClick={() => {
                    this.setState({
                        uploadModal: true
                    })
                }}/>
                <Modal show={this.state.uploadModal}
                       title="آپلود عکس"
                       onCloseClick={() => {
                           this.setState({
                               uploadModal: false
                           })
                       }}>
                    <div className="form-control">
                        <label htmlFor="img">عکس:</label>
                        <input type="file"
                               name="img"
                               id="img"
                               onChange={(e) => {
                                   const {files} = e.target;
                                   const arrFiles = Array.from(files);

                                   this.setState({
                                       img: arrFiles[0]
                                   });
                                   this.uploadImage(arrFiles[0])
                               }}/>
                        {
                            uploadUrl !== "" ?
                                <input type="text" name="uploadUrl" id="uploadUrl" value={uploadUrl}/>
                                : null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

export default gpn(About);