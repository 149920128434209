import Icon from "./Icon";

function File({name, label, value, icon, accept, multiple, onChange}) {
    name = name ? name : "selectFile";
    label = label ? label.toString().trim() : "انتخاب فایل ...";
    icon = icon ? icon : multiple ? "files" : "file";
    accept = accept ? accept : ".png,.bmp,.gif,.jpg,.jpeg,.pdf,.zip,.doc,.docx,.xlsx,.xls,.mp4";

    let labelBodyClassName = "body";

    if (multiple) {
        if (value?.length > 0) {
            labelBodyClassName = "body active";
        }
    } else {
        if (value) {
            labelBodyClassName = "body active";
        }
    }
    return (
        <div className="form-control-file">
            <div className="row">
                <div className="col-auto">
                    <label className="label-main" htmlFor={name}>
                        {
                            icon !== "" ?
                                <Icon i={icon}/>
                                : null
                        }
                        <span className="title">{label}</span>
                    </label>
                </div>
                <div className="col">
                    <label htmlFor={name} className={labelBodyClassName}>
                        {multiple ?
                            value?.length > 0 ?
                                `${value?.length} فایل انتخاب شده`
                                : "هیچ فایلی انتخاب نشده ..."
                            : value ?
                                value.name.toString()
                                : "فایلی انتخاب نشده ..."
                        }
                    </label>
                </div>
            </div>


            <input type="file"
                   style={{display: "none"}}
                   name={name}
                   id={name}
                   onChange={(e) => {
                       const {files} = e.target;
                       const arrFiles = Array.from(files);
                       if (arrFiles?.length > 0) {
                           if (onChange) {
                               if (multiple) {
                                   onChange(name, arrFiles);
                               } else {
                                   onChange(name, arrFiles[0]);
                               }
                           }
                       }
                   }}
                   accept={accept}
                   multiple={multiple}/>
        </div>
    )
}

export default File;