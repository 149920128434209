function Icon({
                  i,
                  regular, solid, light, thin,
                  onClick, onDoubleClick,
                  style, className
              }) {

    className = className ? " " + className + " " : " ";
    className = "ficon" + className;

    let type = "duotone";

    if (regular) {
        type = "regular";
    }

    if (light) {
        type = "light";
    }

    if (solid) {
        type = "solid";
    }

    if (thin) {
        type = "thin";
    }

    className = className + "fa-" + type + " ";

    className = className + "fa-" + (i ? i.toString().trim() : "");

    return (
        <span style={{...style}} className={className}
              onDoubleClick={() => {
                  if (onDoubleClick) {
                      onDoubleClick()
                  }
              }}
              onClick={() => {
                  if (onClick) {
                      onClick();
                  }
              }}></span>
    )
}

export default Icon;