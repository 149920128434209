import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Categories extends Component {

    state = {
        categories: {
            categories: []
        }
    };

    componentDidMount() {
        this.getCategories();
    }


    getCategories() {
        axios.get(`${config.apiV1}/categories`)
            .then(r => {
                window.scrollTo(0, 0);
                this.setState({
                    categories: r.data
                });
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    categoryClick(cat) {
        this.props.navigate(`/category/${cat._id}`);
    }

    renderChildrenCategories(children) {
        return children ? children.length > 0 ?
            <div className="sub-categories">
                {
                    children.map((scat, i) =>
                        <div
                            key={"scat" + i} className="row">
                            <div className="col-auto">
                                <div
                                    className="title"
                                    onClick={this.categoryClick.bind(this, scat)}>-&nbsp;{scat.title}</div>
                                <div>
                                    {this.renderChildrenCategories(scat.children)}
                                </div>
                            </div>

                        </div>
                    )
                }
            </div> : null : null;

    }

    render() {
        const {categories} = this.state.categories;
        return (
            <div className="categories">
                <div className="row">
                    <div className="new-category" onClick={() => this.props.navigate('/category/create')}><span>+</span><span>دسته بندی جدید</span>
                    </div>
                </div>
                {
                    categories ?
                        categories.length > 0 ?
                            categories.map((c, i) =>
                                <div id={"c" + i} className="category" key={"c__" + i}>

                                    <div
                                        className="title"
                                        onClick={this.categoryClick.bind(this, c)}>{c.title}
                                    </div>
                                    {
                                        c.children ? this.renderChildrenCategories(c.children)
                                            : null
                                    }

                                </div>
                            )
                            : <div>لیست دسته بندی خالی است</div>
                        : null
                }
            </div>
        );
    }
}

export default gpn(Categories);