import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Order extends Component {

    state = {
        user: {
            fullName: "",
            phoneNumber: ""
        },
        fullName: "",
        phoneNumber: "",
        tel: "",
        address: "",
        postalCode: "",
        status: "",
        created_at: "",
        amount: "",
        cart: [],
        paymentMethod: "",
        otherData: null,

        editStatus: ""

    };

    componentDidMount() {
        this.getOrderData(this.props.params.id);
    }

    getOrderData(id) {
        axios.get(`${config.api}/payment/order?token=${config.token}&id=${id}`)
            .then(r => {
                const {
                    user,
                    fullName,
                    phoneNumber,
                    tel,
                    address,
                    postalCode,
                    status,
                    created_at,
                    amount,
                    cart,
                    paymentMethod,
                    otherData
                } = r.data.order

                this.setState({
                    user,
                    fullName,
                    phoneNumber,
                    tel,
                    address,
                    postalCode,
                    status,
                    created_at,
                    amount,
                    cart,
                    paymentMethod,
                    otherData
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    handleSubmitStatus(status) {
        const id = this.props.params.id;
        let params = new URLSearchParams();
        params.append('id', id);
        params.append('status', status);
        axios.put(`${config.api}/payment/order/status?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });
    }

    delete() {
        toast.error("در حال حذف ...")
        const id = this.props.params.id;
        axios.delete(`${config.api}/payment/order?token=${config.token}&id=${id}`)
            .then(r => {
                toast.error("با موفقیت حذف شد.");
                setTimeout(() => {
                    this.props.navigate(-1);
                }, 1500);
            })
            .catch(err => {
                toast.error("خطا!")
            });
    }

    renderStatus(paymentMethod, status) {
        switch (paymentMethod) {
            case "نقد":
                return (
                    <div className="form-control">
                        <select name="status" id="status"
                                defaultValue={status}
                                onChange={(e) => {
                                    const {value} = e.target;
                                    this.setState({
                                        status: value
                                    });
                                    this.handleSubmitStatus(value);
                                }}>
                            <option value="پرداخت نشده"
                                    selected={status === "پرداخت نشده"}>پرداخت نشده
                            </option>
                            <option value="پرداخت موفق - در دست بررسی"
                                    selected={status === "پرداخت موفق - در دست بررسی"}>پرداخت موفق - در دست بررسی
                            </option>
                            <option value="پرداخت موفق - ارسال شده"
                                    selected={status === "پرداخت موفق - ارسال شده"}>پرداخت
                                موفق - ارسال شده
                            </option>
                        </select>
                    </div>
                );
            case "چک":
                return (
                    <div className="form-control">
                        <select name="status" id="status"
                                defaultValue={status}
                                onChange={(e) => {
                                    const {value} = e.target;
                                    this.setState({
                                        status: value
                                    });
                                    this.handleSubmitStatus(value);
                                }}>
                            <option value="پرداخت نشده - پرداخت چکی"
                                    selected={status === "پرداخت نشده - پرداخت چکی"}>پرداخت نشده - پرداخت چکی
                            </option>
                            <option value="پرداخت موفق - در دست بررسی"
                                    selected={status === "پرداخت موفق - در دست بررسی"}>پرداخت موفق - در دست بررسی
                            </option>
                            <option value="پرداخت موفق - ارسال شده"
                                    selected={status === "پرداخت موفق - ارسال شده"}>پرداخت
                                موفق - ارسال شده
                            </option>
                        </select>
                    </div>
                );
            default:
                return (<div className="status">{status}</div>);
        }
    }

    render() {
        const {
            user,
            fullName,
            phoneNumber,
            tel,
            address,
            postalCode,
            status,
            created_at,
            amount,
            cart,
            paymentMethod,
            otherData
        } = this.state;

        return (
            <div className="order-data">
                <div className="user">
                    <h1>اطلاعات کاربر</h1>
                    <div className="row">
                        <div className="col-md-6">
                            <span>نام و نام خانوادگی:</span><span>{user.fullName}</span>
                        </div>
                        <div className="col-md-6">
                            <span>شماره موبایل:</span><span>{user.phoneNumber}</span>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div className="body">
                    <h1>سفارش</h1>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="payment-method"
                                 style={{
                                     backgroundColor: paymentMethod === "نقد" ? "#0e5480" :
                                         paymentMethod === "چک" ? "#b9174e" : paymentMethod === "وام" ? "#49288d" : "#97342b"
                                 }}>{paymentMethod}</div>
                        </div>
                        <div className="col-md-6">
                            {this.renderStatus(paymentMethod, status)}
                        </div>
                        <div className="col-md-6">
                            <div className="full-name">{fullName}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="phone-number">{phoneNumber}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="tel">{tel}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="address">{address}</div>
                        </div>
                        <div className="col-md-6">
                            <div className="postal-code">{postalCode}</div>
                        </div>
                        <div className="col-12">
                            <div className="cart-data">
                                {
                                    cart?.map((c, i) =>
                                        <div key={"c" + i} className="cart-data-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <img
                                                        src={c.images ? c.images.length > 0 ? c.images[0] : "" : ""}
                                                        alt=""/>
                                                </div>
                                                <div className="col">
                                                    <div className="title">{c.title + " (" + c.count + ")"}</div>
                                                    <div className="price">{config.priceFormatter(c.price)}</div>
                                                </div>
                                                <div className="col-auto">
                                                    <span className="material"
                                                          onClick={() => window.open(`${config.appURL}/product/${c.slug}`, "_blank")}>arrow_back</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            paymentMethod === "چک" ?
                                <div className="col-12">
                                    <div className="chek">
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <span>جمع کل اقساط:&nbsp;</span><span>{config.priceFormatter(otherData?.percentOfMonths)}</span>
                                            </div>
                                            <div className="col-6">
                                                <span>مبلغ هر قسط:&nbsp;</span><span>{config.priceFormatter(otherData?.pricePerGhest)}</span>
                                            </div>
                                            <div className="col-12">
                                                <div className="sum">
                                                    <span>جمع کل:</span><span>{config.priceFormatter(amount)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <div className="col-12">{created_at}</div>
                    </div>
                </div>
                <br/><br/>
                <div className="opt">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" style={{backgroundColor: "#9c1116"}}
                                    onDoubleClick={this.delete.bind(this)}>حذف کردن سفارش
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default gpn(Order);