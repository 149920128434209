import React, {Component} from 'react';
import {gpn} from "../../GPN"
import {Link} from "react-router-dom";
import SideBarCollapse from "./SideBarCollapse";
import Icon from "./Icon";

class SideBar extends Component {


    checkRefClick() {
        const {checkRef} = this.props;
        if (checkRef) {
            checkRef.current.checked = false;
        }
    }

    render() {
        const {tokenIsValid, mio} = this.props;
        return (
            <div className="col-3">

                {tokenIsValid ?
                    <div className="sidebar">
                        <Link to="/" onClick={() => this.checkRefClick()}>
                            <span>داشبورد</span><span className="material">arrow_back</span>
                        </Link>

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="کاربران"
                            chi={[
                                {
                                    title: "لیست کاربران",
                                    url: "/users"
                                },
                                {
                                    title: "سفارشات",
                                    url: "/orders"
                                },
                                {
                                    title: "مدارک ارسالی",
                                    url: "/documents"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="طرح های تسهیلات"
                            chi={[
                                {
                                    title: "لیست طرح ها",
                                    url: "/vam/tarhs"
                                },
                                {
                                    title: "ثبت طرح جدید",
                                    url: "/vam/tarh/create"
                                }
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="نمایندگان"
                            chi={[
                                {
                                    title: "لیست نمایندگان",
                                    url: "/agents"
                                },
                                {
                                    title: "لیست مشتریان",
                                    url: "/agent/customers"
                                },
                                {
                                    title: "لیست محصولات",
                                    url: "/agent/products"
                                }
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="همکاران"
                            chi={[
                                {
                                    title: "ثبت همکار",
                                    url: "/hamkar/create"
                                },
                                {
                                    title: "لیست همکاران",
                                    url: "/hamkars"
                                },
                            ]}
                        />
                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="سازمان ها"
                            chi={[
                                {
                                    title: "لیست سازمان ها",
                                    url: "/sazmans"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="محصولات"
                            chi={[
                                {
                                    title: "ایجاد محصول",
                                    url: "/product/create"
                                },
                                {
                                    title: "لیست محصولات",
                                    url: "/products"
                                },
                                {
                                    title: "دسته بندی",
                                    url: "/product/categories"
                                },
                                {
                                    title: "باکس محصولات",
                                    url: "/product/boxes"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="وبلاگ"
                            chi={[
                                {
                                    title: "پست جدید",
                                    url: "/post/create"
                                },
                                {
                                    title: "لیست پست ها",
                                    url: "/posts"
                                },
                                {
                                    title: "دسته بندی",
                                    url: "/categories"
                                },
                                {
                                    title: "باکس ها",
                                    url: "/boxes"
                                },
                            ]}
                        />


                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="اسلایدر"
                            chi={[
                                {
                                    title: "ایجاد اسلاید",
                                    url: "/slide/create"
                                },
                                {
                                    title: "لیست اسلایدها",
                                    url: "/slides"
                                }
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="اخبار"
                            chi={[
                                {
                                    title: "ایجاد خبر",
                                    url: "/news/parse"
                                },
                                {
                                    title: "لیست اخبار",
                                    url: "/news"
                                }
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="مشتریان خرید کالا"
                            chi={[
                                {
                                    title: "مشتری جدید",
                                    url: "/customer/new"
                                },
                                {
                                    title: "لیست مشتریان",
                                    url: "/customers"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="مدیران"
                            chi={[
                                {
                                    title: "ایجاد مدیر",
                                    url: "/admin/new"
                                },
                                {
                                    title: "مدیران",
                                    url: "/admins"
                                }
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="تماس ابری"
                            chi={[
                                {
                                    title: "لیست تماس ها",
                                    url: "/calls"
                                }
                            ]}
                        />


                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="حسابداری"
                            chi={[
                                {
                                    title: "حساب کتاب جدید",
                                    url: "/accounting/new"
                                },
                                {
                                    title: "لیست حساب ها",
                                    url: "/accountings"
                                },
                                {
                                    title: "خدمات مجید",
                                    url: "/majid/services"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="تنظیمات"
                            chi={[
                                {
                                    title: "آگهی ساز",
                                    url: "/ads"
                                },
                                {
                                    title: "پروفایل",
                                    url: "/profile"
                                },
                                {
                                    title: "Nav Items",
                                    url: "/nav"
                                },
                                {
                                    title: "درباره ما",
                                    url: "/about"
                                },
                                {
                                    title: "قوانین و مقررات",
                                    url: "/terms"
                                },
                                {
                                    title: "تماس با ما",
                                    url: "/contact"
                                },
                                {
                                    title: "USSD",
                                    url: "/ussd"
                                },
                            ]}
                        />

                        <SideBarCollapse
                            checkRefClick={this.checkRefClick.bind(this)}
                            title="مانیتورینگ"
                            chi={[
                                {
                                    title: "خطاها",
                                    url: "/monitoring/errors"
                                },
                                {
                                    title: "گزارشات",
                                    url: "/monitoring/logs"
                                }
                            ]}
                        />

                    </div> : null
                }
            </div>

        );
    }
}

export default gpn(SideBar);