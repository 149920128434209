import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";
import Field from "../sections/Field";
import Select from "../sections/Select";
import {toast} from "react-toastify";
import FileDownload from "js-file-download";

class Customers extends Component {

    state = {
        customers: [],
        page: 1,
        hasNextPage: false,
        totalPages: null,
        search: "",

        display: "simple"
    };

    componentDidMount() {
        this.getCustomers(1, "");
    }


    getCustomers(p, s) {
        const page = p ? p : this.state.page;
        const search = s || s.toString() === "" ? s : this.state.search;

        axios.get(`${config.api}/customer?token=${config.token}&page=${page}&search=${search}`)
            .then(r => {
                const {customers} = r.data;
                this.setState({
                    customers: page > 1 ? [...this.state.customers, ...customers.docs] : customers.docs,
                    page: page + 1,
                    hasNextPage: customers.hasNextPage,
                    totalPages: customers.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }

    handleInputChange(name, value, index) {
        let mValue = value;
        switch (name) {
            case "clear":
                mValue = mValue ? mValue.toString() === "true" : false;
                break;
            case "price":
            case "profit":
                mValue = mValue ? !isNaN(Number(mValue)) ? Number(mValue) : 0 : 0;
                break;
        }

        let {customers} = this.state;
        customers[index][name] = mValue;

        this.setState({customers});
        let params = new URLSearchParams();
        params.append('id', customers[index]._id);
        params.append('name', name);
        params.append('value', value);
        axios.put(`${config.api}/customer?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });
    }

    sumProfit(customers = []) {
        let sum = 0;
        customers.map(c => {
            if (!c.clear) {
                sum = sum + (Number(c.profit) / 2);
            }
        })

        return sum;
    }


    download() {
        toast.info("در حال دانلود ...");
        axios.get(`${config.api}/customer/excel?token=${config.token}`, {responseType: 'blob'})
            .then(r => {
                try {
                    FileDownload(r.data, decodeURI(r.headers["x-suggested-filename"]));
                } catch (e) {
                }
            })
            .catch(err => {
                console.error('doc download err: ', err);
            });
    }

    render() {
        const {page, hasNextPage, customers, search, display} = this.state;

        let sumProfit = this.sumProfit(customers);
        return (
            <div className="customers">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="form-control">
                            <button onClick={() => this.props.navigate("/customer/new")}>+ جدید</button>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-control">
                            <button
                                style={{backgroundColor: "#006400"}}
                                onClick={() => this.download()}>خروجی اکسل
                            </button>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="search-box">
                    <div className="form-control">
                        <input type="text" name="search" id="search"
                               value={search}
                               onChange={e => {
                                   const {value} = e.target;
                                   this.getCustomers(1, value);
                                   this.setState({search: value});
                               }}
                               placeholder="جستجو کنید (نام و نام خانوادگی یا شماره موبایل):"/>
                    </div>
                </div>

                <div className="display">
                    <div className="row">
                        <span
                            onClick={() => this.setState({display: display === "simple" ? "edit" : "simple"})}
                            className={display === "simple" ? "material" : "material active"}>edit</span>
                    </div>
                </div>
                <br/>
                <div
                    className="sum-profit">
                    <span>سود هر نفر:</span>
                    <span style={{
                        color: sumProfit === 0 ? "#545454" : sumProfit > 0 ? "#129712" : "#ae1414"
                    }}>
                        {config.priceFormatter(sumProfit)
                            .replace("IRT", "")
                            .replace(" ", " ") + " تومان"}
                    </span>

                </div>
                <br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getCustomers(page, search)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {customers.map((c, i) =>
                        display === "simple" ?
                            this.itemSimple(c, i)
                            : this.item(c, i)
                    )}
                </InfiniteScroll>

            </div>
        );
    }

    itemSimple(customer, index) {
        const {_id, fullName, product, ticketNo, status, buyType, clear, price, profit} = customer;

        return (
            <div key={"cs" + index}
                 className="customer-simple">
                <div className="row">
                    <div className="col-auto">
                        <div className="index">{index + 1}</div>
                    </div>

                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="fullName">{fullName}</div>
                            </div>
                            <div className="col-auto">
                                <div className="product">{product}</div>
                            </div>
                            <div className="col-auto">
                                <div className="status">{status}</div>
                            </div>

                            <div className="col-auto">
                                <div className="buyType">{buyType}</div>
                            </div>
                        </div>

                    </div>

                    <div className="col-auto">
                        <div className="ticket-no">
                            <span>#</span><span>{ticketNo}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    item(customer, index) {
        const {_id, fullName, product, ticketNo, status, buyType, clear, price, profit} = customer;

        return (
            <div key={"c" + index}
                 className="customer">
                <div className="row">
                    <div className="col-auto"><span className="num">{(index + 1)}</span></div>
                    <div className="col">
                        <div className="row">
                            <div className="col-6">
                                <Field name="fullName"
                                       textAlign="center"
                                       oc={(name, value) => this.handleInputChange(name, value, index)}
                                       value={fullName}/>
                            </div>
                            <div className="col-6">
                                <Field name="product"
                                       textAlign="center"
                                       oc={(name, value) => this.handleInputChange(name, value, index)}
                                       value={product}/>
                            </div>
                            <div className="col-12"><br/></div>
                            <div className="col-6">
                                <Field name="price"
                                       type="number"
                                       textAlign="center"
                                       dir="ltr"
                                       oc={(name, value) => this.handleInputChange(name, value, index)}
                                       value={price}/>
                                <div
                                    className="price-formatted">{
                                    config.priceFormatter(price).replace("IRT", "").replace(" ", "") + " تومان"
                                }</div>
                            </div>
                            <div className="col-6">
                                <Field name="profit"
                                       type="number"
                                       textAlign="center"
                                       dir="ltr"
                                       oc={(name, value) => this.handleInputChange(name, value, index)}
                                       value={profit}/>
                                <div
                                    className="price-formatted">{
                                    config.priceFormatter(profit).replace("IRT", "").replace(" ", "") + " تومان سود"
                                }</div>
                            </div>
                            <div className="col-12"><br/></div>
                            <div className="col-6">
                                <Field name="ticketNo"
                                       textAlign="center"
                                       dir="ltr"
                                       style={{color: "#103799", fontWeight: 600}}
                                       oc={(name, value) => this.handleInputChange(name, value, index)}
                                       value={ticketNo}/>
                                <div className="hint">شماره تیکت</div>
                            </div>
                            <div className="col-6">
                                <Select name="clear"
                                        value={clear.toString()}
                                        oc={(name, value) => this.handleInputChange(name, value, index)}
                                        options={[
                                            {
                                                title: "تسویه نشده",
                                                value: "false"
                                            },
                                            {
                                                title: "تسویه شده",
                                                value: "true"
                                            }
                                        ]}
                                />
                                <div className="hint">تسویه</div>
                            </div>
                            <div className="col-6">
                                <Select name="status"
                                        value={status}
                                        oc={(name, value) => this.handleInputChange(name, value, index)}
                                        options={[
                                            {
                                                title: "ثبت اولیه",
                                                value: "ثبت اولیه"
                                            },
                                            {
                                                title: "تخصیص اعتبار",
                                                value: "تخصیص اعتبار"
                                            },
                                            {
                                                title: "تکمیل سفارش",
                                                value: "تکمیل سفارش"
                                            },
                                        ]}
                                />
                                <div className="hint">وضعیت</div>
                            </div>
                            <div className="col-6">
                                <Select name="buyType"
                                        value={buyType}
                                        oc={(name, value) => this.handleInputChange(name, value, index)}
                                        options={[
                                            {
                                                title: "خرید تهران",
                                                value: "خرید تهران"
                                            },
                                            {
                                                title: "خرید از موجودی انبار",
                                                value: "خرید از موجودی انبار"
                                            },

                                        ]}
                                />
                                <div className="hint">نحوه خرید</div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="delete"><span className="material">delete</span><span>حذف کردن</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default gpn(Customers);