import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import PostEditor from "../sections/PostEditor";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";
import {toast} from "react-toastify";
import Fab from "../sections/Fab";
import Modal from "../sections/Modal";

class EditPost extends Component {

    state = {
        title: "",
        content: "",
        images: [],

        uploadModal: false,
        img: null,
        uploadUrl: ""
    };

    componentDidMount() {
        this.getPost();
    }

    getPost() {
        const {id} = this.props.params;

        axios.get(`${config.apiV1}/post?id=${id}`)
            .then(r => {
                const {title, content, images} = r.data.post;
                this.setState({title, content, images});

            })
            .catch(err => {

            });

    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({
            images: arrFiles
        });

    }

    handleEdit() {
        const {title, content, images} = this.state;
        if (title !== "") {
            let params = new FormData();
            params.append('id', this.props.params.id);
            params.append('title', title);
            params.append('content', content);

            if (images.length > 0) {
                if (typeof images[0] !== "string") {

                    Array.from(images).forEach(image => {
                        params.append("images", image);
                    });
                }
            }

            axios.put(`${config.api}/post?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    toast.success('با موفقیت ویرایش شد.');
                    this.getPost();
                })
                .catch(err => {
                    toast.error("خطایی رخ داده است!");
                    console.error(err);
                });
        } else {
            toast.error("عنوان را وارد کنید!");
        }
    }

    uploadImage(img) {
        if (img) {
            toast.success("در حال آپلود ...")
            let params = new FormData();
            params.append('img', img);

            axios.post(`${config.api}/upload/image?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    this.setState({uploadUrl: r.data.url});
                    toast.success("با موفقیت آپلود شد.")
                })
                .catch(err => {
                    toast.error("خطا!")
                });
        }
    }

    handleDelete() {
        const {id} = this.props.params;
        axios.delete(`${config.api}/post?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                setTimeout(() => {
                    this.props.navigate(-1);
                }, 2500);
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
                console.error(err);
            });
    }

    render() {
        const {title, content, images, uploadUrl} = this.state;
        return (
            <div className="edit-post">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="title">عنوان:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       value={title}
                                       onChange={(e) => {
                                           const {name, value} = e.target;
                                           this.setState({[name]: value})
                                       }}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="images">
                                <label htmlFor="images">تصاویر:
                                    <div>
                                        {
                                            images.map((img, i) =>
                                                <img key={"img" + i}
                                                     src={typeof img === "string" ? img : URL.createObjectURL(img)}
                                                     alt=""/>
                                            )}
                                    </div></label>
                                <input type="file" name="images" id="images" multiple
                                       onChange={this.handleFileChange.bind(this)}
                                       style={{display: images.length === 0 ? "block" : "none"}}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <PostEditor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                content={content}
                                editorState={EditorState.createWithContent(ContentState.createFromText(content))}
                                onChange={(e) => {
                                    this.setState({content: e})
                                }}
                                toolbarClassName="toolbar-class"
                            />
                        </div>

                        <div className="col-md-12">
                            <br/><br/><br/>
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <button typeof="button" onClick={this.handleEdit.bind(this)}>ویرایش کردن</button>
                                </div>
                                <div className="col-auto">
                                    <button typeof="button" style={{backgroundColor: "#D50000"}}
                                            onDoubleClick={this.handleDelete.bind(this)}>حذف کردن
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Fab icon="cloud_upload" size={14} onClick={() => {
                    this.setState({
                        uploadModal: true
                    })
                }}/>
                <Modal show={this.state.uploadModal}
                       title="آپلود عکس"
                       onCloseClick={() => {
                           this.setState({
                               uploadModal: false
                           })
                       }}>
                    <div className="form-control">
                        <label htmlFor="img">عکس:</label>
                        <input type="file"
                               name="img"
                               id="img"
                               onChange={(e) => {
                                   const {files} = e.target;
                                   const arrFiles = Array.from(files);

                                   this.setState({
                                       img: arrFiles[0]
                                   });
                                   this.uploadImage(arrFiles[0])
                               }}/>
                        {
                            uploadUrl !== "" ?
                                <input type="text" name="uploadUrl" id="uploadUrl" value={uploadUrl}/>
                                : null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

export default gpn(EditPost);