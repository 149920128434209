import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import FileDownload from "js-file-download";
import {toast} from "react-toastify";
import Field from "../sections/Field";

class Document extends Component {

    state = {
        id: this.props.params.id, fullName: "", phoneNumber: "", moaref: "", description: "", shoghl: "",

        aslShenasnamehSafhe1: "", asleKartemeliRo: "", asleKartemeliPohst: "",

        madrakShoghli: "", madrakSokonati: "", moarefiNameh: "", chek: "",

        karmandPrint3MaheHoghogh: "",

        digarPrint6MaheHesab: "", digarPrint6MaheMoadelHesab: "",

        other: "",

        status: "",

        arr: [],

        created_at: ""
    };

    componentDidMount() {
        this.getDocumentInfo();
    }

    getDocumentInfo() {
        const {id} = this.state;
        axios.get(`${config.api}/document?token=${config.token}&id=${id}`)
            .then(r => {
                this.setState(r.data.document);
            })
            .catch(err => {
                console.error(err.stack);
            });
    }

    download(name) {
        toast.info("در حال دانلود ...");
        axios.get(`${config.api}/document/download?token=${config.token}&id=${this.state.id}&name=${name}`, {responseType: 'blob'})
            .then(r => {
                try {
                    FileDownload(r.data, decodeURI(r.headers["x-suggested-filename"]));
                } catch (e) {
                }
            })
            .catch(err => {
                console.error('doc download err: ', err);
            });
    }

    downloadTashilat(name) {
        toast.info("در حال دانلود ...");
        axios.get(`${config.api}/document/tashilat/download?token=${config.token}&name=${name}`, {responseType: 'blob'})
            .then(r => {
                try {
                    FileDownload(r.data, decodeURI(r.headers["x-suggested-filename"]));
                } catch (e) {
                }
            })
            .catch(err => {
                console.error('doc download err: ', err);
            });
    }

    deleteDoc() {
        toast.info("در حال حذف ...");
        axios.delete(`${config.api}/document?token=${config.token}&id=${this.state.id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد");
                setTimeout(() => {
                    this.props.navigate(-1);
                }, 2500)
            })
            .catch(err => {

            });
    }

    sendSMS(type) {
        const {phoneNumber} = this.state;

        let params = new URLSearchParams();
        params.append('typeSMS', type);
        params.append('phoneNumber', phoneNumber);

        toast.info("در حال ارسال پیام ...");
        axios.put(`${config.api}/document/sms?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ارسال شد.");
            })
            .catch(err => {
                toast.error("خطایی رخ داد")
            });
    }

    getDocType() {
        const {fullName} = this.state;
        let docType = "خرید کالا به صورت چکی (ام اس)";

        if (fullName.includes("حکمت")) {
            docType = "خرید کالا با حکمت کارت";
        } else if (fullName.includes(" - ")) {
            if (fullName.includes("امین") || fullName.includes("بردیا") || fullName.includes("محمد")) {
                docType = "دریافت تسهیلات";
            }
        }

        return docType;
    }

    setStatus(status) {
        let params = new URLSearchParams();
        params.append('id', this.props.params.id);
        params.append('status', status);

        axios.put(`${config.api}/document/status?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });

    }

    render() {
        const {
            fullName,
            phoneNumber,
            moaref,
            description,
            shoghl,
            created_at,
            aslShenasnamehSafhe1,
            asleKartemeliRo,
            asleKartemeliPohst,
            madrakShoghli,
            madrakSokonati,
            moarefiNameh,
            chek,
            karmandPrint3MaheHoghogh,
            digarPrint6MaheHesab,
            digarPrint6MaheMoadelHesab,
            other,
            arr,
            status
        } = this.state;

        return (<div className="document-info">
            <div className="card">
                <h1>{fullName}&nbsp;{shoghl ? "(" + shoghl + ")" : ""}</h1>
                <p className="doc-type-hint">این مشتری مدارک را برای&nbsp;
                    <span>{this.getDocType()}</span>&nbsp;ارسال
                    کرده است</p>
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="phone-number">
                            <span>شماره موبایل:&nbsp;</span><span>{phoneNumber}</span>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="phone-number">
                            <span>معرف:&nbsp;</span><span>{moaref}</span>
                        </div>
                    </div>
                </div>

                <Field type="textarea"
                       row={3}
                       value={description}/>

                <div className="row">
                    <div className="col-6">
                        <div className="form-control">
                            <label htmlFor="statusSelect">وضعیت سفارش:</label>
                            <select name="statusSelect"
                                    id="statusSelect"
                                    value={status}
                                    onChange={e => {
                                        this.setState({status: e.target.value});
                                        this.setStatus(e.target.value);
                                    }}>
                                <option value="ثبت اولیه">ثبت اولیه</option>
                                <option value="در دست بررسی">در دست بررسی</option>
                                <option value="ارجاع به بانک">ارجاع به بانک</option>
                                <option value="نیاز به اصلاح">نیاز به اصلاح</option>
                                <option value="تکمیل شده">تکمیل شده</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6">
                        <Field name="status"
                               label="وضعیت دلخواه"
                               oc={(name, value) => {
                                   this.setState({status: value});
                                   this.setStatus(value);
                               }}
                               value={status}/>
                    </div>
                </div>
                <div className="documents">
                    <div className="row justify-content-center">
                        {aslShenasnamehSafhe1 !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("aslShenasnamehSafhe1")}>عکس اصل شناسنامه
                            </button>
                        </div> : null}

                        {asleKartemeliRo !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("asleKartemeliRo")}>عکس کارت ملی رو
                            </button>
                        </div> : null}
                        {asleKartemeliPohst !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("asleKartemeliPohst")}>عکس کارت ملی پشت
                            </button>
                        </div> : null}
                        {madrakShoghli !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("madrakShoghli")}>مدرک شغلی
                            </button>
                        </div> : null}
                        {madrakSokonati !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("madrakSokonati")}>مدرک سکونتی
                            </button>
                        </div> : null}
                        {moarefiNameh !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("moarefiNameh")}>معرفی نامه یا مدرک شغلی
                            </button>
                        </div> : null}
                        {chek !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("chek")}>چک
                            </button>
                        </div> : null}
                        {karmandPrint3MaheHoghogh !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("karmandPrint3MaheHoghogh")}>پرینت 3 ماهه حقوق
                                کارمندی
                            </button>
                        </div> : null}
                        {digarPrint6MaheHesab !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("digarPrint6MaheHesab")}>پرینت 6 ماهه حساب
                            </button>
                        </div> : null}
                        {digarPrint6MaheMoadelHesab !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("digarPrint6MaheMoadelHesab")}>پرینت 6 ماهه
                                معدل حساب
                            </button>
                        </div> : null}

                        {other !== "" ? <div className="col-md-auto">
                            <button type="button"
                                    onClick={() => this.download("other")}>غیره
                            </button>
                        </div> : null}
                    </div>

                </div>

                <div className="array">
                    <div className="row justify-content-center">
                        {arr.map((a, i) => <div className="doc"
                                                onClick={this.downloadTashilat.bind(this, a)}
                                                key={"a" + i}>{(i + 1) + ") " + a.replace("documents", "")}</div>)}
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="form-control">
                            <button type="button"
                                    onDoubleClick={this.sendSMS.bind(this, "accept")}
                                    style={{backgroundColor: "#269e14"}}>
                                ارسال پیام تایید
                            </button>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-control">
                            <button type="button"
                                    onDoubleClick={this.sendSMS.bind(this, "reject")}
                                    style={{backgroundColor: "#da4140"}}>
                                ارسال پیام رد
                            </button>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-md-5">
                        <br/><br/><br/>
                        <div className="form-control">
                            <button type="button"
                                    onDoubleClick={this.deleteDoc.bind(this)}
                                    style={{backgroundColor: "#d51313"}}>حذف کردن
                            </button>
                        </div>
                    </div>
                </div>
                <div className="created-at">{created_at.toString()}</div>
            </div>
        </div>);
    }
}

export default gpn(Document);