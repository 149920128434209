import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";

class SelectProduct extends Component {

    state = {
        modal: false,
        products: []
    };

    componentDidMount() {
        this.getProducts();
    }


    getProducts() {
        axios.get(`${config.api}/products/10?token=${config.token}`)
            .then(r => {
                this.setState({products: r.data.products});
            })
            .catch(err => {

            });
    }

    iv(product, key) {
        let info = product.info;
        let v = "";

        info.map(i => {
            if (i.key === key) {
                v = i.value
            }
        });

        if (v === "") {
            info.map(i => {
                if (i.key.includes(key)) {
                    v = i.value
                }
            });
        }

        return v;
    }

    render() {
        const {modal, products} = this.state;
        const {onSelect, type} = this.props;

        return (
            <div className="select-product">
                {modal ?
                    <div className="modal">
                        <div className="card">
                            <div className="row">
                                <div className="col-auto">
                                    <span className="material close"
                                          onClick={() => this.setState({modal: false})}>close</span>
                                </div>
                                <div className="col">
                                    <p style={{textAlign: "center"}}>انتخاب محصول ...</p>
                                </div>
                            </div>
                            <br/>

                            <div className="body">
                                {
                                    products.map((p, i) =>
                                        <article key={"pr" + i} onClick={() => {

                                            if (type === "laptop") {
                                                if (p.title.includes("لپ تاپ")) {
                                                    let obj = {
                                                        subject: p.title,
                                                        images: p.images.length > 0 ? p.images.map(img => {
                                                            return img.replace("uploads", config.apiV1);
                                                        }) : [],
                                                        sazandeh: this.iv(p, "سازنده"),
                                                        sazandehCPU: this.iv(p, "سازنده پردازنده"),
                                                        seriCPU: this.iv(p, "سری پردازنده"),
                                                        ram: this.iv(p, "ظرفیت حافظه RAM") !== "" ?
                                                            this.iv(p, "ظرفیت حافظه RAM") :
                                                            this.iv(p, "حافظه رم") !== "" ?this.iv(p, "حافظه رم"):
                                                            this.iv(p, "RAM") !== "" ? this.iv(p, "RAM") :
                                                                this.iv(p, "رم"),
                                                        memory: this.iv(p, "ظرفیت حافظه داخلی") !== "" ?
                                                            this.iv(p, "ظرفیت حافظه داخلی") :
                                                            this.iv(p, "حافظه داخلی") !== "" ?
                                                                this.iv(p, "حافظه داخلی") : this.iv(p, "حافظه"),
                                                        vga: this.iv(p, "مدل پردازنده گرافیکی") !== "" ? this.iv(p, "مدل پردازنده گرافیکی") : this.iv(p, "پردازنده گرافیکی") !== "" ? this.iv(p, "پردازنده گرافیکی") : this.iv(p, "گرافیک"),
                                                        display: this.iv(p, "اندازه صفحه نمایش") !== "" ? this.iv(p, "اندازه صفحه نمایش") : "",
                                                        os: this.iv(p, "سیستم عامل"),
                                                        color: this.iv(p, "رنگ") !== "" ? this.iv(p, "رنگ") :
                                                            this.iv(p, "رنگ بندی") !== "" ? this.iv(p, "رنگ بندی") : this.iv(p, "رنگبندی"),
                                                        price: p.price,
                                                    };

                                                    if (onSelect) {
                                                        onSelect(obj);
                                                    }

                                                    this.setState({modal: false});
                                                }

                                            } else if (type === "mobile") {
                                                if (p.title.includes("گوشی") || p.title.includes("موبایل")) {
                                                    let obj = {
                                                        subject: p.title,
                                                        images: p.images.length > 0 ? p.images.map(img => {
                                                            return img.replace("uploads", config.apiV1);
                                                        }) : [],
                                                        price: p.price,
                                                        sazandeh: this.iv(p, "سازنده"),
                                                        andazeh: this.iv(p, "اندازه"),
                                                        display: this.iv(p, "فناوری صفحه‌نمایش") !== "" ?
                                                            this.iv(p, "فناوری صفحه‌نمایش") :
                                                            this.iv(p, "فناوری صفحه‌ نمایش") !== "" ?
                                                                this.iv(p, "فناوری صفحه‌ نمایش")
                                                                : this.iv(p, "صفحه‌ نمایش"),
                                                        resolutionAx: this.iv(p, "رزولوشن عکس"),
                                                        os: this.iv(p, "سیستم عامل"),
                                                        osVersion: this.iv(p, "نسخه سیستم عامل"),
                                                        simCart: this.iv(p, "تعداد سیم کارت"),
                                                        cpu: this.iv(p, "پردازنده‌ی مرکزی") !== "" ? this.iv(p, "پردازنده‌ی مرکزی") : this.iv(p, "پردازنده"),
                                                        ram: this.iv(p, "RAM") !== "" ? this.iv(p, "RAM") : this.iv(p, "رم"),
                                                        battery: this.iv(p, "باتری"),
                                                        hdd: this.iv(p, "حافظه داخلی") !== "" ? this.iv(p, "حافظه داخلی") : this.iv(p, "حافظه"),
                                                        memory: this.iv(p, "کارت حافظه جانبی") !== "" ? this.iv(p, "کارت حافظه جانبی") : this.iv(p, "کارت حافظه"),
                                                        color: this.iv(p, "رنگ")
                                                    };

                                                    if (onSelect) {
                                                        onSelect(obj);
                                                    }

                                                    this.setState({modal: false});
                                                }
                                            }


                                        }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="image">
                                                        <img
                                                            src={p.images ? p.images.length > 0 ? p.images[0].replace("uploads", config.apiV1) : "" : ""}
                                                            alt=""/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="title">{p.title}</div>
                                                    <div className="price">{config.priceFormatter(p.price)}</div>
                                                </div>
                                            </div>
                                        </article>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                    :
                    null}
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-control">
                            <button type="button" onClick={() => this.setState({modal: true})}>انتخاب محصول</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default gpn(SelectProduct);