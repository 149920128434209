import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";
import {toast} from "react-toastify";

class AgentProducts extends Component {

    state = {
        products: [],
        page: 1,
        hasNextPage: false,
        totalPages: null,
    };

    componentDidMount() {
        this.getProducts(1);
    }


    getProducts(p) {
        const page = p ? p : this.state.page;

        axios.get(`${config.api}/agent/product/list?token=${config.token}&page=${page}`)
            .then(r => {
                const {products} = r.data;


                this.setState({
                    products: page > 1 ? [...this.state.products, ...products.docs] : products.docs,
                    page: page + 1,
                    hasNextPage: products.hasNextPage,
                    totalPages: products.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }

    deleteProduct(index, id) {
        toast.info("در حال حذف ...");
        axios.delete(`${config.api}/agent/product?token=${config.token}&id=${id}`)
            .then(r => {
                let {products} = this.state;
                products.splice(index, 1);
                this.setState({products});
            })
            .catch(err => {
                console.error('err: ', err);
            });
    }

    render() {
        const {page, hasNextPage, products} = this.state;
        return (
            <div className="agent-products">
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getProducts(page)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {products.map((p, i) =>
                        <div key={"product" + i} className="agent-product">
                            <div className="row">
                                <div className="col">
                                    <div className="title">{p.title}</div>
                                    <div className="description">{p.description}</div>
                                    <div
                                        className="price">{config.priceFormatter(p.price).replace("IRT", "").replace(/ /g, "") + " تومان"}</div>

                                </div>
                                <div className="col-auto">
                                    <span className="material delete"
                                          onDoubleClick={() => this.deleteProduct(i, p._id)}>delete</span>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </InfiniteScroll>
            </div>
        );
    }
}

export default gpn(AgentProducts);