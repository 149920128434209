import Field from "./MUI/Field";
import Icon from "./MUI/Icon";
import React, {useEffect, useState} from "react";

export default function VamTarhFields({fields, onChange}) {
    const [field, setField] = useState("");

    return (
        <div className="vam-tarh-fields">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="new-field">

                        <div className="row">
                            <div className="col">
                                <Field name="field"
                                       value={field}
                                       onEnterPress={() => {
                                           let list = field;
                                           list.push(field);
                                           setField("");
                                           onChange(list);
                                       }}
                                       onChange={(name, value) => setField(value)}
                                       label="فیلد"/>
                            </div>
                            <div className="col-auto">
                                <Icon i="plus"
                                      solid
                                      className="icon-add-field"
                                      onClick={() => {
                                          let list = fields;
                                          list.push(field);
                                          setField("");
                                          onChange(list);
                                      }}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="fields">

                        {
                            fields.map((f, i) =>
                                <div className="vam-tarh-field" key={"field" + i}>
                                    <div className="row">
                                        <div className="col">
                                            <Field name="field"
                                                   label="فیلد"
                                                   value={f}
                                                   onChange={(name, value) => {
                                                       let list = fields;
                                                       list[i] = value;
                                                       onChange(list);
                                                   }}/>
                                        </div>
                                        <div className="col-auto">
                                            <Icon i="minus"
                                                  className="remove-vam-tarh-field"
                                                  solid
                                                  onClick={() => {
                                                      let list = fields;
                                                      list.splice(i, 1);
                                                      onChange(list);
                                                  }}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}