import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/MUI/Field";
import File from "../sections/MUI/File";
import Button from "../sections/MUI/Button";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Icon from "../sections/MUI/Icon";

class Sazmans extends Component {

    state = {
        sazmans: [],

        subject: "",
        logo: null
    };

    componentDidMount() {
        this.getSazmans();
    }

    getSazmans() {
        axios.get(`${config.api}/sazman?token=${config.token}`)
            .then(r => {
                this.setState({sazmans: r.data.sazmans});
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleSubmit() {
        const {subject, logo} = this.state;
        if (subject !== "" && logo) {
            toast.info("در حال ثبت ...");
            let params = new FormData();
            params.append('subject', subject);
            params.append('logo', logo);
            axios.post(`${config.api}/sazman?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            }).then(r => {
                toast.success("با موفقیت ثبت شد.");

                this.setState({
                    subject: "",
                    logo: null
                });
                this.getSazmans();
            })
                .catch(err => {
                    config.errorHandler(err);
                });
        } else {
            toast.error("عنوان یا لوگو سازمان وارد نشده.");
        }
    }

    handleDelete(id) {
        toast.info("در حال حذف ...");
        axios.delete(`${config.api}/sazman?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                this.getSazmans();
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleEdit(id, subject) {
        let params = new URLSearchParams();
        params.append('id', id);
        params.append('subject', subject);
        toast.info("در حال ویرایش ...");
        axios.put(`${config.api}/sazman?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ویرایش شد.");
                this.getSazmans();
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleSubjectChange(index, value) {
        let {sazmans} = this.state;
        sazmans[index].subject = value;
        this.setState({sazmans});
    }

    render() {
        const {sazmans, subject, logo} = this.state;

        return (
            <div className="sazmans">
                <div className="create">
                    <div className="row">
                        <div className="col">
                            <File multiple={false}
                                  value={logo}
                                  name="logo"
                                  label="لوگو"
                                  onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>
                        <div className="col-3">
                            <Field name="subject"
                                   value={subject}
                                   label="نام سازمان یا اداره"
                                   onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>
                        <div className="col-2">
                            <Button icon="check" onClick={() => this.handleSubmit()}>ثبت کردن</Button>
                        </div>
                    </div>
                </div>

                <div className="list">
                    {
                        sazmans?.map((s, i) =>
                            <article key={"s" + i}>
                                <div className="row">
                                    <div className="col-auto">
                                        <img src={s.logo} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <Field name={"f" + i}
                                                       value={s.subject}
                                                       onChange={(name, value) => this.handleSubjectChange(i, value)}
                                                       label="عنوان"/>
                                            </div>
                                            <div className="col-auto">
                                                <Icon i="edit" className="edit-icon" solid
                                                      onClick={() => this.handleEdit(s.id, s.subject)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <Icon i="trash" className="delete-icon" solid
                                              onDoubleClick={() => this.handleDelete(s.id)}/>
                                    </div>
                                </div>
                            </article>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default gpn(Sazmans);