import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Icon from "../sections/Icon";

class NavItems extends Component {

    state = {
        navItems: [],

        icon: "",
        title: "",
        url: ""
    };

    componentDidMount() {
        this.getNavItems();
    }

    getNavItems() {
        axios.get(`${config.apiV1}/nav`)
            .then(r => {
                const {navItems} = r.data;

                this.setState({navItems});
            })
            .catch(err => {
                console.error(err);
                toast.error("خطا!");
            });
    }


    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSave() {
        const {title, url, icon} = this.state;
        if (title !== "" && url !== "") {

            let params = new URLSearchParams();
            params.append('title', title);
            params.append('icon', icon);
            params.append('url', url);

            axios.post(`${config.api}/nav?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ذخیره شد.");
                    this.setState({navItems: [], title: "", url: "", icon: ""});
                    this.getNavItems();
                })
                .catch(err => {
                    console.error(err);
                    toast.error("خطا!")
                });
        } else {
            toast.error("فیلد ها را پر کنید!")
        }
    }

    handleDelete(id) {
        axios.delete(`${config.api}/nav?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                this.getNavItems();
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    render() {
        const {navItems, icon, title, url} = this.state;

        return (
            <div className="container-navitem">
                <div className="create-navitem">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-control">
                                    <label htmlFor="title">عنوان:</label>
                                    <input type="text"
                                           name="title"
                                           value={title}
                                           onChange={this.handleInputChange.bind(this)}
                                           id="title"
                                           style={{textAlign: "center"}}
                                           placeholder="عنوان"/>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-control">
                                    <label htmlFor="icon">آیکون:</label>
                                    <input type="text"
                                           name="icon"
                                           value={icon}
                                           onChange={this.handleInputChange.bind(this)}
                                           id="icon"
                                           style={{textAlign: "center", direction: "ltr"}}
                                           placeholder="آیکون"/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-control">
                                    <label htmlFor="url">لینک:</label>
                                    <input type="text"
                                           name="url"
                                           value={url}
                                           style={{direction: "ltr", textAlign: "center"}}
                                           onChange={this.handleInputChange.bind(this)}
                                           id="url"
                                           placeholder="لینک"/>
                                </div>
                            </div>
                            <div className="col-auto">
                                <br/>
                                <button type="button" onClick={this.handleSave.bind(this)}>ذخیره</button>
                            </div>
                        </div>

                    </form>
                </div>
                <br/><br/>
                <div className="navitems">
                    {navItems.map((nav, i) =>
                        <div key={"nav" + i} className="nav">
                            <div className="row">
                                <div className="col">
                                    <div className="title">
                                        <Icon i={nav.icon}/>
                                        {nav.title}
                                    </div>
                                    <div className="url">{nav.url}</div>
                                </div>
                                <div className="col-auto">
                                    <Icon i="trash"
                                          className="delete"
                                          onDoubleClick={() => {
                                              this.handleDelete(nav._id)
                                          }}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default gpn(NavItems);