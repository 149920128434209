import React, {Component} from 'react';
import {gpn} from "../../GPN"
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";

class NewAdmin extends Component {

    state = {
        username: "", fullName: "", password: "",
    };

    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    submit() {
        const {username, fullName, password} = this.state;

        if (username.length >= 3 && fullName !== "" && password.length >= 8) {
            let params = new URLSearchParams();
            params.append('username', username);
            params.append('fullName', fullName);
            params.append('password', password);

            toast.info("در حال ارسال ...")
            axios.post(`${config.api}/admin?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ذخیره شد.");
                    setTimeout(() => {
                        this.props.navigate(-1);
                    }, 2500)
                })
                .catch(err => {
                    toast.error("خطا!")
                });
        } else {
            toast.error("نام کاربری 3 کاراکتر - نام خانوادگی 1 کاراکتر - گذرواژه 8 کاراکتر");
        }
    }

    render() {
        const {username, fullName, password} = this.state;
        return (<div className="new-admin">
            <div className="card">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="fullName">نام و نام خانوادگی:</label>
                                <input type="text"
                                       name="fullName"
                                       id="fullName"
                                       placeholder="نام و نام خانوادگی"
                                       onChange={this.handleInputChange.bind(this)}
                                       style={{textAlign: "center"}}
                                       value={fullName}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="username">نام کاربری:</label>
                                <input type="text"
                                       name="username"
                                       id="username"
                                       placeholder="نام کاربری"
                                       onChange={this.handleInputChange.bind(this)}
                                       style={{textAlign: "center", direction: "ltr"}}
                                       value={username}/>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="form-control">
                                <label htmlFor="password">گذرواژه:</label>
                                <input type="text"
                                       name="password"
                                       id="password"
                                       placeholder="گذرواژه"
                                       onChange={this.handleInputChange.bind(this)}
                                       style={{textAlign: "center", direction: "ltr"}}
                                       value={password}/>
                            </div>
                        </div>

                        <div className="col-6">
                            <br/><br/><br/>
                            <button type="button" onClick={this.submit.bind(this)}>ذخیره</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>);
    }
}

export default gpn(NewAdmin);