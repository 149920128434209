import Icon from "./Icon";

function Button({
                    type,
                    submit,
                    icon,
                    children,
                    gradientStart,
                    gradientEnd,
                    backgroundColor,
                    color,
                    textSize,
                    borderRadius,
                    onClick,
                    onDoubleClick
                }) {
    type = submit ? "submit" : type ? type : "button";

    let style = getStyle(backgroundColor, color, gradientStart, gradientEnd, textSize, borderRadius);

    return (
        <div className="form-control">
            <button type={type}
                    className="mui-button"
                    style={style}
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                    onDoubleClick={() => {
                        if (onDoubleClick) {
                            onDoubleClick();
                        }
                    }}>

                {icon ?
                    <Icon i={icon}/>
                    : null}

                <span className="title">{children}</span>
            </button>
        </div>
    )
}

function getStyle(backgroundColor, color, gradientStart, gradientEnd, textSize, borderRadius) {
    let style = {
        fontSize: textSize ? (textSize + "pt") : "12pt"
    };

    if (gradientStart && gradientEnd) {
        style = {
            ...style,
            background: `linear-gradient(90deg, ${gradientStart}, ${gradientEnd})`
        }
    } else {
        if (backgroundColor) {
            style = {
                ...style,
                backgroundColor: backgroundColor
            }
        }
        if (color) {
            style = {
                ...style,
                color: color
            }
        }
    }

    if (borderRadius) {
        style = {
            ...style,
            borderRadius: borderRadius + "px"
        }
    }


    return style;
}

export default Button;