import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Select from "../sections/MUI/Select";

class ParseNews extends Component {

    state = {
        link: "",
        image: null,
        title: null,
        body: null,

        farsNews: [],

        meta: {
            title: null,
            description: null
        }
    };

    componentDidMount() {
        this.getFarsNews();
    }


    getFarsNews() {
        axios.get(`${config.api}/news/fars/list?token=${config.token}`)
            .then(r => {
                console.log('r.data.news: ', r.data.news);

                this.setState({
                    farsNews: r.data.news
                });
            })
            .catch(err => {
                config.errorHandler(err, true);
            });
    }

    getFarsNewsInfo(link) {
        this.setState({
            image: null,
            title: null,
            body: null
        });
        axios.get(`${config.api}/news/fars?token=${config.token}&url=${link}`)
            .then(r => {
                const {title, body, image} = r.data;

                this.setState({image, title, body, meta: {title: title, description: body.slice(0, 155)}});
            })
            .catch(err => {
                config.errorHandler(err, true);
            });
    }

    getNews(e) {
        e.preventDefault();
        this.setState({
            image: null,
            title: null,
            body: null
        });
        const {link} = this.state;
        axios.get(`${config.api}/news/parse?token=${config.token}&link=${link}`)
            .then(r => {
                const {title, body, image, meta} = r.data;

                this.setState({image, title, body, meta});
            })
            .catch(err => {

            });
    }

    inputChange(e) {
        let {name, value} = e.target;
        this.setState({[name]: value});
    }

    createNews() {
        const {title, body, image, meta} = this.state;

        let params = new URLSearchParams();
        params.append('title', title);
        params.append('body', body);
        params.append('image', image);
        params.append('meta', JSON.stringify(meta));

        toast.success("در حال ارسال ...")
        axios.post(`${config.api}/news?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ارسال شد.")
                this.setState({
                    link: "",
                    image: null,
                    title: null,
                    body: null,
                    meta: {
                        title: null,
                        description: null
                    }
                });
                window.scrollTo(0, 0);
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        const {link, image, title, body, meta, farsNews} = this.state;

        return (
            <div className="parse-news">
                <Select simple
                        name="link"
                        placeholder="خبرگزاری فارس"
                        value={link}
                        onChange={(name, value) => {
                            this.setState({[name]: value});
                            this.getFarsNewsInfo(value);
                        }}
                        options={farsNews?.map(fn => {
                            return {t: fn.title, v: fn.link}
                        })}/>
                <br/>
                <form onSubmit={this.getNews.bind(this)}>
                    <div className="form-control">
                        <label htmlFor="link">لینک خبر:</label>
                        <input type="text"
                               name="link"
                               placeholder="URL"
                               style={{direction: "ltr", textAlign: "left"}}
                               id="link"
                               onChange={this.inputChange.bind(this)}
                               value={link}/>
                    </div>
                </form>
                <br/><br/><br/>
                {image && title && body ?
                    <div className="data">
                        <div className="row">
                            <div className="col-auto">
                                <img src={image} alt=""/>
                            </div>
                            <div className="col">
                                <div className="title">
                                    <div className="form-control">
                                        <input type="text" name="title" id="title" value={title}
                                               onChange={this.inputChange.bind(this)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="body">
                                    <div className="form-control">
                                        <textarea name="body" id="body" rows="15" value={body}
                                                  onChange={this.inputChange.bind(this)}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-control">
                                    <br/><br/>
                                    <button type="button" onClick={this.createNews.bind(this)}>ارسال خبر</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}

            </div>
        );
    }
}

export default gpn(ParseNews);