import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Contact extends Component {

    state = {
        contacts: null
    };

    componentDidMount() {
        this.getContacts();
    }

    getContacts() {
        axios.get(`${config.api}/contact?token=${config.token}`)
            .then(r => {
                const {contacts} = r.data;
                this.setState({contacts});
            })
            .catch(err => {
                console.error(err);
            });
    }

    delete(id) {
        axios.delete(`${config.api}/contact?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                this.getContacts();
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    render() {
        const {contacts} = this.state;
        return (
            <div className="contacts">
                {
                    contacts ?
                        contacts.map((c, i) =>
                            <div key={i} className="contact">
                                <div className="title">
                                    <div>{c.title}</div>
                                    <div>{c.ip}</div>
                                </div>
                                <pre className="description">{c.description} </pre>
                                <div style={{textAlign: "left"}}><span className="material"
                                                                       onClick={this.delete.bind(this, c.id)}>delete</span>
                                </div>

                            </div>
                        )
                        : <div>در حال دریافت ...</div>
                }
            </div>
        );
    }
}

export default gpn(Contact);