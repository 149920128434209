import React, {Component} from 'react';
import {convertFromHTML, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {stateToHTML} from "draft-js-export-html";
import ContentState from "draft-js/lib/ContentState";

export default class PostEditor extends Component {
    constructor(props) {
        super(props);


        this.state = {
            editorState: EditorState.createEmpty(),
            contentIsSet: false
        };


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && !this.state.contentIsSet) {
            if (this.props.content !== "") {
                this.initContent();
            }
        }
    }

    initContent() {
        if (this.props.content) {
            const blocksFromHTML = convertFromHTML(this.props.content);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            this.setState({
                editorState: EditorState.createWithContent(state),
                contentIsSet: true
            });

        }
    }

    componentDidMount() {
        this.initContent();
    }

    onEditorStateChange = (editorState) => {
        this.props.onChange(stateToHTML(editorState.getCurrentContent()))
        this.setState({
            editorState,
        });
    };

    render() {
        const {editorState} = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                localization={{
                    locale: 'fa',
                }}
                onEditorStateChange={this.onEditorStateChange}
            />
        )
    }
}
