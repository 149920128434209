export default function ModalDelete({
                                        title,
                                        description,
                                        onYes,
                                        onNo
                                    }) {


    return (
        <div className="modal-delete">
            <div className="card-modal">
                <div className="head">
                    <div className="row">
                        <div className="col-auto">
                            <span className="material" onClick={() => {
                                if (onNo) {
                                    onNo();
                                }
                            }}>close</span>
                        </div>
                        <div className="col"><h1>{title}</h1></div>
                    </div>
                </div>
                <p>{description}</p>
                <div className="opt">
                    <div className="row">
                        <div className="col-7">
                            <div className="form-control">
                                <button type="button"
                                        style={{backgroundColor: "#858585"}}
                                        onClick={() => {
                                            if (onNo) {
                                                onNo();
                                            }
                                        }}>خیر
                                </button>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-control">
                                <button type="button"
                                        style={{backgroundColor: "#b62726"}}
                                        onClick={() => {
                                            if (onYes) {
                                                onYes();
                                            }
                                        }}>بله
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
