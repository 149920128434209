import {useState} from "react";

export default function SingleField({
                                        name,
                                        info,
                                        type,
                                        textAlign,
                                        dir,
                                        label,
                                        maxLength,
                                        placeholder,
                                        col,
                                        os,
                                        oc,
                                        onAccept,
                                        onReject,
                                        onPending,
                                        disabled
                                    }) {

    const {status, value} = info;

    const [rejectText, setRejectText] = useState("");

    function statusColor() {
        return status === "pending" ? "#fd7f27" : status === "accepted" ? "#20a720" : "#d12828"
    }

    return (<div className={col ? col : "col-md-6"}>
        <form autoComplete="off"
              style={{borderColor: statusColor()}}
              className="form-agent-single-field"
              onSubmit={e => {
                  e.preventDefault();
              }}>
            {type === "textarea" ? <div className="form-control">
                <label htmlFor={name}>{label}:</label>
                <textarea
                    style={{textAlign: textAlign ? textAlign : "center", direction: dir ? dir : "rtl"}}
                    name={name}
                    disabled={disabled}
                    onChange={e => {
                        if (!disabled) {
                            let {value} = e.target;
                            value = value.slice(0, maxLength ? maxLength : 2000);
                            if (oc) {
                                oc(name, value);

                            }
                        }

                    }}
                    placeholder={placeholder}
                    value={value}
                    id={name}/>
                <div className="row">
                    <div className="col">

                    </div>

                </div>
            </div> : <div className="form-control">
                <div className="row">
                    <div className="col">
                        <label htmlFor={name}>{label}:</label>
                        <input
                            style={{
                                textAlign: textAlign ? textAlign : "center", direction: dir ? dir : "rtl"
                            }}
                            type={type ? type : "text"}
                            name={name}
                            onChange={e => {
                                if (!disabled) {
                                    let {value} = e.target;
                                    value = value.slice(0, maxLength ? maxLength : 2000);
                                    if (oc) {

                                        oc(name, value);

                                    }
                                }
                            }}
                            placeholder={placeholder}
                            value={value}
                            id={name}/>
                    </div>
                </div>
            </div>}

            {
                !disabled ?
                    <div className="opt">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                            <span className="material accept" onClick={() => {
                                if (onAccept) {
                                    onAccept(name);
                                }
                            }}>check</span>
                            </div>
                            <div className="col-auto">
                        <span className="material pending" onClick={() => {
                            if (onPending) {
                                onPending(name);
                            }
                        }}>refresh</span>
                            </div>
                            <div className="col-auto">
                                <input type="text"
                                       name="rejectText"
                                       className="input-reject-text"
                                       value={rejectText}
                                       onChange={(e) => {
                                           setRejectText(e.target.value)
                                       }}
                                       placeholder="دلیل رد کردن"/>
                                <span className="material reject" onClick={() => {
                                    if (onReject) {
                                        onReject(name, rejectText);
                                    }
                                }}>close</span>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </form>
    </div>)
}