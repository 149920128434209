import React, {Component} from 'react';
import {gpn} from "./GPN"
import Header from "./components/sections/Header";
import config from "./config";
import {toast} from "react-toastify";
import axios from "axios";
import Main from "./components/sections/Main";
import Calculator from "./components/sections/Calculator";
import SideBar from "./components/sections/SideBar";

class App extends Component {

    state = {
        tokenIsValid: false,
        username: "",

        mio: true
    };


    componentDidMount() {
        this.checkToken();
        this.info();
    }

    info() {
        if (document.location.pathname !== "/login" && document.location.pathname !== "/init") {

            axios.get(`${config.api}/info?token=${config.token}`)
                .then(r => {
                    this.setState({
                        username: r.data.username
                    });
                })
                .catch(err => {
                    let code = err.response.status.toString()
                    if (code === "403" || code === "401") {
                        toast.error("دوباره وارد شوید!")
                        localStorage.removeItem("token");
                        document.location.href = "/login";
                    }
                });
        }
    }

    checkToken() {
        const pathname = document.location.pathname;
        const {token} = config;
        let isValid = true
        if (pathname !== "/login" && pathname !== "/init") {
            if (token === undefined) {
                isValid = false;
            } else if (!token) {
                isValid = false;
            } else if (token === "") {
                isValid = false;
            }
            if (!isValid) {
                document.location.href = "/login";
            }
            this.setState({
                tokenIsValid: isValid
            });
        }
    }

    setTitle(title) {
        document.title = title;
    }

    setMIO() {
        this.setState({mio: false});

    }

    render() {
        const {navigate} = this.props;
        const {tokenIsValid, username, mio} = this.state;
        let ro = {navigate, mio, setTitle: this.setTitle, setMIO: this.setMIO.bind(this)};

        return (
            <div className="container-main">
                <Header username={username} {...this.props} ro={ro} mio={mio} tokenIsValid={tokenIsValid}/>
                <Calculator/>
                <main>
                    <div className="row">
                        <SideBar tokenIsValid={tokenIsValid} ro={ro} checkRef={this.checkRef}/>
                        <Main {...this.props} ro={ro} mio={false}/>

                    </div>
                </main>
            </div>
        );
    }
}

export default gpn(App);
