import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Errors extends Component {

    state = {
        errors: null
    };


    componentDidMount() {
        this.getErrors();
    }

    getErrors() {
        axios.get(`${config.api}/error/list?token=${config.token}`)
            .then(r => {
                console.log('r.data: ', r.data);
                this.setState({errors: r.data.errors});
            })
            .catch(err => {
                console.error(err.stack);
            });
    }

    deleteErrors() {
        toast.info("در حال حذف ...")
        axios.delete(`${config.api}/errors?token=${config.token}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.")
                this.getErrors();
            })
            .catch(err => {
                toast.error("خطا!")
            });
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="errors-list">

                <div className="row">
                    <div className="col-auto">
                        <div className="form-control">
                            <button className="delete-errors"
                                    onDoubleClick={this.deleteErrors.bind(this)}
                                    style={{backgroundColor: "#992525"}}>حذف همه
                            </button>
                        </div>
                    </div>
                </div>
                <br/>
                {
                    errors ?
                        errors.length > 0 ?
                            errors.map((e, i) =>
                                <div key={"error" + i} className="error-item">
                                    <div className="row">
                                        <div className="col-auto">
                                            <span className="status">{e.status}</span>
                                        </div>
                                        <div className="col">
                                            <div className="path-name">{e.pathname}</div>
                                            <div className="message">{e.message}</div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="created-at">{e.created_at}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <p>موردی نیست!</p>
                        : <p>در حال دریافت ...</p>
                }
            </div>
        );
    }
}

export default gpn(Errors);