import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";

class Orders extends Component {

    state = {
        orders: [],
        page: 1,
        hasNextPage: false,
        totalPages: null,
        search: ""
    };

    componentDidMount() {
        this.getOrders(1, "");
    }


    getOrders(p, s) {
        const page = p ? p : this.state.page;
        const search = s || s.toString() === "" ? s : this.state.search;

        axios.get(`${config.api}/payment/order/all?token=${config.token}&page=${page}&search=${search}`)
            .then(r => {
                const {orders} = r.data;


                this.setState({
                    orders: page > 1 ? [...this.state.orders, ...orders.docs] : orders.docs,
                    page: page + 1,
                    hasNextPage: orders.hasNextPage,
                    totalPages: orders.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        const {page, hasNextPage, orders, search} = this.state;

        return (
            <div className="orders">

                <div className="search-box">
                    <div className="form-control">
                        <input type="text" name="search" id="search"
                               value={search}
                               onChange={e => {
                                   const {value} = e.target;
                                   this.getOrders(1, value);


                                   this.setState({search: value});
                               }}
                               placeholder="جستجو کنید :"/>
                    </div>
                </div>
                <br/><br/><br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getOrders(page, search)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {orders.map((o, i) =>
                        <div key={"o" + i} className="order" onClick={() => this.props.navigate(`/order/${o._id}`)}>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="payment-method"
                                         style={{
                                             backgroundColor: o.paymentMethod === "نقد" ? "#0e5480" :
                                                 o.paymentMethod === "چک" ? "#b9174e" : o.paymentMethod === "وام" ? "#49288d" : "#97342b"
                                         }}
                                    >{o.paymentMethod}</div>
                                </div>
                                <div className="col">
                                    <div className="status"
                                         style={{
                                             color: o.status.includes("پرداخت موفق") || o.status.includes("پرداخت شده") ? "#0d840d"
                                                 : o.status.includes("پرداخت ناموفق") || o.status.includes("پرداخت نشده") ? "#9c1116" : "#454545"
                                         }}
                                    >{o.status}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div
                                        className="full-name">{o.fullName ? o.fullName !== "" ? o.fullName : "بدون نام و نام خانوادگی" : "بدون نام و نام خانوادگی"}</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="phone-number">{o.phoneNumber}</div>
                                </div>
                            </div>
                        </div>)}
                </InfiniteScroll>
            </div>);
    }
}

export default gpn(Orders);