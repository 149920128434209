import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Boxes extends Component {

    state = {
        boxes: null
    };

    componentDidMount() {
        this.getAllBoxes();
    }

    getAllBoxes() {
        axios.get(`${config.api}/box/all?token=${config.token}`)
            .then(r => {
                this.setState({
                    boxes: r.data.boxes
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    deleteBox(id) {
        axios.delete(`${config.api}/box?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقت حذف شد.");
                this.getAllBoxes();
            })
            .catch(err => {

            });
    }

    render() {
        const {boxes} = this.state;
        return (
            <div className="boxes">
                <div className="row">
                    <div className="col-auto">
                        <button onClick={() => this.props.navigate("/box/create")}>+ جدید</button>
                    </div>
                </div>
                <br/><br/>
                {
                    boxes ?
                        boxes.length > 0 ?
                            boxes.map((b, i) =>
                                <div key={i} className="box">
                                    <div className="row">
                                        <div className="col-auto">
                                            <img src={b.image} alt=""/>
                                        </div>
                                        <div className="col" onClick={() => this.props.navigate("/box/" + b.id)}>
                                            <br/>
                                            <div>{b.title} ({b.type})
                                                -> <span
                                                    style={{direction: "ltr", textAlign: "left"}}>{b.url}</span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <br/>
                                            <span className="material"
                                                  onDoubleClick={this.deleteBox.bind(this, b.id)}>delete</span>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <div>موردی نیست</div>
                        : <div>در حال دریافت ...</div>
                }
            </div>
        );
    }
}


export default gpn(Boxes);