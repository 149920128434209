import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import Field from "../sections/MUI/Field";
import Select from "../sections/MUI/Select";
import PostEditor from "../sections/PostEditor";
import Button from "../sections/MUI/Button";
import {toast} from "react-toastify";
import {EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import VamTarhFields from "../sections/VamTarhFields";

class EditVamTarh extends Component {

    state = {
        id: this.props.params.id,
        title: "",
        content: "",
        price: "",
        data: {
            shoghl: ""
        },
        enable: "",
        fields: []
    };

    componentDidMount() {
        this.getVamTarh();
    }


    getVamTarh() {
        const {id} = this.state;
        axios.get(`${config.apiV1}/vam/tarh?id=${id}`)
            .then(r => {
                const {
                    title,
                    content,
                    data,
                    enable,
                    fields
                } = r.data;

                let price = r.data.price.toString().replace(/[^0-9]/g, "");
                price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                this.setState({
                    title,
                    content,
                    data,
                    enable,
                    price,
                    fields
                });
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }


    handleSubmit() {
        const {id, title, content, price, data, enable, fields} = this.state;
        if (title !== "" && price !== "") {
            let params = new URLSearchParams();
            params.append('id', id);
            params.append('title', title);
            params.append('content', content);
            params.append('price', price.replace(/,/g, "").toString().trim());
            params.append('data', JSON.stringify(data));
            params.append('enable', enable);
            params.append('fields', JSON.stringify(fields ? fields : []));

            toast.info("در حال ثبت ...");
            axios.put(`${config.api}/vam/tarh?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ذخیره شد!");
                    this.getVamTarh();
                })
                .catch(err => {
                    config.errorHandler(err);
                });
        } else {
            toast.error("نام طرح و مبلغ را وارد کنید!");
        }
    }

    render() {
        const {title, content, price, data, enable, fields} = this.state;
        return (
            <div className="edit-vam-tarh">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <Field type="text"
                                   name="title"
                                   value={title}
                                   label="نام طرح"
                                   onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>

                        <div className="col-md-6">
                            <Select simple
                                    value={enable}
                                    name="enable"
                                    options={[
                                        {t: "وضعیت: فعال", v: "true"},
                                        {t: "وضعیت: غیرفعال", v: "false"}
                                    ]}
                                    onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>

                        <div className="col-md-6">
                            <Field type="text"
                                   typePrice
                                   name="price"
                                   value={price}
                                   maxLength={12}
                                   style={{direction: "ltr"}}
                                   label="مبلغ"
                                   onChange={(name, value) => this.setState({[name]: value})}/>
                        </div>
                        <div className="col-md-6">
                            <Field type="text"
                                   name="shoghl"
                                   value={data.shoghl}
                                   label="شغل"
                                   onChange={(name, value) => {
                                       let d = data;
                                       d[name] = value
                                       this.setState({data: d})
                                   }}/>
                        </div>

                        <div className="col-12">
                            <br/>
                            <VamTarhFields fields={fields} onChange={(value) => {
                                this.setState({fields: value})
                            }}/>
                            <br/>
                        </div>

                        <div className="col-12">
                            <PostEditor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                content={content}
                                editorState={EditorState.createWithContent(ContentState.createFromText(content))}
                                onChange={(e) => {
                                    this.setState({content: e})
                                }}
                                toolbarClassName="toolbar-class"
                            />
                        </div>

                        <div className="col-12">
                            <br/>
                            <div className="row justify-content-end">
                                <div className="col-md-5">
                                    <Button submit={false} icon="edit" onClick={() => this.handleSubmit()}>ویرایش
                                        طرح</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default gpn(EditVamTarh);