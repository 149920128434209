import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";
import noImage from "../../assets/images/no-image.jpg";
import {toast} from "react-toastify";
import Field from "../sections/Field";
import Select from "../sections/MUI/Select";
import Button from "../sections/MUI/Button";
import Icon from "../sections/MUI/Icon";

class Products extends Component {


    state = {
        products: [],
        search: "",
        sort: "جدید ترین",

        page: 1,
        hasNextPage: true,
        totalPages: null
    }


    getProducts(page, search, sort) {
        axios.get(`${config.apiV1}/product/all?page=${page}&search=${search}&sort=${sort}`)
            .then(r => {
                const {products} = r.data;
                this.setState({
                    products: page > 1 ? [...this.state.products, ...products.docs] : products.docs,
                    page: page + 1,
                    hasNextPage: products.hasNextPage,
                    totalPages: products.totalPages
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    deleteProduct(index, id) {
        axios.delete(`${config.api}/product?token=${config.token}&id=${id}`)
            .then(r => {
                let {products} = this.state;
                products.splice(index, 1);
                this.setState({
                    products
                });
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    zeroPrices() {
        toast.info("صفر کردن ...")
        axios.put(`${config.api}/product/price/zero?token=${config.token}`, {})
            .then(r => {
                toast.success("صفر شد.");
                document.location.href = "/products";
            })
            .catch(err => {

            });
    }

    setPrice(index) {
        const {products} = this.state;
        let {id, price} = products[index];
        price = price.toString() !== "" ? price.toString() : "0";

        let params = new URLSearchParams();
        params.append('id', id);
        params.append('price', price);
        toast.info("در حال ثبت ...");
        axios.put(`${config.api}/product/set/price?token=${config.token}`, params)
            .then(r => {
                toast.success("ذخیره شد");
            })
            .catch(err => {
                config.errorHandler(err)
            });

    }

    render() {
        const {page, hasNextPage, products, search, sort} = this.state;
        return (
            <div className="products">
                <div className="row justify-content-between">
                    <div className="new-product" onClick={() => this.props.navigate('/product/create')}>
                        <span>+</span><span>محصول جدید</span>
                    </div>
                    {/*<div className="zero-price" onDoubleClick={() => this.zeroPrices()}>*/}
                    {/*    صفر کردن همه قیمت ها*/}
                    {/*</div>*/}
                </div>
                <br/>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <Field type="text"
                               value={search}
                               name={search}
                               dir={config.isLTR(search) ? "ltr" : "rtl"}
                               oc={(name, value) => {
                                   this.setState({search: value});
                                   this.getProducts(1, value, sort);
                               }}
                               textAlign="center"
                               placeholder="جستجو ..."/>
                    </div>
                    <div className="col-md-4">
                        <Select simple value={sort} name="sort"
                                options={[
                                    {t: "جدید ترین", v: "جدید ترین"},
                                    {t: "قدیمی ترین", v: "قدیمی ترین"},
                                    {t: "گران ترین", v: "گران ترین"},
                                    {t: "ارزان ترین", v: "ارزان ترین"}
                                ]}
                                onChange={(name, value) => {
                                    this.setState({[name]: value});
                                    this.getProducts(1, search, value);
                                }}/>
                    </div>
                </div>
                <br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getProducts(page, search, sort)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {
                        products ? products.map((p, i) =>
                            <div key={"p" + i} className="product">
                                <div className="row">
                                    <div className="col-auto">
                                        <img draggable={false}
                                             src={p.cover ? p.cover : noImage} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="body">
                                            <div className="title"
                                                 style={{textDecoration: p.inStock ? "none" : "line-through"}}
                                                 onClick={() => navigator.clipboard.writeText(p.title)}>{p.title + (p.inStock ? "" : " (ناموجود)")}</div>
                                            {
                                                p.inStock ?
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="price">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <Icon solid className="icon-change-price"
                                                                              onClick={() => this.setPrice(i)}
                                                                              i="check"/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <input type="number"
                                                                               name="price"
                                                                               autoComplete="off"
                                                                               onChange={e => {
                                                                                   const {value} = e.target;
                                                                                   let mProducts = products;
                                                                                   mProducts[i].price = value.toString();
                                                                                   this.setState({products: mProducts});
                                                                               }
                                                                               }
                                                                               value={p.price ? p.price.toString() : "0"}
                                                                               id="price"/>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="material delete"
                                              onDoubleClick={this.deleteProduct.bind(this, i, p.id)}>delete</span>
                                        <span className="material edit"
                                              onClick={() => this.props.navigate(`/product/edit/${p.id}`)}>edit</span>

                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </InfiniteScroll>
            </div>
        );
    }
}

export default gpn(Products);