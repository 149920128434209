import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Collapsible from "react-collapsible";
import {Link} from "react-router-dom";
import Icon from "./Icon";

class SideBarCollapse extends Component {

    state = {
        open: false
    };

    render() {
        const {open} = this.state;
        let {title, chi, checkRefClick} = this.props;
        title = title ? title : "";
        chi = chi ? chi : []

        return (
            <Collapsible
                onTriggerOpening={() => this.setState({open: true})}
                onTriggerClosing={() => this.setState({open: false})}
                trigger={
                    <div className="collapse-header">
                        <span className="title">{title}</span>
                        <Icon i={open ? "angle-up" : "angle-down"}/>
                    </div>
                }
                transitionTime={50}>
                <div className="body">
                    {chi.map((ch, i) =>
                        <div className="side-collapse-link" key={"child_" + i} onClick={() => {
                            this.props.navigate(ch.url);
                            if (checkRefClick) {
                                checkRefClick();
                            }
                        }}>{ch.title}</div>
                    )}
                </div>
            </Collapsible>
        );
    }
}

export default gpn(SideBarCollapse);