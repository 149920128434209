import React, {Component} from 'react';
import {gpn} from "../../GPN"

class Select extends Component {

    state = {};

    render() {
        const {name, value, oc, options} = this.props
        return (
            <div className="form-control">
                <select name={name}
                        value={value}
                        onChange={e => {
                            const {name, value} = e.target;
                            if (oc) {
                                oc(name, value)
                            }
                        }}
                        id={name}>
                    {options?.map((o, i) =>
                        <option value={o.value} key={name + "o" + i}>{o.title}</option>
                    )}
                </select>
            </div>
        );
    }
}

export default gpn(Select);