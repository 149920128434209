import React, {Component} from 'react';
import {gpn} from "../../GPN"

class PostForResult extends Component {

    state = {};

    render() {
        const {posts, modal, onModalClose, onPostClick} = this.props;
        if (modal) {
            return (
                <div className="post-for-result">
                    <div className="card">
                        <div className="row">
                            <div className="col-auto">
                                <span className="material" onClick={() => onModalClose()}>close</span>
                            </div>
                        </div>
                        {
                            posts ? posts.length > 0 ?
                                posts.map((post, i) =>
                                    <div key={"post" + i} className="post-modal" onClick={() => onPostClick(post)}>
                                        <div className="row">
                                            <div className="col-auto">
                                                <img src={post.image} alt=""/>
                                            </div>
                                            <div className="col">
                                                <div className="title">{post.title}</div>
                                                <div className="url">{post.url}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null : <div>موردی نیست</div>
                        }
                    </div>
                </div>
            );
        } else return null

    }
}

export default gpn(PostForResult);