import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";
import Icon from "../sections/MUI/Icon";
import {toast} from "react-toastify";

class AgentCustomers extends Component {

    state = {
        customers: [],
        page: 1,
        hasNextPage: true,
        totalPages: null,
        search: ""
    };

    getCustomers(p, s) {
        const page = p ? p : this.state.page;
        const search = s || s.toString() === "" ? s : this.state.search;

        axios.get(`${config.api}/agent/customer/list?token=${config.token}&page=${page}&search=${search}`)
            .then(r => {
                const {customers} = r.data;


                this.setState({
                    customers: page > 1 ? [...this.state.customers, ...customers.docs] : customers.docs,
                    page: page + 1,
                    hasNextPage: customers.hasNextPage,
                    totalPages: customers.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }

    handleDelete(id) {
        axios.delete(`${config.api}/agent/customer?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                this.setState({
                    customers: [],
                    page: 1,
                    hasNextPage: true,
                    totalPages: null,
                });
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    render() {
        const {page, hasNextPage, customers, search} = this.state;
        return (
            <div className="agent-customers">

                <div className="search-box">
                    <div className="form-control">
                        <input type="text" name="search" id="search"
                               value={search}
                               onChange={e => {
                                   const {value} = e.target;
                                   this.getCustomers(1, value);


                                   this.setState({search: value});
                               }}
                               placeholder="جستجو کنید (نام و نام خانوادگی یا شماره موبایل):"/>
                    </div>
                </div>
                <br/><br/><br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getCustomers(page, search)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {customers.map((c, i) =>
                        <div key={"c" + i} className="customer">
                            <div className="row">
                                <div className="col">
                                    <div className="fullName">{c.fullName}</div>
                                    <div className="phoneNumber">{c.phoneNumber}</div>
                                    <div className="agent">
                                        <p className="full-name">
                                            <span>نماینده:</span><span>{c.agent.fullName + " - " + c.agent.phoneNumber}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Icon i="trash" className="icon-delete"
                                          onDoubleClick={() => this.handleDelete(c.id)}/>
                                </div>
                            </div>

                        </div>)}
                </InfiniteScroll>
            </div>
        );
    }
}

export default gpn(AgentCustomers);