import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Posts from "./Posts";
import PostEditor from "../sections/PostEditor";
import Fab from "../sections/Fab";
import Modal from "../sections/Modal";
import {Cascader} from "antd";

class CreatePost extends Component {

    state = {
        title: "",
        content: "",
        category: "",
        categories: [],
        images: [],
        editorState: "",

        uploadModal: false,
        img: null,
        uploadUrl: ""
    };

    componentDidMount() {
        this.getCategories();
    }


    getCategories() {
        axios.get(`${config.apiV1}/categories`)
            .then(r => {
                window.scrollTo(0, 0);
                this.setState({
                    categories: r.data
                });
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({
            images: arrFiles
        });

    }

    handleCategoryChange(selected) {
        let arr = selected.includes(",") ? selected.split(',') : selected;

        const category = arr;
        this.setState({
            category
        });
    }

    uploadImage(img) {
        if (img) {
            toast.success("در حال آپلود ...")
            let params = new FormData();
            params.append('img', img);

            axios.post(`${config.api}/upload/image?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    this.setState({uploadUrl: r.data.url});
                    toast.success("با موفقیت آپلود شد.")
                })
                .catch(err => {
                    toast.error("خطا!")
                });
        }
    }

    handleSendPost() {
        const {title, category, content, images} = this.state;
        if (title !== "" && category !== "") {
            let params = new FormData();
            params.append('title', title);
            params.append('category', JSON.stringify(category));
            params.append('content', content);

            Array.from(images).forEach(image => {
                params.append("images", image);
            });

            axios.post(`${config.api}/post?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    toast.success("با موفقیت ارسال شد.");
                    setTimeout(() => {
                        this.props.navigate(-1);
                    }, 2500)
                })
                .catch(err => {
                    console.error(err);
                    toast.error("خطایی رخ داده است!");
                });
        } else {
            toast.error("فیلدها را به درستی کامل کنید!")
        }

    }

    render() {
        const {title, content, categories, images, uploadUrl} = this.state;

        return (
            <div className="create-post">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="title">عنوان:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       onChange={this.handleInputChange.bind(this)}
                                       value={title}
                                       placeholder="عنوان"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="images">تصاویر:

                                    <div className="images">
                                        {
                                            images.map((img, i) =>
                                                <img src={URL.createObjectURL(img)} key={"img" + i} alt=""/>
                                            )
                                        }
                                    </div>
                                </label>
                                <input type="file"
                                       name="images"
                                       id="images"
                                       multiple
                                       onChange={this.handleFileChange.bind(this)}
                                       accept="image/*"
                                       style={{
                                           display: images.length > 0 ? "none" : "inline-block"
                                       }}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">

                                <label htmlFor="category">دسته بندی:</label>
                                <Cascader options={categories.cascaderCategories}
                                          id="category"
                                          changeOnSelect
                                          bordered={false}
                                          multiple={false}
                                          style={{width: '100%', borderBottom: "1px solid #cdcdcd"}}
                                          onChange={(e) => this.handleCategoryChange(e)}
                                          size={"large"}
                                          placement={"bottomLeft"}
                                          expandIcon={<span>&nbsp;</span>}
                                          placeholder="دسته بندی ..."/>
                            </div>
                        </div>
                        <div className="col-12">
                            <PostEditor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onChange={(e) => {
                                    this.setState({content: e})
                                }}
                                toolbarClassName="toolbar-class"
                            />
                        </div>
                        <div className="col-md-6">
                            <br/><br/><br/>
                            <button type="button" onClick={this.handleSendPost.bind(this)}>ارسال پست</button>
                        </div>
                    </div>
                </form>
                <Fab icon="cloud_upload" size={14} onClick={() => {
                    this.setState({
                        uploadModal: true
                    })
                }}/>
                <Modal show={this.state.uploadModal}
                       title="آپلود عکس"
                       onCloseClick={() => {
                           this.setState({
                               uploadModal: false
                           })
                       }}>
                    <div className="form-control">
                        <label htmlFor="img">عکس:</label>
                        <input type="file"
                               name="img"
                               id="img"
                               onChange={(e) => {
                                   const {files} = e.target;
                                   const arrFiles = Array.from(files);

                                   this.setState({
                                       img: arrFiles[0]
                                   });
                                   this.uploadImage(arrFiles[0])
                               }}/>
                        {
                            uploadUrl !== "" ?
                                <input type="text" name="uploadUrl" id="uploadUrl" value={uploadUrl}/>
                                : null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

export default gpn(CreatePost);