import React, {Component} from 'react';
import {gpn} from "../../GPN"

class Fab extends Component {

    state = {};

    render() {
        const {onClick, icon, size} = this.props;
        return (
            <div className="fab" style={{fontStyle: size}} onClick={onClick}><span className="material">{icon}</span>
            </div>
        );
    }
}

export default gpn(Fab);