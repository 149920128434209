import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class Admins extends Component {

    state = {
        fullName: "",
        username: "",

        admins: []
    };

    componentDidMount() {
        this.getInfo();
    }

    getInfo() {
        axios.get(`${config.api}/info?token=${config.token}`)
            .then(r => {
                const {fullName, username} = r.data;
                this.setState({
                    fullName,
                    username
                });
                this.getAdmins();
            })
            .catch(err => {

            });
    }

    getAdmins() {
        axios.get(`${config.api}/admin/list?token=${config.token}`)
            .then(r => {
                this.setState({admins: r.data.admins});
            })
            .catch(err => {

            });
    }

    deleteAdmin(id) {
        toast.info("در حال حذف ...")
        axios.delete(`${config.api}/admin?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                this.getAdmins();
            })
            .catch(err => {
                console.error(err.stack);
                toast.error("خطا!")
            });
    }

    render() {
        const {username, admins} = this.state;

        return (
            <div className="admins">
                <div className="card">
                    <div className="new">
                        <div className="row">
                            <div className="col-auto">
                                <button type="button"
                                        onClick={() => this.props.navigate("/admin/new")}>+ ایجاد مدیر
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="list">
                        {admins?.map((a, i) =>
                            <div className="admin" key={"admin" + i}
                                 style={username === a.username ? {backgroundColor: "rgba(0,119,255,0.06)"} : {}}>
                                <div className="row">
                                    <div className="col">
                                        <p className="fullName">{a.fullName + (username === a.username ? "  (شما)" : "")}</p>
                                        <p className="username">{a.username}</p>
                                    </div>
                                    <div className="col-auto">
                                        {username !== a.username && a.username !== "Majid" ?
                                            <span className="material"
                                                  onDoubleClick={this.deleteAdmin.bind(this, a._id)}>delete</span>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default gpn(Admins);