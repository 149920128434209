export default function Icon({far, i, onClick, onDoubleClick, style, className}) {
    className = className ? className : "";

    className = "ficon " + className + " ";

    if (far) {
        className = className + "far ";
    } else {
        className = className + "fas ";
    }

    className = className + "fa-" + (i ? i.toString().trim() : "");

    return (
        <span style={{...style}} className={className}
              onDoubleClick={() => {
                  if (onDoubleClick) {
                      onDoubleClick();
                  }
              }}
              onClick={() => {
                  if (onClick) {
                      onClick();
                  }
              }}></span>
    )
}