import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Field from "../sections/Field";

class Slides extends Component {

    state = {
        slides: null
    };

    componentDidMount() {
        this.getSlides();
    }


    getSlides() {
        axios.get(`${config.apiV1}/slide`)
            .then(r => {
                const {slides} = r.data;
                this.setState({
                    slides
                });
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    handleDelete(id) {
        axios.delete(`${config.api}/slide?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                this.getSlides();
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    handleInputChange(name, value, index) {
        let {slides} = this.state;
        slides[index][name] = value;
        this.setState({slides});

        const id = slides[index].id;

        let params = new URLSearchParams();
        params.append('id', id);
        params.append('name', name);
        params.append('value', value);


        axios.put(`${config.api}/slide?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });
    }


    render() {
        const {slides} = this.state;
        return (<div className="slides">

            <div className="row">
                <div className="col-auto">
                    <div className="form-control">
                        <button typeof="button"
                                onClick={() => this.props.navigate("/slide/create")}>+ ایجاد اسلاید
                        </button>
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className="list">
                {slides?.map((s, i) =>
                    <div key={"s" + i} className="slide">
                        <img src={s.image} alt=""/>

                        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field type="text"
                                           name="title"
                                           textAlign="center"
                                           oc={(name, value) => this.handleInputChange(name, value, i)}
                                           value={s.title ? s.title : ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field type="text"
                                           name="link"
                                           dir="ltr"
                                           textAlign="center"
                                           oc={(name, value) => this.handleInputChange(name, value, i)}
                                           value={s.link ? s.link : ""}
                                    />
                                </div>
                            </div>


                            <Field type="textarea"
                                   row={3}
                                   name="content"
                                   oc={(name, value) => this.handleInputChange(name, value, i)}
                                   value={s.content ? s.content : ""}
                            />
                        </form>
                        <div className="delete-wrapper">
                            <span className="material"
                                  onDoubleClick={this.handleDelete.bind(this, s.id)}>delete</span>
                        </div>
                    </div>)}
            </div>
        </div>);
    }
}

export default gpn(Slides);