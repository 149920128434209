import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Button from "../sections/MUI/Button";
import axios from "axios";
import config from "../../config";
import noImage from "../../assets/images/no-image.jpg";
import Icon from "../sections/MUI/Icon";

class Hamkars extends Component {

    state = {
        hamkars: null
    };

    componentDidMount() {
        this.getHamkars();
    }

    getHamkars() {
        axios.get(`${config.apiV1}/hamkar/list`)
            .then(r => {
                this.setState({
                    hamkars: r.data.hamkars
                });
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    render() {
        const {hamkars} = this.state;

        return (
            <div className="hamkars">
                <div className="row">
                    <div className="col-6">
                        <Button submit={false}
                                onClick={() => this.props.navigate("/hamkar/create")}
                                backgroundColor="#1565C0"
                                icon="plus">ثبت همکار</Button>
                    </div>
                </div>

                <br/><br/>
                <div className="list">
                    {hamkars ?
                        hamkars.length > 0 ?
                            hamkars.map((h, i) =>
                                <article key={"h" + i}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <img src={h.image ? h.image : noImage} alt=""/>
                                        </div>
                                        <div className="col">
                                            <p className="title">{h.title}</p>

                                        </div>
                                        <div className="col-auto">
                                            <Icon i="edit"
                                                  solid
                                                  className="edit-icon"
                                                  onClick={() => this.props.navigate(`/hamkar/${h.id}`)}/>
                                        </div>
                                    </div>
                                </article>
                            )
                            : <p>موردی نیست!</p>
                        : <p>در حال بارگیری ...</p>}
                </div>
            </div>
        );
    }

}

export default gpn(Hamkars);