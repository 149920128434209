import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Select from "../sections/MUI/Select";
import Field from "../sections/MUI/Field";
import Button from "../sections/MUI/Button";
import axios from "axios";
import config from "../../config";
import Toggle from "../sections/MUI/Toggle";
import Icon from "../sections/MUI/Icon";
import FileDownload from "js-file-download";

class MajidServices extends Component {

    state = {
        status: "تسویه نشده",
        services: [],
        sum: "",
        categories: [
            {
                subject: "اعتبار سنجی",
                price: 100000
            },
            {
                subject: "پرینت حساب",
                price: 100000
            },
            {
                subject: "بنر یا تراکت",
                price: 100000
            },
            {
                subject: "حکم یا فیش",
                price: 50000
            },
            {
                subject: "ثبت چک",
                price: 10000
            },
            {
                subject: "تایید چک",
                price: 10000
            },
            {
                subject: "رد یا انتقال چک",
                price: 10000
            },
            {
                subject: "انتقال وجه",
                price: 25000
            },
            {
                subject: "طراحی فایل فرد",
                price: 25000
            },
            {
                subject: "تغییر پی دی اف یا ورد",
                price: 10000
            }
        ],
        category: "",

        subject: "",
        price: ""
    };

    componentDidMount() {
        this.getServices(this.state.status);
    }


    getServices(status) {
        axios.get(`${config.api}/majid/services?token=${config.token}&status=${status}`)
            .then(r => {
                console.log('r.data: ', r.data);

                const {services, sum} = r.data;
                this.setState({services, sum});
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleSubmit() {
        const {subject, price} = this.state;
        let params = new URLSearchParams();
        params.append('subject', subject);
        params.append('price', price !== "" ? price.toString() : "0");

        axios.post(`${config.api}/majid/services?token=${config.token}`, params)
            .then(r => {
                this.setState({
                    category: "",
                    subject: "",
                    price: ""
                });
                this.getServices(this.state.status);
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleStatus(id, status) {
        axios.put(`${config.api}/majid/services?token=${config.token}&id=${id}&status=${status}`)
            .then(r => {
                this.getServices(this.state.status);
            })
            .catch(err => {
                config.errorHandler(err);
            });

    }

    handleDelete(id) {
        axios.delete(`${config.api}/majid/services?token=${config.token}&id=${id}`)
            .then(r => {
                this.getServices(this.state.status);
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleExcel() {
        axios.get(`${config.api}/majid/services/excel?token=${config.token}&status=${this.state.status}`, {responseType: 'blob'})
            .then(r => {
                try {
                    FileDownload(r.data, decodeURI(r.headers["x-suggested-filename"]));
                } catch (e) {
                }
            })
            .catch(err => {
                console.error('doc download err: ', err);
            });
    }

    render() {
        const {
            categories, category,
            subject, price,
            sum, status, services
        } = this.state;
        return (
            <div className="majid-services">
                <div className="new">
                    <div className="row">
                        <div className="col-3">
                            <Select
                                name="category"
                                simple
                                onChange={(name, value) => {
                                    let cat = categories[Number(value.toString())];
                                    this.setState({
                                        [name]: value,
                                        subject: cat.subject + " ",
                                        price: cat.price
                                    })
                                }}
                                placeholder="خدمات ..."
                                options={categories.map((c, i) => {
                                    return {
                                        t: c.subject + ` (${c.price / 1000}ت)`,
                                        v: i
                                    }
                                })} value={category}/>
                        </div>
                        <div className="col-4">
                            <Field name="subject"
                                   label="عنوان خدمت"
                                   onChange={(name, value) => this.setState({[name]: value})}
                                   value={subject}/>
                        </div>
                        <div className="col-3">
                            <Field name="price"
                                   type="number"
                                   style={{direction: "ltr"}}
                                   label="نرخ"
                                   onChange={(name, value) => this.setState({[name]: value})}
                                   value={price}/>
                        </div>
                        <div className="col-2">
                            <p style={{fontSize: "4pt"}}>&nbsp;</p>
                            <Button icon="check"
                                    onClick={() => this.handleSubmit()}
                                    submit={false}>ذخیره</Button>
                        </div>
                    </div>
                </div>
                <br/>
                <Toggle t1={{title: "تسویه نشده", icon: null}} t2={{title: "تسویه شده", icon: null}} name="status"
                        value={status}
                        onChange={(name, value) => {
                            this.setState({
                                sum: "",
                                services: [],
                                [name]: value
                            });
                            this.getServices(value);
                        }}/>

                <div className="row">
                    <div className="col-md-6">
                        <div className="sum"><span>جمع کل:</span>&nbsp;<span>{sum}</span></div>
                    </div>
                    <div className="col-md-6">
                        <Button submit={false}
                                onClick={() => this.handleExcel()}
                                backgroundColor="#004D40">خروجی اکسل</Button>
                    </div>
                </div>
                <div className="list">
                    {services.map((s, i) =>
                        <article key={"ser" + i}>
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className="subject">{s.subject}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="price">{s.price}</p>
                                        </div>
                                        <div className="col-4">
                                            <Select simple
                                                    name={"status" + i}
                                                    onChange={(name, value) => {
                                                        let mServices = services;
                                                        mServices[i].status = value;
                                                        this.setState({services: mServices});
                                                        this.handleStatus(s.id, value);
                                                    }}
                                                    value={s.status}
                                                    options={[
                                                        {t: "تسویه شده", v: "تسویه شده"},
                                                        {t: "تسویه نشده", v: "تسویه نشده"}
                                                    ]}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Icon i="trash"
                                          onDoubleClick={() => this.handleDelete(s.id)}
                                          className="delete-icon"
                                    />
                                </div>
                            </div>
                        </article>
                    )}
                </div>
            </div>
        );
    }
}

export default gpn(MajidServices);