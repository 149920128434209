import React, {Component} from 'react';
import {gpn} from "../GPN"
import axios from "axios";
import config from "../config";
import {toast} from "react-toastify";

class Login extends Component {

    state = {
        username: "",
        password: ""
    };

    componentDidMount() {
        this.props.setTitle("ورود به حساب");
        this.checkInit();
    }

    checkInit() {
        axios.get(`${config.api}/init/check`)
            .then(r => {
                if (r.data.success) {
                    if (!r.data.init) {
                        document.location.href = "/init";
                    }
                }
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    login(e) {
        e.preventDefault();
        const {username, password} = this.state;

        let params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);

        axios.post(`${config.api}/login`, params)
            .then(r => {
                if (r.data.success) {
                    toast.success("خوش آمدید ...");
                    localStorage.setItem("token", r.data.token);
                    setTimeout(() => {
                        document.location.href = "/";
                    }, 3000);
                }
            })
            .catch(err => {
                toast.error("نام کاربری یا رمز عبور اشتباه است!");
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    render() {
        const {username, password} = this.state;

        return (
            <div className="login">
                <form onSubmit={this.login.bind(this)}>
                    <h1>ورود ادمین</h1>
                    <div className="form-control">
                        <label htmlFor="username">نام کاربری:</label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            style={{
                                direction: "ltr",
                                textAlign: "center"
                            }}
                            value={username}
                            placeholder="نام کاربری"
                            onChange={this.handleInputChange.bind(this)}/>
                    </div>
                    <div className="form-control">
                        <label htmlFor="password">گذرواژه:</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            style={{
                                direction: "ltr",
                                textAlign: "center"
                            }}
                            value={password}
                            placeholder="گذرواژه"
                            onChange={this.handleInputChange.bind(this)}/>
                    </div>

                    <div className="form-control">
                        <br/>
                        <button type="submit">ورود</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default gpn(Login);