import Icon from "./Icon";
import React, {useEffect, useState} from "react";

function Field({
                   onEnterPress,
                   type,
                   typePrice,
                   name,
                   row,
                   label,
                   icon,
                   maxLength,
                   onChange,
                   value,
                   placeholder,
                   style,
                   autoComplete
               }) {
    name = name ? name : "textField";
    type = type ? type : "text";
    type = typePrice ? "text" : type;
    row = row ? row : 3;
    maxLength = maxLength ? !isNaN(Number(maxLength.toString())) ? Number(maxLength.toString()) : 2000 : 2000;
    value = value ? value : "";
    autoComplete = autoComplete ? "on" : "off";
    style = style ? style : {};

    let inputref = React.createRef();
    let labelref = React.createRef();
    let labelbodyref = React.createRef();

    const [mType, setMType] = useState(type);

    let labelStyle = {}
    let labelBodyStyle = {}

    if (value.toString().trim() !== "") {
        labelStyle = {
            marginTop: "-10px",
        }
        labelBodyStyle = {
            backgroundColor: "#ffffff",
            cursor: "pointer",
            color: "#313131"
        }
    }


    return (
        <>
            {mType !== "textarea" ?
                <div className="form-control-text">
                    {label ?
                        <div className="label"
                             ref={labelref}
                             style={labelStyle}
                             onClick={() => {
                                 try {
                                     inputref.current.focus();
                                 } catch (err) {
                                 }
                             }
                             }>

                            <div className="label-body"
                                 style={labelBodyStyle}
                                 ref={labelbodyref}>
                                {icon ? <Icon i={icon}/> : null}
                                <span className="title">{label}</span>
                            </div>
                        </div> : null
                    }

                    <input type={mType}
                           name={name}
                           id={name}
                           style={style}
                           autoComplete={autoComplete}

                           onWheel={(e) => {
                               if (mType === "number") {
                                   e.target.blur();
                               }
                           }
                           }
                           onKeyDown={(e) => {
                               if (mType === "number") {
                                   try {
                                       if (e.which === 38 || e.which === 40) {
                                           e.preventDefault();
                                       }
                                   } catch (err) {
                                   }
                               }
                               if (e.code === "Enter") {
                                   if (onEnterPress) {
                                       onEnterPress();
                                   }
                               }
                           }
                           }
                           onFocus={() => {
                               labelref.current.style.marginTop = "-10px";
                               labelbodyref.current.style.backgroundColor = "#ffffff";
                               labelbodyref.current.style.cursor = "pointer";
                               labelbodyref.current.style.color = "#313131";
                           }
                           }
                           onBlur={() => {
                               if (value.toString().trim() === "") {
                                   labelref.current.style.marginTop = "8px";
                                   labelbodyref.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
                                   labelbodyref.current.style.cursor = "text";
                                   labelbodyref.current.style.color = "#656565";
                               }
                           }
                           }
                           value={value}
                           ref={inputref}
                           onChange={e => {
                               let {value} = e.target;
                               value = value.slice(0, maxLength);

                               if (onChange) {
                                   if (typePrice) {
                                       let price = value.toString().replace(/[^0-9]/g, "");
                                       price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                       onChange(name, price);
                                   } else {
                                       onChange(name, value);
                                   }
                               }
                           }
                           }/>

                    {
                        type === "password" ?
                            <Icon
                                onClick={() => setMType(mType === "password" ? "text" : "password")}
                                i={type === "password" && mType === "password" ? "eye" : "eye-slash"}
                                className="icon-eye-password"/>
                            : null
                    }
                </div>
                :
                <div className="form-control-textarea">
                    {label ? <label htmlFor={name}>
                        {icon ? <Icon i={icon}/> : null}
                        <span className="title">{label}:</span>
                    </label> : null}

                    <textarea name={name}
                              id={name}
                              style={style}
                              onChange={e => {
                                  let {value} = e.target;
                                  value = value.slice(0, maxLength);
                                  if (onChange) {
                                      onChange(name, value);
                                  }
                              }
                              }
                              value={value ? value : ""}
                              placeholder={placeholder ? placeholder : label ? label + " ..." : ""}
                              rows={row}></textarea>
                </div>
            }
        </>
    )
}


export default Field;