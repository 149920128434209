import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";
import noImage from "../../assets/images/no-image.jpg";

class Posts extends Component {


    state = {
        posts: [],

        page: 1,
        hasNextPage: true,
        totalPages: null
    }

    componentDidMount() {
        // this.getPosts();
    }


    getPosts(page) {
        axios.get(`${config.apiV1}/post/all?page=${page}`)
            .then(r => {
                const {posts} = r.data;
                this.setState({
                    posts: [...this.state.posts, ...posts.docs],
                    page: page + 1,
                    hasNextPage: posts.hasNextPage,
                    totalPages: posts.totalPages
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        const {page, hasNextPage, posts} = this.state;
        return (
            <div className="posts">
                <div className="row">
                    <div className="new-post" onClick={() => this.props.navigate('/post/create')}>
                        <span>+</span><span>پست جدید</span>
                    </div>
                </div>
                <br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getPosts(page)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {
                        posts ? posts.map((p, i) =>
                            <div key={"p" + i} className="post"
                                 onClick={() => this.props.navigate(`/post/edit/${p.id}`)}>
                                <div className="row">
                                    <div className="col-auto">
                                        <img draggable={false}
                                             src={p.images.length > 0 ? p.images[0] : noImage} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="body">
                                            <div className="title">{p.title}</div>
                                            <div className="author">{p.author.fullName}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </InfiniteScroll>
            </div>
        );
    }
}

export default gpn(Posts);