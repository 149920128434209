import React, {Component} from 'react';

class Field extends Component {

    state = {};

    render() {
        let {
            label,
            placeholder,
            name,
            value,
            oc,
            maxLength,
            dir,
            textAlign,
            type,
            row,
            style,
            disabled
        } = this.props;
        label = label ? label : "";
        placeholder = placeholder ? placeholder : "";
        name = name ? name : "";
        value = value ? value : "";
        dir = dir ? dir : "rtl";
        textAlign = textAlign ? textAlign : "right";
        type = type ? type : "text";
        row = row ? row : 4
        style = style ? style : {};
        style = {...style, textAlign, direction: dir};
        disabled = disabled !== null ? disabled : false;
        if (type !== "textarea") {
            return (
                <div className="form-control">
                    {label !== "" ?
                        <label htmlFor={name}>{label + ":"}</label> : null
                    }
                    <input type={type}
                           name={name}
                           disabled={disabled}
                           style={style}
                           id={name}
                           onWheel={(e) => {
                               if (type === "number") {
                                   e.target.blur()
                               }
                           }}
                           value={value}
                           onChange={e => {
                               let {value} = e.target;
                               let mMaxLength = maxLength ? maxLength : 1000;
                               value = value.slice(0, mMaxLength);
                               oc(name, value);
                           }}
                           placeholder={placeholder}/>
                </div>
            );
        } else {
            return (
                <div className="form-control">
                    {label !== "" ?
                        <label htmlFor={name}>{label + ":"}</label>
                        : null}
                    <textarea
                        name={name}
                        style={{textAlign, direction: dir}}
                        id={name}
                        disabled={disabled}
                        value={value}
                        rows={row}
                        onChange={e => {
                            let {value} = e.target;
                            let mMaxLength = maxLength ? maxLength : 1000;
                            value = value.slice(0, mMaxLength);
                            oc(name, value);
                        }}
                        placeholder={placeholder}/>
                </div>
            );
        }

    }
}

export default Field;