import Icon from "./Icon";

function Toggle({name, t1, t2, onChange, value, fontSize}) {
    fontSize = fontSize ? fontSize.toString().replace("pt", "") : "12";

    t1 = t1 ? t1 : {
        title: "مورد اول",
        icon: null
    };

    t2 = t2 ? t2 : {
        title: "مورد دوم",
        icon: null
    };

    return (
        <div className="mui-toggle" id={name}>
            <div className="row">
                <div className="col">
                    <div className={t1.title === value ? "tab active" : "tab"}
                         style={{fontSize: fontSize + "pt"}}
                         onClick={() => {
                             if (onChange) {
                                 onChange(name, t1.title);
                             }
                         }}>
                        {t1.icon ? <Icon i={t1.icon}/> : null}
                        <span className="title">{t1.title}</span>
                    </div>
                </div>
                <div className="col">
                    <div className={t2.title === value ? "tab active" : "tab"}
                         style={{fontSize: fontSize + "pt"}}
                         onClick={() => {
                             if (onChange) {
                                 onChange(name, t2.title);
                             }
                         }}>
                        {t2.icon ? <Icon i={t2.icon}/> : null}
                        <span className="title">{t2.title}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Toggle;