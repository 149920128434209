import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class User extends Component {

    state = {
        editMode: false,
        fullName: "",
        phoneNumber: "",
        orders: null,
        cart: null
    };

    componentDidMount() {
        this.getUser(this.props.params.id);
    };

    getUser(id) {
        axios.get(`${config.api}/user?token=${config.token}&id=${id}`)
            .then(r => {
                let {fullName, phoneNumber, orders, cart} = r.data.user;

                fullName = fullName ?
                    fullName.toString() !== "" ? fullName :
                        "بدون نام و نام خانوادگی" : "بدون نام و نام خانوادگی";
                phoneNumber = phoneNumber ? phoneNumber : "نامشخص";
                orders = orders ? orders : [];
                cart = cart ? cart : [];

                this.setState({
                    fullName, phoneNumber, orders, cart
                });
            })
            .catch(err => {
                console.error(err);
                toast.error("خطا!");
            });
    }

    deleteUser() {
        toast.info("در حال حذف کردن ...");
        const id = this.props.params.id;
        axios.delete(`${config.api}/user?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                setTimeout(() => {
                    this.props.navigate(-1);
                }, 1800)
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    editUser() {
        toast.info("در حال ویرایش کردن ...");
        const {fullName, phoneNumber} = this.state;
        const id = this.props.params.id;
        let params = new URLSearchParams();
        params.append('id', id);
        params.append('fullName', fullName);
        params.append('phoneNumber', phoneNumber);
        axios.put(`${config.api}/user?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ویرایش شد!");
                this.getUser(id)
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    render() {
        const {fullName, phoneNumber, orders, cart, editMode} = this.state;


        return (
            <div className="user">
                <div className="edit-mode"
                     style={editMode ? {backgroundColor: "#06417f", color: "white"} : {
                         backgroundColor: "#ededed",
                         color: "#545454"
                     }}
                     onClick={() => {
                         this.setState({editMode: !editMode})
                     }}>
                    <div className="row">
                        <span className="material">edit</span><span>حالت ویرایش</span>
                    </div>
                </div>

                <div className="row">
                    {editMode ?
                        <>
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="fullName">نام و نام خانوادگی:</label>
                                    <input type="text" name="fullName" id="fullName" value={fullName}
                                           onChange={
                                               (e) => {
                                                   let {value} = e.target;
                                                   value = value.slice(0, 155);
                                                   this.setState({fullName: value});
                                               }
                                           }/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="phoneNumber">شماره موبایل:</label>
                                    <input type="text" name="phoneNumber" id="phoneNumber" value={phoneNumber}
                                           onChange={
                                               (e) => {
                                                   let {value} = e.target;
                                                   value = value.slice(0, 11);
                                                   this.setState({phoneNumber: value});
                                               }
                                           }/>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-md-6">
                                <div className="full-name"><span>نام و نام خانوادگی:</span><span>{fullName}</span></div>
                            </div>
                            <div className="col-md-6">
                                <div className="phone-number"><span>شماره موبایل:</span><span>{phoneNumber}</span></div>
                            </div>
                        </>
                    }

                    <div className="col-md-12">
                        <br/><br/>
                        <div className="orders">

                            <h2>لیست سفارشات</h2>
                            {orders ?
                                orders.length > 0 ?
                                    orders.map((o, i) =>
                                        <div key={"o" + i} className="order-item">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="payment-method">{o.paymentMethod}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="status"
                                                         style={{color: o.status.includes("پرداخت موفق") ? "#0d840d" : o.status.includes("پرداخت نشده") ? "#9c1116" : "#454545"}}>{o.status}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="full-name">{o.fullName}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="phone-number">{o.phoneNumber}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="tel">{o.tel}</div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="address">{o.address}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="postal-code">{o.postalCode}</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="cart-data">
                                                        {o.cart?.map((oc, oci) =>
                                                            <div key={"oc" + oci} className="cart-data-item">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <img src={oc.images ? oc.images[0] : ""}
                                                                             alt=""/>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div
                                                                            className="title">{oc.title + "  " + "(" + oc.count + ")"}</div>
                                                                        <div
                                                                            className="price">{config.priceFormatter(oc.price)}</div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        {
                                                                            o.otherData ?
                                                                                <div
                                                                                    className="other-data">
                                                                                    <div
                                                                                        className="row justify-content-between">
                                                                                        <div className="col-auto">
                                                                                            <span>جمع اقساط:</span><span>{config.priceFormatter(o.otherData.percentOfMonths)}</span>
                                                                                        </div>
                                                                                        <div className="col-auto">
                                                                                            <span>مبلغ هر قسط اقساط:</span><span>{config.priceFormatter(o.otherData.pricePerGhest)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        <div className="amount">جمع
                                                                            کل:&nbsp;{config.priceFormatter(o.amount)}</div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="created-at">{o.created_at}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <button onClick={() => this.props.navigate(`/order/${o.id}`)}>رفتن
                                                        به سفارش
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <div>موردی نیست!</div>
                                : <div>در حال دریافت ...</div>}

                        </div>
                    </div>

                    <div className="col-md-12">
                        <br/><br/>

                        <div className="cart">
                            <h2>سبد خرید کاربر</h2>
                            {cart ?
                                cart.length > 0 ?
                                    cart.map((c, i) =>
                                        <div className="cart-item" key={"c" + i}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <img src={c.images ? c.images.length > 0 ? c.images[0] : "" : ""}
                                                         alt=""/>
                                                </div>
                                                <div className="col">
                                                    <div className="body">
                                                        <a
                                                            href={config.appURL + "/product/" + c.slug}
                                                            target="_blank"
                                                            rel="noreferrer">{c.title + " (" + c.count + ")"}</a>
                                                        <div className="price">{config.priceFormatter(c.price)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <div>موردی نیست!</div>
                                : <div>در حال دریافت ...</div>}
                        </div>
                    </div>

                    <div className="col-6">
                        <br/><br/>
                        <button type="button"
                                onClick={this.editUser.bind(this)}>ویرایش کردن
                        </button>
                    </div>
                    <div className="col-6">
                        <br/><br/>
                        <button type="button" style={{backgroundColor: "#9c1116"}}
                                onDoubleClick={this.deleteUser.bind(this)}>حذف کردن
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default gpn(User);