import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "../sections/MUI/Field";
import File from "../sections/MUI/File";
import PostEditor from "../sections/PostEditor";
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";
import Button from "../sections/MUI/Button";
import {EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";

class EditHamkar extends Component {

    state = {
        id: this.props.params.id,
        title: "",
        description: "",

        image: "",
        selectedImage: null,

    };

    componentDidMount() {
        this.getHamkar();
    }


    getHamkar() {
        const {id} = this.state;
        axios.get(`${config.api}/hamkar?token=${config.token}&id=${id}`)
            .then(r => {
                const {title, image, slug, description} = r.data;
                this.setState({title, image, description});
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    handleSubmit() {
        const {id, title, description, selectedImage} = this.state;
        if (title !== "") {
            toast.info("در حال ویرایش ...");
            let params = new FormData();
            params.append('id', id);
            params.append('title', title);
            params.append('description', description);
            if (selectedImage) {
                params.append('image', selectedImage);
            }

            axios.put(`${config.api}/hamkar?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    toast.success("با موفقیت ویرایش شد.");
                    this.getHamkar();
                })
                .catch(err => {
                    config.errorHandler(err);
                });
        } else {
            toast.error("فیلد عنوان را وارد کنید");
        }
    }

    handleDelete() {
        const {id} = this.state;
        toast.info("در حال حذف کردن ...");
        axios.delete(`${config.api}/hamkar?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                this.props.navigate(-1);
            })
            .catch(err => {
                config.errorHandler(err);
            });
    }

    render() {
        const {title, description, selectedImage} = this.state;

        return (<div className="create-hamkar">
            <div className="row">
                <div className="col-md-6">
                    <Field name="title"
                           value={title}
                           onChange={(name, value) => this.setState({[name]: value})}
                           label="نام همکار، فروشگاه یا ..."/>

                </div>
                <div className="col-md-6">
                    <File name="selectedImage"
                          label="عکس یا لوگو"
                          value={selectedImage}
                          onChange={(name, value) => this.setState({[name]: value})}
                          multiple={false}
                          accept=".png,.bmp,.gif,.jpg,.jpeg"/>
                </div>

                <div className="col-12">
                    <PostEditor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        content={description}
                        editorState={EditorState.createWithContent(ContentState.createFromText(description))}
                        onChange={(e) => {
                            this.setState({description: e})
                        }}
                        toolbarClassName="toolbar-class"
                    />
                    <br/><br/>
                    <div className="row justify-content-between">
                        <div className="col-md-5">
                            <Button icon="edit" submit={false} onClick={() => this.handleSubmit()}>ویرایش کردن</Button>

                        </div>
                        <div className="col-md-5">
                            <Button icon="trash" submit={false} onDoubleClick={() => this.handleDelete()}
                                    backgroundColor="#D50000">حذف کردن</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default gpn(EditHamkar);