import React, {Component} from 'react';
import {gpn} from "../../GPN"
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import config from "../../config";

class Documents extends Component {

    state = {
        documents: [],
        page: 1,
        hasNextPage: false,
        totalPages: null,
        search: ""
    };

    componentDidMount() {
        this.getDocuments(1, "");
    }


    getDocuments(p, s) {
        const page = p ? p : this.state.page;
        const search = s || s.toString() === "" ? s : this.state.search;

        axios.get(`${config.api}/document/all?token=${config.token}&page=${page}&search=${search}`)
            .then(r => {
                const {documents} = r.data;

                this.setState({
                    documents: page > 1 ? [...this.state.documents, ...documents.docs] : documents.docs,
                    page: page + 1,
                    hasNextPage: documents.hasNextPage,
                    totalPages: documents.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }


    getDocType(fullName) {
        let docType = "خرید کالا به صورت چکی (ام اس)";

        if (fullName.includes("حکمت")) {
            docType = "خرید کالا با حکمت کارت";
        } else if (fullName.includes(" - ")) {
            if (fullName.includes("امین") || fullName.includes("بردیا") || fullName.includes("محمد")) {
                docType = "دریافت تسهیلات";
            }
        }

        return docType;
    }

    render() {
        const {page, hasNextPage, documents, search} = this.state;
        return (
            <div className="documents">

                <div className="search-box">
                    <div className="form-control">
                        <input type="text" name="search" id="search"
                               value={search}
                               onChange={e => {
                                   const {value} = e.target;
                                   this.getDocuments(1, value);

                                   this.setState({search: value});
                               }}
                               placeholder="جستجو کنید (نام و نام خانوادگی یا شغل):"/>
                    </div>
                </div>
                <br/><br/><br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getDocuments(page, search)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {documents.map((d, i) =>
                        <div key={"d" + i} className="document"
                             onClick={() => this.props.navigate(`/document/${d._id}`)}>
                            <div className="row">
                                <div className="col">
                                    <div className="full-name">
                                        {d.fullName ?
                                            d.fullName !== "" ?
                                                d.fullName : "بدون نام و نام خانوادگی"
                                            : "بدون نام و نام خانوادگی"}
                                    </div>
                                    <div className="doc-type">{this.getDocType(d.fullName)}</div>
                                    <div className="shoghl">{d.shoghl}</div>
                                    <div className="row">
                                        <div className="phone-number">
                                            {d.phoneNumber ?
                                                d.phoneNumber.toString() !== "" ?
                                                    d.phoneNumber : "بدون شماره" : "بدون شماره"}
                                        </div>
                                        <div className="moaref">
                                            معرف:&nbsp;{d.moaref ?
                                            d.moaref.toString() !== "" ?
                                                d.moaref : "بدون معرف" : "بدون معرف"}
                                        </div>
                                    </div>

                                    {d.agent ?
                                        <section className="agent-data">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <p className="agent-full-name">
                                                        <span>نماینده:</span><span>{d.agent.information ? d.agent.information.fullName ? d.agent.information.fullName.value : "" : ""}</span>
                                                    </p>
                                                </div>
                                                <div className="col-auto">
                                                    <p className="agent-phone-number">
                                                        <span>شماره نماینده:</span><span>{d.agent.phoneNumber}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </section>
                                        : null}

                                    <p className="status">{d.status}</p>

                                    <div className="count">
                                        <span>مدارک ارسالی:</span><span>{this.getDocumentsCount(d)}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <span className="material">edit</span>
                                </div>
                            </div>
                        </div>)}
                </InfiniteScroll>
            </div>
        );
    }

    getDocumentsCount(doc) {

        const {
            aslShenasnamehSafhe1, asleKartemeliRo, asleKartemeliPohst, madrakShoghli, madrakSokonati,
            moarefiNameh, chek, karmandPrint3MaheHoghogh, digarPrint6MaheHesab,
            digarPrint6MaheMoadelHesab, other, arr
        } = doc;

        let count = 0;
        if (aslShenasnamehSafhe1 !== "") {
            count++;
        }
        if (asleKartemeliRo !== "") {
            count++;
        }
        if (asleKartemeliPohst !== "") {
            count++;
        }
        if (madrakShoghli !== "") {
            count++;
        }
        if (madrakSokonati !== "") {
            count++;
        }
        if (moarefiNameh !== "") {
            count++;
        }
        if (chek !== "") {
            count++;
        }
        if (karmandPrint3MaheHoghogh) {
            count++;
        }
        if (digarPrint6MaheHesab) {
            count++;
        }
        if (digarPrint6MaheMoadelHesab) {
            count++;
        }
        if (other) {
            count++;
        }
        if (arr) {
            count = count + arr.length;
        }

        return count;
    }
}

export default gpn(Documents);