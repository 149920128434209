import React, {Component} from 'react';
import {gpn} from "../../GPN"
import Field from "./Field";
import moment from "jalali-moment";
import config from "../../config";

class Calculator extends Component {

    state = {
        modal: !!localStorage.getItem("calculator-modal"),

        price: "",
        pish: "",
        karmozd: localStorage.getItem("calculator-modal-karmozd") ? localStorage.getItem("calculator-modal-karmozd") : "3.5",
        chek: "2",
        startDate: "",
        month: "10"

    };

    componentDidMount() {
        this.setState({startDate: moment().locale("fa").format("jYYYY/jMM/jDD")});
    }


    handlePriceChange(name, value) {
        let price = value.toString().replace(/[^0-9]/g, "");
        price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({[name]: price});
    }

    calculate() {
        try {

            let {
                price,
                karmozd,
                chek,
                pish,
                startDate,
                month
            } = this.state;
            price = price.toString().trim() !== "" ? Number(price.toString().replace(/,/g, "")) : 1;
            pish = pish.toString().trim() !== "" ? Number(pish.toString().replace(/,/g, "")) : 1;
            karmozd = karmozd ? karmozd : 3.5;
            chek = Number(chek);
            month = Number(month);

            let ghest = month / chek;

            let percentOfMonths = 0;
            let pricePerGhest = 0;

            let mabaghi = price - pish;

            percentOfMonths = mabaghi * (month * karmozd) / 100;
            percentOfMonths = percentOfMonths + mabaghi;
            pricePerGhest = percentOfMonths / ghest;

            let aghsat = [];

            let start = moment(startDate, "jYYYY/jMM/jDD");

            if (chek === 2) {
                let _45 = moment(startDate, "jYYYY/jMM/jDD").add(45, "jDay");
                aghsat.push(_45.locale("fa").format("jYYYY/jMM/jDD dddd"))


                for (let i = 2; i <= month - 1; i += 2) {
                    let _45_ = moment(startDate, "jYYYY/jMM/jDD").add(45, "jDay");
                    let s = _45_.add(i, "jMonth");
                    aghsat.push(s.locale("fa").format("jYYYY/jMM/jDD dddd"));
                }
            } else {
                for (let i = 1; i <= ghest; i++) {
                    let s = moment(startDate, "jYYYY/jMM/jDD").add(i, "jMonth");
                    aghsat.push(s.locale("fa").format("jYYYY/jMM/jDD dddd"));
                }

            }

            return {
                percentOfMonths: Number(percentOfMonths.toFixed(0)),
                pricePerGhest: Number(pricePerGhest.toFixed(0)),
                aghsat
            };

        } catch (err) {

        }
        return null;
    }

    render() {
        const {
            modal,
            price,
            karmozd,
            chek,
            pish,
            startDate,
            month
        } = this.state;

        let result = this.calculate();

        return (
            <>
                {!modal ?
                    <span className="material calculator-fab"
                          onClick={() => {
                              localStorage.setItem("calculator-modal", "true")
                              this.setState({modal: true})
                          }}>format_list_numbered</span>
                    :
                    <div className="modal-calculator">
                        <div className="calculator-card">
                            <div className="row">
                                <div className="col-auto">
                                    <span className="material close"
                                          onClick={() => {
                                              localStorage.removeItem("calculator-modal")
                                              this.setState({modal: false})
                                          }}>close</span>
                                </div>
                                <div className="col">
                                    <p className="title">محاسبه گر اقساط</p>
                                </div>
                            </div>
                            <br/>

                            <form onSubmit={e => e.preventDefault()} autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field name="price"
                                               value={price}
                                               label="مبلغ"
                                               textAlign="center"
                                               placeholder="10,000,000"
                                               dir="ltr"
                                               oc={(name, value) => {
                                                   let price = Number(value.replace(/,/g, "").toString().trim())
                                                   if (!isNaN(price)) {
                                                       let pish = Number((price / 3).toFixed(0).toString());
                                                       this.handlePriceChange("pish", pish)
                                                   }
                                                   this.handlePriceChange(name, value)
                                               }}/>
                                    </div>
                                    <div className="col-md-6">
                                        <Field name="karmozd"
                                               type="number"
                                               value={karmozd}
                                               label="کارمزد ماهیانه (%)"
                                               textAlign="center"
                                               dir="ltr"
                                               placeholder="%"
                                               oc={(name, value) => {
                                                   localStorage.setItem("calculator-modal-karmozd", value)
                                                   this.setState({[name]: value})
                                               }}/>
                                    </div>
                                    <div className="col-md-6">
                                        <Field name="pish"
                                               value={pish}
                                               label="پیش پرداخت"
                                               textAlign="center"
                                               placeholder="10,000,000"
                                               dir="ltr"
                                               oc={(name, value) => this.handlePriceChange(name, value)}/>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-control">
                                            <label htmlFor="chek">چک ها در قالب:</label>
                                            <select name="chek"
                                                    id="chek"
                                                    onChange={e => {
                                                        this.setState({chek: e.target.value})
                                                    }
                                                    }
                                                    value={chek}>
                                                <option value="1">یک ماهه</option>
                                                <option value="2">دو ماهه</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Field name="startDate"
                                               value={startDate}
                                               label="تاریخ شروع چک ها"
                                               textAlign="center"
                                               placeholder="1402/01/01"
                                               dir="ltr"
                                               oc={(name, value) => {
                                                   this.setState({startDate: value})
                                               }}/>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-control">
                                            <label htmlFor="month">تعداد ماه:</label>
                                            <select name="month"
                                                    id="month"
                                                    onChange={e => {
                                                        this.setState({month: e.target.value})
                                                    }
                                                    }
                                                    value={month}>
                                                <option value="2">2</option>
                                                <option value="4">4</option>
                                                <option value="6">6</option>
                                                <option value="8">8</option>
                                                <option value="10">10</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <br/>
                            {
                                result ?
                                    <div className="calc-result">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="dates">
                                                    {
                                                        result?.aghsat.map((a, i) =>
                                                            <div key={"agh" + i} className="ghest">
                                                                <span className="date">{a.split(" ")[0]}</span>
                                                                <span className="material">check</span>
                                                                <span className="day">{a.split(" ")[1]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="price-per-ghest">
                                                    <span>مبلغ هر قسط:</span>
                                                    <span>
                                                        {config.priceFormatter(result.pricePerGhest)
                                                            .replace("IRT", "")
                                                            .replace(/ /g, "") + " تومان"
                                                        }
                                                    </span>


                                                </p>
                                                <p className="sum">
                                                    <span>قیمت تمام شده:</span>
                                                    <span>
                                                        {config.priceFormatter(Number(pish.toString().trim().replace(/,/g, "")) + result.percentOfMonths)
                                                            .replace("IRT", "")
                                                            .replace(/ /g, "") + " تومان"}
                                                    </span>
                                                </p>
                                            </div>


                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default gpn(Calculator);