import React, {Component} from 'react';
import {gpn} from "../../GPN"

class Modal extends Component {

    state = {};

    render() {
        const {children, title, show, onCloseClick} = this.props;
        return (
            <div className="modal" style={{display: show ? "flex" : "none"}}>
                <div className="card">
                    <div className="row">
                        <div className="col-auto"><span className="material close" onClick={onCloseClick}>close</span>
                        </div>
                        <div className="col" style={{textAlign: "center"}}>{title}</div>
                    </div>
                    <div className="body">{children}</div>
                </div>
            </div>
        );
    }
}

export default gpn(Modal);