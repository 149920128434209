import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import InfiniteScroll from "react-infinite-scroller";

class Users extends Component {

    state = {
        users: [],
        page: 1,
        hasNextPage: false,
        totalPages: null,
        search: ""
    };

    componentDidMount() {
        this.getUsers(1, "");
    }


    getUsers(p, s) {
        const page = p ? p : this.state.page;
        const search = s || s.toString() === "" ? s : this.state.search;

        axios.get(`${config.api}/user/all?token=${config.token}&page=${page}&search=${search}`)
            .then(r => {
                const {users} = r.data;


                this.setState({
                    users: page > 1 ? [...this.state.users, ...users.docs] : users.docs,
                    page: page + 1,
                    hasNextPage: users.hasNextPage,
                    totalPages: users.totalPages
                });


            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        const {page, hasNextPage, users, search} = this.state;

        return (
            <div className="users">

                <div className="search-box">
                    <div className="form-control">
                        <input type="text" name="search" id="search"
                               value={search}
                               onChange={e => {
                                   const {value} = e.target;
                                   this.getUsers(1, value);


                                   this.setState({search: value});
                               }}
                               placeholder="جستجو کنید (نام و نام خانوادگی یا شماره موبایل):"/>
                    </div>
                </div>
                <br/><br/><br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getUsers(page, search)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {users.map((u, i) =>
                        <div key={"u" + i} className="user"
                             onClick={() => this.props.navigate(`/user/${u._id}`)}>
                            <div
                                className="fullName">{u.fullName ? u.fullName !== "" ? u.fullName : "بدون نام و نام خانوادگی" : "بدون نام و نام خانوادگی"}</div>
                            <div className="phoneNumber">{u.phoneNumber}</div>
                        </div>)}
                </InfiniteScroll>
            </div>);
    }
}

export default gpn(Users);