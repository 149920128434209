import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";

class Logs extends Component {
    state = {
        logs: [],

        page: 1,
        hasNextPage: false,
        totalPages: null,
    };


    componentDidMount() {
        this.getLogs(1);
    }

    getLogs(page) {
        axios.get(`${config.api}/log/list?token=${config.token}&page=${page ? page : 1}`)
            .then(r => {
                const {logs} = r.data;
                console.log('logs: ', logs);

                this.setState({
                    logs: page > 1 ? [...this.state.logs, ...logs.docs] : logs.docs,
                    page: page + 1,
                    hasNextPage: logs.hasNextPage,
                    totalPages: logs.totalPages
                });

            })
            .catch(err => {
                console.error(err.stack);
            });
    }

    deleteLogs() {
        toast.info("در حال حذف ...")
        axios.delete(`${config.api}/logs?token=${config.token}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.")
                this.getLogs();
            })
            .catch(err => {
                toast.error("خطا!")
            });
    }


    methodColor(log) {
        let color = "#313131";
        try {
            let method = log.data.method.toString().trim().toLowerCase();

            switch (method) {
                case "post":
                    color = "#0239ac"
                    break;
                case "get":
                    color = "#c57f01"
                    break;
                case "delete":
                    color = "#c50101"
                    break;
                case "put":
                    color = "#087261"
                    break;
            }
        } catch (err) {
        }

        return color;
    }


    getData(obj) {
        let data = "";
        for (const [key, value] of Object.entries(obj)) {
            if (key !== 'token') {
                data = data + `${key} -> ${value}` + "\n";
            }
        }
        return data;
    }

    render() {
        const {page, hasNextPage, logs} = this.state;

        return (
            <div className="logs">

                <div className="row">
                    <div className="col-auto">
                        <div className="form-control">
                            <button className="delete-errors"
                                    onDoubleClick={this.deleteLogs.bind(this)}
                                    style={{backgroundColor: "#992525"}}>حذف همه
                            </button>
                        </div>
                    </div>
                </div>
                <br/>

                <div className="list">
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={() => this.getUsers(page)}
                        hasMore={hasNextPage}
                        loader={<div className="loader" key={0}>دریافت ...</div>}>

                        {logs.map((l, i) =>
                            <div key={"log" + i} className="log-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="method"
                                             style={{color: this.methodColor(l)}}>{l.data.method}</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="type">
                                            {l.agent ? "نماینده" : l.user ? "کاربر" : "مهمان"}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="path">{l.path}</div>
                                        <pre>{l.data.query ? this.getData(l.data.query) : null}<br/>{l.data.body ? this.getData(l.data.body) : null}</pre>
                                    </div>
                                    <div className="col">
                                        <div className="created-at">{
                                            l.created_at
                                        }</div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </InfiniteScroll>
                </div>

            </div>
        );
    }
}

export default gpn(Logs);