import React, {Component} from 'react';
import {gpn} from "../../GPN"
import {Cascader} from "antd";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class ProductBox extends Component {

    state = {

        category: "",
        sort: null,

        categories: [],
        boxes: null

    };

    componentDidMount() {
        this.getBoxes();
    }


    getCategories() {
        axios.get(`${config.apiV1}/product/categories`)
            .then(r => {
                window.scrollTo(0, 0);
                this.setState({
                    categories: r.data
                });
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }


    getBoxes() {
        axios.get(`${config.api}/product/box?token=${config.token}`)
            .then(r => {
                window.scrollTo(0, 0);

                this.setState({
                    boxes: r.data.boxes,
                    sort: r.data.boxes.length + 1
                });
                this.getCategories();
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    handleCategoryChange(selected) {
        let arr = selected.includes(",") ? selected.split(',') : selected;

        const category = arr[arr.length - 1];

        this.setState({
            category
        });
    }


    handleSubmit() {
        const {category, sort} = this.state;

        if (category !== "" && sort) {

            let params = new URLSearchParams();
            params.append('category', category);
            params.append('sort', sort.toString());


            axios.post(`${config.api}/product/box?token=${config.token}`, params)
                .then(r => {
                    toast.success("با موفقیت ایجاد شد");
                    this.getBoxes();
                })
                .catch(err => {
                    toast.error("خطایی رخ داده است!");
                });
        } else {
            toast.error("فیلد ها را بدرستی وارد کنید!");
        }
    }

    deleteBox(id) {
        axios.delete(`${config.api}/product/box?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!")
                this.getBoxes();
            })
            .catch(err => {
                toast.error("خطا!")
            });
    }

    render() {
        const {categories, sort, boxes} = this.state;
        return (
            <div className="product-box">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-control">

                                <label htmlFor="category">دسته بندی:</label>
                                <Cascader options={categories.cascaderCategories}
                                          id="category"
                                          changeOnSelect
                                          bordered={false}
                                          multiple={false}
                                          style={{width: '100%', borderBottom: "1px solid #cdcdcd"}}
                                          onChange={(e) => this.handleCategoryChange(e)}
                                          size={"large"}
                                          placement={"bottomLeft"}
                                          expandIcon={<span>&nbsp;</span>}
                                          placeholder="دسته بندی ..."/>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-control">
                                <label htmlFor="sort">ترتیب</label>
                                <input type="number"
                                       name="sort"
                                       id="sort"
                                       placeholder="ترتیب"
                                       value={sort}
                                       onChange={e => {
                                           const {value} = e.target;
                                           this.setState({
                                               sort: value
                                           })
                                       }}
                                       min={1} max={1000}/>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="create" onClick={this.handleSubmit.bind(this)}>+ ایجاد کردن</span>
                        </div>
                    </div>

                </form>

                <br/><br/>
                <div className="pboxes">
                    {
                        boxes ?
                            boxes.length > 0 ?
                                boxes.map((b, i) =>
                                    <div key={i} className="box">
                                        <div className="row">
                                            <div className="col-auto">
                                                {b.category ? b.category.title : ""}
                                            </div>
                                            <div className="col-auto">&nbsp;({b.sort})</div>
                                            <div className="col" style={{textAlign: "left"}}>
                                                <span className="material"
                                                      onClick={this.deleteBox.bind(this, b._id)}>delete</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <div>موردی نیست.</div>
                            : <div>دریافت ...</div>
                    }
                </div>
            </div>
        );
    }
}

export default gpn(ProductBox);