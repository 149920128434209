import React, {Component} from 'react';
import {gpn} from "../GPN"
import config from "../config";
import SelectProduct from "./sections/SelectProduct";
import AdsImageMaker from "./sections/AdsImageMaker";

class Ads extends Component {

    state = {
        type: "laptop",

        laptop: {
            images: [],
            subject: "",
            price: "",
            sazandeh: "",
            sazandehCPU: "",
            seriCPU: "",
            ram: "",
            memory: "",
            vga: "",
            display: "",
            os: "",
            color: ""
        },

        mobile: {
            images: [],
            subject: "",
            price: "",
            sazandeh: "",
            andazeh: "",
            display: "",
            resolutionAx: "",
            os: "",
            osVersion: "",
            simCart: "",
            cpu: "",
            ram: "",
            battery: "",
            hdd: "",
            memory: "",
            color: ""
        }

    };

    handleInputChange(e, t = "laptop") {
        let {name, value, maxLength} = e.target;
        maxLength = maxLength ? Number(maxLength.toString()) : 300;
        value = value.slice(0, maxLength);

        let {laptop, mobile} = this.state;

        if (t === "laptop") {
            laptop[name] = value;
            this.setState({
                laptop,
            });
        } else if (t === "mobile") {
            mobile[name] = value;
            this.setState({
                mobile
            });
        }

    }

    reset() {
        this.setState({
            laptop: {
                subject: "",
                price: "",
                sazandeh: "",
                sazandehCPU: "",
                seriCPU: "",
                ram: "",
                memory: "",
                vga: "",
                display: "",
                os: "",
                color: ""
            },

            mobile: {
                subject: "",
                price: "",
                sazandeh: "",
                andazeh: "",
                display: "",
                resolutionAx: "",
                os: "",
                osVersion: "",
                simCart: "",
                cpu: "",
                ram: "",
                battery: "",
                hdd: "",
                memory: "",
                color: ""
            }

        });
    }

    render() {
        const {type, laptop, mobile} = this.state;
        if (type === "laptop") {

            const {
                subject, sazandeh, sazandehCPU, seriCPU, ram, memory, vga, display, os, color, price
            } = laptop;
            let fPrice = config.priceFormatter(price);
            fPrice = fPrice.replace("IRT", "").replace(/ /g, "");

            let ex = subject ? `💚 ${subject} 💚\n` : "";
            ex = ex + "\n" + "🟩 نقد و اقساط 🟩" + "\n";
            ex = ex + (sazandeh ? "\n" + "✅ سازنده: " + sazandeh : "");
            ex = ex + (sazandehCPU ? "\n" + "✅ سازنده پردازنده: " + sazandehCPU : "");
            ex = ex + (seriCPU ? "\n" + "✅ سری پردازنده: " + seriCPU : "");
            ex = ex + (ram ? "\n" + "✅ حافظه رم: " + ram : "");
            ex = ex + (memory ? "\n" + "✅ حافظه داخلی: " + memory : "");
            ex = ex + (vga ? "\n" + "✅ گرافیک: " + vga : "");
            ex = ex + (display ? "\n" + "✅ اندازه صفحه نمایش: " + display : "");
            ex = ex + (os ? "\n" + "✅ سیستم عامل: " + os : "");
            ex = ex + (color ? "\n" + "✅ رنگ: " + color : "");
            ex = ex + (price ? "\n" + `\n🔰 ${fPrice} تومان 🔰` : "\n🔰 قیمت: تماس بگیرید 🔰");
            ex = ex + "\n\n🧑‍💻 اطلاعات بیشتر: \n" + "💁‍♂️ ایتا: https://eitaa.com/Takinanoosh1398\n💁‍♂️ روبیکا: https://rubika.ir/Takinanoosh1401\n💁‍♂️ اینستاگرام: https://www.instagram.com/takinanoosh.ir/\n💁‍♂️ تلگرام: https://t.me/Takinanoosh1398\n\n💁‍♂️ تماس: \n09132828121\n09923542529\n02191309562\n";
            ex = ex + "\n\n‍💻 شبکه های اجتماعی: \n" + "✳️ ایتا: https://eitaa.com/Takinanoosh2\n✳️ روبیکا: https://rubika.ir/Takinanoosh2\n✳️ بله: https://ble.ir/join/ZTEzZDQ5Zj\n✳️ تلگرام: https://t.me/takinanosh\n" + "\n✳️ آدرس سایت: https://takinanoosh.ir";

            return (<div className="ads">
                <form onSubmit={e => e.preventDefault()} autoComplete="off">
                    <div className="form-control">
                        <select name="type" id="type" value={type} onChange={e => {
                            this.setState({type: e.target.value})
                        }}>
                            <option value="laptop">لپ تاپ</option>
                            <option value="mobile">گوشی</option>
                        </select>
                    </div>
                    <SelectProduct
                        type="laptop"
                        onSelect={(data) => {
                            this.setState({
                                laptop: {
                                    images: data.images,
                                    subject: data.subject,
                                    sazandeh: data.sazandeh,
                                    sazandehCPU: data.sazandehCPU,
                                    seriCPU: data.seriCPU,
                                    ram: data.ram,
                                    memory: data.memory,
                                    vga: data.vga,
                                    display: data.display,
                                    os: data.os,
                                    color: data.color,
                                    price: data.price
                                }
                            })
                        }}/>
                    <br/>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="subject">عنوان:</label>
                            <div className="form-control">
                                <input type="text"
                                       name="subject"
                                       id="subject"
                                       placeholder="عنوان"
                                       value={subject}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-control">
                                <label htmlFor="sazandeh">سازنده:</label>
                                <input type="text"
                                       name="sazandeh"
                                       id="sazandeh"
                                       placeholder="سازنده"
                                       style={{direction: "ltr"}}
                                       value={sazandeh}
                                       maxLength={150}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="sazandehCPU">سازنده پردازنده:</label>
                                <input type="text"
                                       name="sazandehCPU"
                                       id="sazandehCPU"
                                       placeholder="سازنده پردازنده"
                                       value={sazandehCPU}
                                       style={{direction: "ltr"}}
                                       maxLength={150}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="seriCPU">سری پردازنده:</label>
                                <input type="text"
                                       name="seriCPU"
                                       id="seriCPU"
                                       placeholder="سری پردازنده"
                                       value={seriCPU}
                                       style={{direction: "ltr"}}
                                       maxLength={150}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="ram">رم:</label>
                                <input type="text"
                                       name="ram"
                                       id="ram"
                                       placeholder="رم - گیگابایت"
                                       value={ram}
                                       style={{direction: "ltr"}}
                                       maxLength={100}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="memory">حافظه داخلی:</label>
                                <input type="text"
                                       name="memory"
                                       id="memory"
                                       placeholder="حافظه داخلی"
                                       value={memory}
                                       style={{direction: "ltr"}}
                                       maxLength={100}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="vga">گرافیک:</label>
                                <input type="text"
                                       name="vga"
                                       onWheel={e => e.target.blur()}
                                       id="vga"
                                       placeholder="گرافیک"
                                       value={vga}
                                       style={{direction: "ltr"}}
                                       maxLength={355}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="display">اندازه صفحه نمایش:</label>
                                <input type="text"
                                       name="display"
                                       id="display"
                                       placeholder="اندازه صفحه نمایش"
                                       value={display}
                                       maxLength={100}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="os">سیستم عامل:</label>
                                <input type="text"
                                       name="os"
                                       id="os"
                                       placeholder="سیستم عامل"
                                       value={os}
                                       maxLength={100}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="color">رنگ:</label>
                                <input type="text"
                                       name="color"
                                       id="color"
                                       placeholder="رنگ"
                                       value={color}
                                       maxLength={100}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="price">قیمت:</label>
                                <input type="number"
                                       name="price"
                                       id="price"
                                       placeholder="قیمت"
                                       value={price}
                                       style={{direction: "ltr"}}
                                       maxLength={150}
                                       onWheel={e => e.target.blur()}
                                       onChange={(e) => this.handleInputChange(e, "laptop")}/>
                            </div>
                        </div>
                    </div>

                </form>
                <div className="form-control">
                    <textarea name="ex" id="ex" rows="10" value={ex}></textarea>
                </div>

                <AdsImageMaker type={type} obj={laptop}/>
                <br/><br/><br/><br/><br/><br/>
                <div className="form-control"
                     onDoubleClick={() => this.reset()}>
                    <button type="button">Reset</button>
                </div>
            </div>);

        } else if (type === "mobile") {
            const {
                subject,
                price,
                sazandeh,
                andazeh,
                display,
                resolutionAx,
                os,
                osVersion,
                simCart,
                cpu,
                ram,
                battery,
                hdd,
                memory,
                color
            } = mobile;

            let fPrice = config.priceFormatter(price);
            fPrice = fPrice.replace("IRT", "").replace(/ /g, "");

            let ex = subject ? `💚 ${subject} 💚\n` : "";
            ex = ex + "\n" + "🟩 نقد و اقساط 🟩" + "\n\n";
            ex = ex + (sazandeh ? "\n" + "✅ سازنده: " + sazandeh : "");
            ex = ex + (andazeh ? "\n" + "✅ اندازه: " + andazeh : "");
            ex = ex + (display ? "\n" + "✅ فناوری صفحه‌نمایش: " + display : "");
            ex = ex + (resolutionAx ? "\n" + "✅ رزولوشن عکس: " + resolutionAx : "");
            ex = ex + (cpu ? "\n" + "✅ پردازنده: " + cpu : "");
            ex = ex + (ram ? "\n" + "✅ حافظه رم: " + ram : "");
            ex = ex + (hdd ? "\n" + "✅ حافظه داخلی: " + hdd : "");
            ex = ex + (battery ? "\n" + "✅ باتری: " + battery : "");
            ex = ex + (os ? "\n" + "✅ سیستم عامل: " + os : "");
            ex = ex + (osVersion ? "\n" + "✅ نسخه سیستم عامل: " + osVersion : "");
            ex = ex + (simCart ? "\n" + "✅ سیم کارت: " + simCart : "");
            ex = ex + (memory ? "\n" + "✅ کارت حافظه: " + memory : "");
            ex = ex + (color ? "\n" + "✅ رنگ: " + color : "");
            ex = ex + (price ? "\n" + `\n🔰 ${fPrice} تومان 🔰` : "\n🔰 قیمت: تماس بگیرید 🔰");
            ex = ex + "\n\n🧑‍💻 اطلاعات بیشتر: \n" + "💁‍♂️ ایتا: https://eitaa.com/Takinanoosh1398\n💁‍♂️ روبیکا: https://rubika.ir/Takinanoosh1401\n💁‍♂️ اینستاگرام: https://www.instagram.com/takinanoosh.ir/\n💁‍♂️ تلگرام: https://t.me/Takinanoosh1398\n\n💁‍♂️ تماس: \n09132828121\n09923542529\n02191309562\n";
            ex = ex + "\n\n‍💻 شبکه های اجتماعی: \n" + "✳️ ایتا: https://eitaa.com/Takinanoosh2\n✳️ روبیکا: https://rubika.ir/Takinanoosh2\n✳️ بله: https://ble.ir/join/ZTEzZDQ5Zj\n✳️ تلگرام: https://t.me/takinanosh\n" + "\n✳️ آدرس سایت: https://takinanoosh.ir";

            return (<div className="ads">

                <form onSubmit={e => e.preventDefault()} autoComplete="off">
                    <div className="form-control">
                        <select name="type" id="type" value={type} onChange={e => {
                            this.setState({type: e.target.value})
                        }}>
                            <option value="laptop">لپ تاپ</option>
                            <option value="mobile">گوشی</option>
                        </select>
                    </div>

                    <SelectProduct
                        type="mobile"
                        onSelect={(data) => {
                            this.setState({
                                mobile: {
                                    images: data.images,
                                    subject: data.subject,
                                    price: data.price,
                                    sazandeh: data.sazandeh,
                                    andazeh: data.andazeh,
                                    display: data.display,
                                    resolutionAx: data.resolutionAx,
                                    os: data.os,
                                    osVersion: data.osVersion,
                                    simCart: data.simCart,
                                    cpu: data.cpu,
                                    ram: data.ram,
                                    battery: data.battery,
                                    hdd: data.hdd,
                                    memory: data.memory,
                                    color: data.color
                                }
                            })

                        }}/>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-control">
                                <label htmlFor="subject">عنوان:</label>
                                <input type="text"
                                       name="subject"
                                       id="subject"
                                       placeholder="عنوان"
                                       value={subject}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-control">
                                <label htmlFor="sazandeh">سازنده:</label>
                                <input type="text"
                                       name="sazandeh"
                                       id="sazandeh"
                                       placeholder="سازنده"
                                       style={{direction: "ltr"}}
                                       value={sazandeh}
                                       maxLength={150}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="andazeh">اندازه:</label>
                                <input type="text"
                                       name="andazeh"
                                       id="andazeh"
                                       placeholder="اندازه"
                                       style={{direction: "rtl"}}
                                       value={andazeh}
                                       maxLength={150}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="display">فناوری صفحه نمایش:</label>
                                <input type="text"
                                       name="display"
                                       id="display"
                                       placeholder="فناوری صفحه نمایش"
                                       style={{direction: "rtl"}}
                                       value={display}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="resolutionAx">رزولوشن عکس:</label>
                                <input type="text"
                                       name="resolutionAx"
                                       id="resolutionAx"
                                       placeholder="رزولوشن عکس"
                                       style={{direction: "rtl"}}
                                       value={resolutionAx}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="cpu">پردازنده:</label>
                                <input type="text"
                                       name="cpu"
                                       id="cpu"
                                       placeholder="پردازنده"
                                       style={{direction: "rtl"}}
                                       value={cpu}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="ram">حافظه رم:</label>
                                <input type="text"
                                       name="ram"
                                       id="ram"
                                       onWheel={e => e.target.blur()}
                                       placeholder="حافظه رم"
                                       style={{direction: "rtl"}}
                                       value={ram}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="hdd">حافظه داخلی:</label>
                                <input type="text"
                                       name="hdd"
                                       id="hdd"
                                       onWheel={e => e.target.blur()}
                                       placeholder="حافظه داخلی"
                                       style={{direction: "rtl"}}
                                       value={hdd}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="battery">باتری:</label>
                                <input type="text"
                                       name="battery"
                                       id="battery"
                                       placeholder="باتری"
                                       style={{direction: "rtl"}}
                                       value={battery}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="os">سیستم عامل:</label>
                                <input type="text"
                                       name="os"
                                       id="os"
                                       placeholder="سیستم عامل"
                                       style={{direction: "ltr"}}
                                       value={os}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="osVersion">نسخه سیستم عامل:</label>
                                <input type="text"
                                       name="osVersion"
                                       id="osVersion"
                                       placeholder="نسخه سیستم عامل"
                                       style={{direction: "ltr"}}
                                       value={osVersion}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="simCart">سیم کارت:</label>
                                <input type="text"
                                       name="simCart"
                                       id="simCart"
                                       onWheel={e => e.target.blur()}
                                       placeholder="سیم کارت"
                                       style={{direction: "ltr"}}
                                       value={simCart}
                                       maxLength={300}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="memory">کارت حافظه:</label>
                                <input type="text"
                                       name="memory"
                                       id="memory"
                                       placeholder="کارت حافظه"
                                       style={{direction: "rtl"}}
                                       value={memory}
                                       maxLength={500}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="color">رنگ:</label>
                                <input type="text"
                                       name="color"
                                       id="color"
                                       placeholder="رنگ"
                                       style={{direction: "rtl"}}
                                       value={color}
                                       maxLength={500}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="price">قیمت:</label>
                                <input type="number"
                                       name="price"
                                       id="price"
                                       placeholder="قیمت"
                                       value={price}
                                       style={{direction: "ltr"}}
                                       maxLength={150}
                                       onWheel={e => e.target.blur()}
                                       onChange={(e) => this.handleInputChange(e, "mobile")}/>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="form-control">
                    <textarea name="ex" id="ex" rows="10" value={ex}></textarea>
                </div>
                <AdsImageMaker type={type} obj={mobile}/>
                <br/><br/><br/><br/><br/><br/>
                <div className="form-control"
                     onDoubleClick={() => this.reset()}>
                    <button type="button">Reset</button>
                </div>
            </div>);
        }
    }
}

export default gpn(Ads);