import React, {Component} from 'react';
import {gpn} from "../../GPN"

class DashBox extends Component {

    state = {};

    render() {
        let {mio, title, url, icon, navigate, count} = this.props;
        title = title ? title : "";
        url = url ? url : "/";
        icon = icon ? icon : "close";
        count = count.toString() ? count : "1";

        return (
            <div className={mio ? "col-md-4" : "col-md-3"}>
                <div className="box" onClick={() => navigate(url)}>
                    <div className="icon">
                        <span className="material">{icon}</span>
                    </div>
                    <div className="title"><span>{title}</span><span>{count}</span></div>
                </div>
            </div>
        );
    }
}

export default gpn(DashBox);