import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import FileDownload from "js-file-download";

class Accountings extends Component {

    state = {
        accountings: [],
    };

    componentDidMount() {
        this.list();
    }


    list() {
        axios.get(`${config.api}/accounting?token=${config.token}`)
            .then(r => {
                console.log('r.data.list: ', r.data.list);

                this.setState({
                    accountings: r.data.list
                });
            })
            .catch(err => {

            });
    }

    balance() {
        const {accountings} = this.state;

        let balance = 0;

        accountings.map(a => {
            if (a.type === "بستانکار" && !a.clear) {
                balance = balance + a.price;
            } else if (a.type === "بدهکار" && !a.clear) {
                balance = balance - a.price;
            }
        });

        return balance;
    }

    edit(id) {
        axios.put(`${config.api}/accounting?token=${config.token}&id=${id}`)
            .then(r => {
                toast("با موفقیت ذخیره شد.")
                this.list();
            })
            .catch(err => {
            });
    }

    delete(id) {
        axios.delete(`${config.api}/accounting?token=${config.token}&id=${id}`)
            .then(r => {
                toast("با موفقیت حذف شد.")
                this.list();
            })
            .catch(err => {
            });
    }

    download() {
        toast.info("در حال دانلود ...");
        axios.get(`${config.api}/accounting/pdf?token=${config.token}`, {responseType: 'blob'})
            .then(r => {
                try {
                    FileDownload(r.data, decodeURI(r.headers["x-suggested-filename"]));
                } catch (e) {
                }
            })
            .catch(err => {
                console.error('doc download err: ', err);
            });
    }

    render() {
        const {accountings} = this.state;
        let balance = this.balance();

        return (
            <div className="accountings">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="form-control">
                            <button onClick={() => this.props.navigate("/accounting/new")}>+ جدید</button>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-control">
                            <button
                                style={{backgroundColor: "#b74616"}}
                                onClick={() => this.download()}>دانلود خروجی PDF
                            </button>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
                <div className="balance"
                     style={{backgroundColor: balance === 0 ? "#999999" : balance > 0 ? "#068406" : "#a40d0d"}}>
                    <span>تراز مالی:</span><span>{config.priceFormatter(balance)}</span></div>
                {
                    accountings?.map((a, i) =>
                        <article key={"a" + i} style={{textDecoration: a.clear ? "line-through" : "none"}}>
                            <div className="row">
                                <div className="col">
                                    <p className="subject">{a.subject + ` (${a.type})`}</p>
                                    <p className="description">{a.description}</p>
                                    <p className="clear">
                                <span className="row">
                                    <span className="col-auto"><span>وضعیت:</span>
                                    </span>
                                    <span className="col-auto">
                                        <div className="form-control">
                                            <select name="clear"
                                                    id="clear"
                                                    onChange={() => this.edit(a.id)}
                                                    defaultValue={a.clear.toString()}
                                                    value={a.clear.toString()}>
                                                <option value="false">تسویه نشده</option>
                                                <option value="true">تسویه شده</option>
                                            </select>
                                        </div>
                                    </span>
                                </span>
                                    </p>
                                    <p className="price">
                                        <span>مبلغ:</span><span
                                        style={{color: a.type === "بدهکار" ? "#921010" : "#118711"}}>{a.type === "بستانکار" ? config.priceFormatter(a.price) : "-" + config.priceFormatter(a.price)}</span>
                                    </p>
                                    <p className="created-at">{a.created_at}</p>

                                </div>
                                <div className="col-auto">
                                    <div className="delete" onDoubleClick={() => this.delete(a.id)}>
                                        <span className="material">delete</span>
                                    </div>
                                </div>
                            </div>

                        </article>
                    )
                }
            </div>
        );
    }
}

export default gpn(Accountings);