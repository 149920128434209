import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import noImage from "../../assets/images/no-image.jpg";
import {toast} from "react-toastify";

class EditCategory extends Component {

    state = {
        id: "",
        title: "",
        meta: "",
        image: "",
        keywords: "",
        selectedImage: null,
        description: ""
    };

    componentDidMount() {
        this.getCategory();
    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({
            selectedImage: arrFiles[0]
        });
    }

    getCategory() {
        const {id} = this.props.params;
        axios.get(`${config.api}/category?token=${config.token}&id=${id}`)
            .then(r => {
                const {id, title, meta, image} = r.data.category;

                document.title = title;
                this.setState({
                    id, title, meta, image,
                    keywords: meta.keywords,
                    description: meta.description
                });
            })
            .catch(err => {

            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    delete() {
        const {id} = this.state;
        axios.delete(`${config.api}/category?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                setTimeout(() => {
                    this.props.navigate(-1);
                }, 2500)
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    edit() {
        const {id, title, selectedImage, keywords, description} = this.state;
        let meta = {
            keywords, description
        };
        meta = JSON.stringify(meta);

        let params = new FormData();
        params.append('editId', id);
        params.append('meta', meta);
        params.append('title', title);
        if (selectedImage) {
            params.append('image', selectedImage);
        }

        axios.put(`${config.api}/category?token=${config.token}`, params, {
            header: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(r => {
                toast.success("با موفقیت ویرایش شد.")
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!")
            });
    }

    render() {
        const {id, title, image, keywords, description, selectedImage} = this.state;
        return (
            <div className="edit-category">
                {
                    id ?
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-control">

                                    <label htmlFor="selectedImage">عکس دسته بندی:</label>
                                    <input type="file"
                                           name="selectedImage"
                                           id="selectedImage"
                                           onChange={this.handleFileChange.bind(this)}/>
                                </div>
                                <img
                                    draggable={false}
                                    src={selectedImage ? URL.createObjectURL(selectedImage) : image !== "" ? image : noImage}
                                    alt=""/>
                            </div>
                            <div className="col-md-6">
                                <div className="form-control">
                                    <label htmlFor="title">عنوان دسته بندی:</label>
                                    <input type="text"
                                           name="title"
                                           id="title"
                                           onChange={this.handleInputChange.bind(this)}
                                           placeholder="عنوان"
                                           value={title}/>
                                </div>
                                <div className="form-control">
                                    <label htmlFor="keywords">کلیدواژه ها:</label>
                                    <input type="text" name="keywords" id="keywords"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') {
                                                   let keyword = e.target.value;
                                                   let keywords = this.state.keywords;

                                                   if (keywords === "") {
                                                       keywords = keyword;
                                                   } else {
                                                       keywords = keywords + ", " + keyword;
                                                   }
                                                   this.setState({
                                                       keywords
                                                   });
                                                   e.target.value = ""
                                               }
                                           }}/>

                                    <br/>
                                    <textarea id="keywords"
                                              name="keywords"
                                              className="keywords"
                                              value={keywords}
                                              placeholder="کلیدواژه ها ..."
                                              onChange={this.handleInputChange.bind(this)}/>

                                </div>
                                <div className="form-control">
                                    <label htmlFor="description">توضیحات:</label>
                                    <textarea name="description"
                                              id="description"
                                              placeholder="چیزی بنویسید ..."
                                              value={description}
                                              onChange={this.handleInputChange.bind(this)}
                                              rows="5"/>
                                </div>
                            </div>

                            <div className="col">

                                <button type="button"
                                        onClick={this.edit.bind(this)}
                                        style={{backgroundColor: "#0D47A1", margin: 8}}>ویرایش کردن
                                </button>
                            </div>
                            <div className="col-auto">&nbsp;</div>
                            <div className="col">
                                <button type="button"
                                        onDoubleClick={this.delete.bind(this)}
                                        style={{backgroundColor: "#E53935", margin: 8}}>حذف کردن
                                </button>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default gpn(EditCategory);