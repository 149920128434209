import React, {Component} from 'react';
import {gpn} from "../GPN"
import axios from "axios";
import config from "../config";
import DashBox from "./sections/DashBox";
import Field from "./sections/Field";
import moment from "jalali-moment";
import calculator from "./sections/Calculator";

class Dashboard extends Component {

    state = {
        price: "",
        karmozd: localStorage.getItem("calculator-modal-karmozd") ? localStorage.getItem("calculator-modal-karmozd") : "4",
        month: "36",

        statistics: {
            users: "",
            paymentOrders: "",
            documents: "",
            contacts: "",
            productCategories: "",
            products: "",
            productBoxes: "",
            slides: "",
            boxes: "",
            navItems: "",
            news: "",
            ussds: "",
            posts: "",
            categories: ""
        }
    };


    componentDidMount() {
        this.statistics();
    }

    statistics() {
        axios.get(`${config.api}/statistics?token=${config.token}`)
            .then(r => {
                this.setState({statistics: r.data});
            })
            .catch(err => {

            });
    }


    calculate() {
        try {

            let {
                price,
                karmozd,
                month
            } = this.state;
            price = price.toString().trim() !== "" ? Number(price.toString().replace(/,/g, "")) : 1;
            karmozd = karmozd ? Number(karmozd) : 3.5;
            month = Number(month);

            let finalKarmozd = month * karmozd;

            let sum = price + (price * finalKarmozd / 100);

            return sum / month;

        } catch (err) {
        }
        return 0;
    }

    handlePriceChange(name, value) {
        let price = value.toString().replace(/[^0-9]/g, "");
        price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({[name]: price});
    }

    render() {
        const {navigate} = this.props;
        const {price, karmozd, month} = this.state;
        const {
            users, paymentOrders, documents, contacts,
            productCategories, products, productBoxes,
            slides, boxes, navItems, news, ussds, posts,
            categories
        } = this.state.statistics;


        return (
            <div className="dashboard">
                <section className="ghest-calc">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <Field name="price"
                                       type="text"
                                       label="مبلغ"
                                       dir="ltr"
                                       textAlign="center"
                                       placeholder="10,000,000"
                                       oc={(name, value) => this.handlePriceChange(name, value)}
                                       value={price}/>
                            </div>
                            <div className="col-md-3">
                                <Field name="karmozd"
                                       type="number"
                                       label="کارمزد %"
                                       dir="ltr"
                                       textAlign="center"
                                       placeholder="4"
                                       oc={(name, value) => {
                                           localStorage.setItem("calculator-modal-karmozd", value);
                                           this.setState({[name]: value})
                                       }}
                                       value={karmozd}/>
                            </div>

                            <div className="col-md-3">
                                <div className="form-control">
                                    <label htmlFor="month">ماه:</label>
                                    <select name="month" id="month"
                                            value={month}
                                            defaultValue={month}
                                            onChange={e => {
                                                this.setState({month: e.target.value})
                                            }}>

                                        <option value="2">2</option>
                                        <option value="4">4</option>
                                        <option value="6">6</option>
                                        <option value="8">8</option>
                                        <option value="10">10</option>
                                        <option value="12">12</option>
                                        <option value="18">18</option>
                                        <option value="24">24</option>
                                        <option value="36">36</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                {price !== "" ?
                                    <div className="per-ghest">
                                        <span>مبلغ هر قسط:</span><span>{config.priceFormatter(this.calculate()).replace("IRT", "").toString().trim().replace(/ /g, "") + " تومان"}</span>
                                    </div> : null
                                }

                            </div>
                        </div>
                    </form>
                </section>
                <br/><br/>
                <div className="row justify-content-center">

                    <DashBox title="لیست کاربران"
                             icon="group"
                             url="/users"
                             count={users}
                             {...this.props}/>

                    <DashBox title="مدارک ارسالی"
                             icon="folder_shared"
                             url="/documents"
                             count={documents}
                             {...this.props}/>

                    <DashBox title="لیست سفارشات"
                             icon="border_all"
                             url="/orders"
                             count={paymentOrders}
                             {...this.props}/>


                    <DashBox title="لیست محصولات"
                             icon="shopping_cart"
                             url="/products"
                             count={products}
                             {...this.props}/>

                    <DashBox title="دسته بندی محصولات"
                             icon="menu"
                             url="/product/categories"
                             count={productCategories}
                             {...this.props}/>

                    <DashBox title="لیست پست ها"
                             icon="message"
                             url="/posts"
                             count={posts}
                             {...this.props}/>

                    <DashBox title="دسته بندی پست ها"
                             icon="more_horiz"
                             url="/categories"
                             count={categories}
                             {...this.props}/>

                    <DashBox title="باکس محصولات"
                             icon="inbox"
                             url="/product/boxes"
                             count={productBoxes}
                             {...this.props}/>


                    <DashBox title="اسلایدر"
                             icon="slideshow"
                             url="/slides"
                             count={slides}
                             {...this.props}/>


                    <DashBox title="باکس ها"
                             icon="move_to_inbox"
                             url="/boxes"
                             count={boxes}
                             {...this.props}/>

                    <DashBox title="آیتم هدر"
                             icon="navigation"
                             url="/nav"
                             count={navItems}
                             {...this.props}/>

                    <DashBox title="اخبار"
                             icon="poll"
                             url="/news"
                             count={news}
                             {...this.props}/>


                    <DashBox title="کدهای دستوری"
                             icon="code"
                             url="/ussd"
                             count={ussds}
                             {...this.props}/>


                    <DashBox title="لیست تماس با ما"
                             icon="contacts"
                             url="/contact"
                             count={contacts}
                             {...this.props}/>

                    <DashBox title="درباره ما"
                             icon="group_add"
                             url="/about"
                             count={""}
                             {...this.props}/>
                </div>
            </div>
        );
    }
}

export default gpn(Dashboard);