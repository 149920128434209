import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import SideBar from "./SideBar";
import Icon from "./Icon";

class Header extends Component {

    state = {
        username: "",
        fullName: ""
    };


    constructor() {
        super();

        this.checkRef = React.createRef();
    }

    componentDidMount() {
        this.pathName();
        this.info();
    }

    info() {
        const path = document.location.pathname;
        if (path !== "/login" && path !== "/init") {

            axios.get(`${config.api}/info?token=${config.token}`)
                .then(r => {
                    const {username, fullName} = r.data;
                    this.setState({
                        username, fullName
                    });
                })
                .catch(err => {

                    if (err.response.status === 403) {
                        localStorage.removeItem("token");
                        toast.error("وارد شوید ...");
                        document.location.href = "/login";
                    }
                });
        }
    }

    setTitle(title) {
        document.title = title;
    }

    pathName() {
        const path = document.location.pathname;
        switch (path) {
            case "/":
                this.setTitle("داشبورد");
                return "داشبورد";
            case "/login":
                this.setTitle("ورود به حساب");
                return "ورود به حساب";
            case "/init":
                this.setTitle("ایجاد ادمین");
                return "ایجاد ادمین";
            case "/category/create":
                this.setTitle("ایجاد دسته بندی");
                return "ایجاد دسته بندی";
            case "/categories":
                this.setTitle("دسته بندی ها");
                return "دسته بندی ها";
            case "/post/create":
                this.setTitle("پست جدید");
                return "پست جدید";
            case "/posts":
                this.setTitle("پست ها");
                return "پست ها";
            case "/slide/create":
                this.setTitle("ایجاد اسلاید");
                return "ایجاد اسلاید";
            case "/slides":
                this.setTitle("اسلاید ها");
                return "اسلاید ها";
            case "/boxes":
                this.setTitle("باکس ها");
                return "باکس ها";
            case "/box/create":
                this.setTitle("ایجاد باکس");
                return "ایجاد باکس";
            case "/about":
                this.setTitle("درباره ما");
                return "درباره ما";
            case "/terms":
                this.setTitle("قوانین و مقررات");
                return "قوانین و مقررات";
            case "/contact":
                this.setTitle("تماس با ما");
                return "تماس با ما";
            case "/product/categories":
                this.setTitle("دسته بندی محصولات");
                return "دسته بندی محصولات";
            case "/products":
                this.setTitle("لیست محصولات");
                return "لیست محصولات";
            case "/product/create":
                this.setTitle("ایجاد محصول");
                return "ایجاد محصول";
            case "/product/boxes":
                this.setTitle("باکس محصولات");
                return "باکس محصولات";
            case "/product/category/create":
                this.setTitle("دسته بندی محصول جدید");
                return "دسته بندی محصول جدید";
            case "/users":
                this.setTitle("لیست کاربران");
                return "لیست کاربران";
            case "/orders":
                this.setTitle("لیست سفارشات");
                return "لیست سفارشات";
            case "/news":
                this.setTitle("اخبار");
                return "اخبار";
            case "/ussd":
                this.setTitle("نمونه کد ussd");
                return "نمونه کد ussd";
            case "/documents":
                this.setTitle("مدارک ارسالی");
                return "مدارک ارسالی";
            case "/profile":
                this.setTitle("پروفایل");
                return "پروفایل";
            case "/admins":
                this.setTitle("مدیران");
                return "مدیران";
            case "/admin/new":
                this.setTitle("ایجاد ادمین");
                return "ایجاد ادمین";
            case "/calls":
                this.setTitle("لیست تماس ها");
                return "لیست تماس ها";
            case "/accountings":
                this.setTitle("حسابداری");
                return "حسابداری";
            case "/accounting/new":
                this.setTitle("حساب کتاب جدید");
                return "حساب کتاب جدید";
            case "/customer/new":
                this.setTitle("ثبت مشتری خرید کالا جدید");
                return "ثبت مشتری خرید کالا جدید";
            case "/customers":
                this.setTitle("مشتریان خرید کالا");
                return "مشتریان خرید کالا";
            case "/agents":
                this.setTitle("نمایندگان");
                return "نمایندگان";
            case "/monitors/errors":
                this.setTitle("لیست خطاها");
                return "لیست خطاها";
            case "/monitors/logs":
                this.setTitle("گزارشات");
                return "گزارشات";
            case "/vam/tarhs":
                this.setTitle("طرح های تسهیلات");
                return "طرح های تسهیلات";
            case "/vam/tarh/create":
                this.setTitle("ثبت طرح تسهیلات");
                return "ثبت طرح تسهیلات";

            default:
                return "";
        }
    }

    render() {
        let pathname = this.pathName();
        pathname = pathname !== "" ? pathname : "داشبورد"
        const {ro, mio, tokenIsValid} = this.props;
        return (
            <header className="header">
                <div className="row">
                    <div className="col">
                        <div className="pathname">{pathname}</div>
                    </div>
                </div>
            </header>
        );
    }
}

export default gpn(Header);