import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import {Cascader} from "antd";

class CreateCategory extends Component {

    state = {
        categories: {
            categories: null, cascaderCategories: []
        }, title: '', image: null, parent: "", keywords: "", description: ""
    };

    componentDidMount() {
        this.getCategories();
    }


    getCategories() {
        axios.get(`${config.apiV1}/categories`)
            .then(r => {
                window.scrollTo(0, 0);
                this.setState({
                    categories: r.data
                });
            })
            .catch(err => {
                toast.error("خطایی رخ داده است!");
            });
    }

    create(e) {
        toast.success("در حال ایجاد ...");

        const {title, image, keywords, description, parent} = this.state;
        let meta = {
            keywords, description
        };
        meta = JSON.stringify(meta);

        let params = new FormData();
        params.append('title', title);
        params.append('parent', parent);
        params.append('meta', meta);
        if (image) {
            params.append('image', image);
        }
        axios.post(`${config.api}/category?token=${config.token}`, params, {
            header: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(r => {
                toast.success("با موفقیت ایجاد شد.");
                this.setState({
                    categories: {
                        categories: null, cascaderCategories: []
                    }, title: '', image: null, keywords: "", description: ""
                });
                this.getCategories();
            })
            .catch(err => {
                toast.error("خطا!");
            });

    }

    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({
            image: arrFiles[0]
        });
    }

    handleParentCategoryChange(selected) {
        let arr = selected.includes(",") ? selected.split(',') : selected;
        const parent = arr[arr.length - 1];
        this.setState({
            parent
        });
    }

    render() {
        const {
            title, meta, parent, keywords, description, categories
        } = this.state;
        return (<div className="create-category">
            <form onSubmit={e => e.preventDefault()}>
                <h1>ایجاد دسته بندی جدید</h1>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-control">
                            <label htmlFor="title">عنوان دسته بندی:</label>
                            <input type="text"
                                   name="title"
                                   id="title"
                                   onChange={this.handleInputChange.bind(this)}
                                   value={title}
                                   placeholder="عنوان"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-control">
                            <label htmlFor="image">عکس دسته بندی:</label>
                            <input type="file" name="image" id="image" onChange={this.handleFileChange.bind(this)}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-control">

                            <label htmlFor="parent">دسته بندی مادر:</label>
                            <Cascader options={categories.cascaderCategories}
                                      id="parent"
                                      changeOnSelect
                                      bordered={false}
                                      multiple={false}
                                      style={{width: '100%', borderBottom: "1px solid #cdcdcd"}}
                                      onChange={(e) => this.handleParentCategoryChange(e)}
                                      size={"large"}
                                      placement={"bottomLeft"}
                                      expandIcon={<span>&nbsp;</span>}
                                      placeholder="دسته بندی مادر ..."/>
                        </div>
                    </div>
                    <div className="col-md-6"></div>

                    <div className="col-md-6">
                        <div className="form-control">
                            <label htmlFor="keywords">کلیدواژه ها:</label>
                            <input type="text" name="keywords" id="keywords"
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter') {
                                           let keyword = e.target.value;
                                           let keywords = this.state.keywords;

                                           if (keywords === "") {
                                               keywords = keyword;
                                           } else {
                                               keywords = keywords + ", " + keyword;
                                           }
                                           this.setState({
                                               keywords
                                           });
                                           e.target.value = ""
                                       }
                                   }}/>

                            <br/>
                            <textarea id="keywords"
                                      name="keywords"
                                      className="keywords"
                                      value={keywords}
                                      placeholder="کلیدواژه ها ..."
                                      onChange={this.handleInputChange.bind(this)}/>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-control">
                            <label htmlFor="description">توضیحات:</label>
                            <textarea name="description"
                                      id="description"
                                      placeholder="چیزی بنویسید ..."
                                      value={description}
                                      onChange={this.handleInputChange.bind(this)}
                                      rows="5"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <br/>
                        <br/>
                        <button type="button" onClick={this.create.bind(this)}>ایجاد دسته بندی</button>
                    </div>
                </div>
            </form>
        </div>);
    }
}

export default gpn(CreateCategory);