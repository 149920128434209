import React, {Component} from 'react';
import {gpn} from "../GPN"
import axios from "axios";
import config from "../config";
import {toast} from "react-toastify";

class Ussd extends Component {

    state = {
        search: "",

        title: "",
        code: "",

        codes: []
    };

    componentDidMount() {
        this.getCodes("");
    }


    getCodes(search) {
        axios.get(`${config.apiV1}/ussd/codes?search=${search}`)
            .then(r => {
                this.setState({
                    codes: r.data.codes
                });
            })
            .catch(err => {

            });
    }

    createCode() {
        const {title, code, search} = this.state;
        if (title !== "" && code !== "") {

            let params = new URLSearchParams();
            params.append('title', title);
            params.append('code', code);
            this.setState({
                title: "", code: ""
            });
            axios.post(`${config.api}/ussd/code?token=${config.token}`, params)
                .then(r => {
                    this.getCodes(search);
                    toast.success("با موفقیت ایجاد شد.")
                })
                .catch(err => {
                    toast.error("خطا!");
                    this.setState({
                        title, code
                    });
                });
        }
    }

    deleteCode(id) {
        axios.delete(`${config.api}/ussd/code?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.")
                this.getCodes(this.state.search);
            })
            .catch(err => {
                toast.error("خطا")
            });
    }

    handleInputChange(e) {
        let {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        const {search, title, code, codes} = this.state;
        return (
            <div className="ussd">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.getCodes(search);
                }}>
                </form>

                <div className="create">
                    <div className="row">
                        <div className="col">
                            <div className="form-control">
                                <label htmlFor="title">عنوان کد:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       value={title}
                                       onChange={this.handleInputChange.bind(this)}
                                       placeholder="عنوان"/>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-control">
                                <label htmlFor="code">کد:</label>
                                <input type="text"
                                       name="code"
                                       id="code"
                                       style={{direction: "ltr"}}
                                       value={code}
                                       onChange={this.handleInputChange.bind(this)}
                                       placeholder="کد"/>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span onClick={() => this.createCode()}>+ ایجاد</span>
                        </div>
                    </div>
                </div>

                <br/><br/><br/>

                <div className="search">
                    <div className="row">
                        <div className="col">
                            <div className="form-control">
                                <input type="text" name="search" id="search" placeholder="جستجو کنید ..."
                                       value={search}
                                       onChange={(e) => {
                                           let {value} = e.target;
                                           this.getCodes(value);
                                           this.setState({search: value});
                                       }}/>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="material" onClick={() => {
                                this.getCodes(search);
                            }}>search</span>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="codes">
                    {codes.map((c, i) =>
                        <div key={"c" + i} className="row">
                            <div className="col">
                                <div className="title">{c.title}</div>
                                <div className="code">{c.code}</div>
                            </div>
                            <div className="col-auto">
                                <span className="material"
                                      onDoubleClick={this.deleteCode.bind(this, c.id)}>delete</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default gpn(Ussd);