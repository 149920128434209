import React, {Component} from 'react';
import {gpn} from "../../GPN"
import {Route, Routes} from "react-router-dom";
import Dashboard from "../Dashboard";
import Login from "../Login";
import InitAdmin from "../InitAdmin";
import CreateCategory from "../category/CreateCategory";
import Categories from "../category/Categories";
import EditCategory from "../category/EditCategory";
import Posts from "../post/Posts";
import CreatePost from "../post/CreatePost";
import EditPost from "../post/EditPost";
import CreateSlide from "../slide/CreateSlide";
import Slides from "../slide/Slides";
import Boxes from "../box/Boxes";
import CreateBox from "../box/CreateBox";
import NavItems from "../nav/NavItems";
import About from "../utilities/About";
import Contact from "../utilities/Contact";
import ProductCategories from "../product/ProductCategories";
import CreateProductCategory from "../product/CreateProductCategory";
import EditProductCategory from "../product/EditProductCategory";
import Products from "../product/Products";
import CreateProduct from "../product/CreateProduct";
import EditProduct from "../product/EditProduct";
import ProductBox from "../product/ProductBox";
import Users from "../user/Users";
import User from "../user/User";
import Orders from "../user/Orders";
import Order from "../user/Order";
import ParseNews from "../news/ParseNews";
import News from "../news/News";
import USSD from "../USSD";
import Ads from "../Ads";
import Documents from "../documents/Documents";
import Document from "../documents/Document";
import Profile from "../Profile";
import Admins from "../admins/Admins";
import NewAdmin from "../admins/NewAdmin";
import Calls from "../calls/Calls";
import Accountings from "../accounting/Accountings";
import NewAccounting from "../accounting/NewAccounting";
import Customers from "../customer/Customers";
import NewCustomer from "../customer/NewCustomer";
import EditBox from "../box/EditBox";
import Agent from "../agent/Agent";
import Agents from "../agent/Agents";
import Terms from "../utilities/Terms";
import Errors from "../monitoring/Errors";
import Logs from "../monitoring/Logs";
import AgentProducts from "../agent/AgentProducts";
import VamTarhs from "../vam/VamTarhs";
import CreateVamTarh from "../vam/CreateVamTarh";
import EditVamTarh from "../vam/EditVamTarh";
import CreateHamkar from "../hamkar/CreateHamkar";
import Hamkars from "../hamkar/Hamkars";
import EditHamkar from "../hamkar/EditHamkar";
import AgentCustomers from "../agent/AgentCustomers";
import Sazmans from "../sazman/Sazmans";
import MajidServices from "../majid-services/MajidServices";

class Main extends Component {

    state = {};

    render() {
        const {ro, navigate, mio} = this.props
        return (
            <div className="col-9">
                {
                    document.location.pathname !== "/" ?
                        <div className="row">
                            <div className="col-auto">
                                <div className="back" onClick={() => navigate(-1)}>
                                    <span className="material">arrow_forward</span>
                                    <span>بازگشت</span>
                                </div>
                            </div>
                        </div> : null
                }
                <div className="wrapper-main">
                    <Routes>
                        <Route path="/" element={<Dashboard {...ro}/>}/>
                        <Route path="/login" element={<Login {...ro}/>}/>
                        <Route path="/init" element={<InitAdmin {...ro}/>}/>
                        <Route path="/category/create" element={<CreateCategory {...ro}/>}/>
                        <Route path="/categories" element={<Categories {...ro}/>}/>
                        <Route path="/category/:id" element={<EditCategory {...ro}/>}/>
                        <Route path="/posts" element={<Posts {...ro}/>}/>
                        <Route path="/post/create" element={<CreatePost {...ro}/>}/>
                        <Route path="/post/edit/:id" element={<EditPost {...ro}/>}/>
                        <Route path="/slide/create" element={<CreateSlide {...ro}/>}/>
                        <Route path="/slides" element={<Slides {...ro}/>}/>
                        <Route path="/boxes" element={<Boxes {...ro}/>}/>
                        <Route path="/box/create" element={<CreateBox {...ro}/>}/>
                        <Route path="/box/:id" element={<EditBox {...ro}/>}/>
                        <Route path="/nav" element={<NavItems {...ro}/>}/>
                        <Route path="/about" element={<About {...ro}/>}/>
                        <Route path="/terms" element={<Terms {...ro}/>}/>
                        <Route path="/contact" element={<Contact {...ro}/>}/>
                        <Route path="/product/categories" element={<ProductCategories {...ro}/>}/>
                        <Route path="/product/category/create" element={<CreateProductCategory {...ro}/>}/>
                        <Route path="/product/category/:id" element={<EditProductCategory {...ro}/>}/>
                        <Route path="/products" element={<Products {...ro}/>}/>
                        <Route path="/product/create" element={<CreateProduct {...ro}/>}/>
                        <Route path="/product/edit/:id" element={<EditProduct {...ro}/>}/>
                        <Route path="/product/boxes" element={<ProductBox {...ro}/>}/>
                        <Route path="/users" element={<Users {...ro}/>}/>
                        <Route path="/user/:id" element={<User {...ro}/>}/>
                        <Route path="/orders" element={<Orders {...ro}/>}/>
                        <Route path="/order/:id" element={<Order {...ro}/>}/>
                        <Route path="/news/parse" element={<ParseNews {...ro}/>}/>
                        <Route path="/news" element={<News {...ro}/>}/>
                        <Route path="/ussd" element={<USSD {...ro}/>}/>
                        <Route path="/ads" element={<Ads {...ro}/>}/>
                        <Route path="/documents" element={<Documents {...ro}/>}/>
                        <Route path="/document/:id" element={<Document {...ro}/>}/>
                        <Route path="/profile" element={<Profile {...ro}/>}/>
                        <Route path="/admins" element={<Admins {...ro}/>}/>
                        <Route path="/admin/new" element={<NewAdmin {...ro}/>}/>
                        <Route path="/calls" element={<Calls {...ro}/>}/>

                        <Route path="/accountings" element={<Accountings {...ro}/>}/>
                        <Route path="/accounting/new" element={<NewAccounting {...ro}/>}/>

                        <Route path="/customers" element={<Customers {...ro}/>}/>
                        <Route path="/customer/new" element={<NewCustomer {...ro}/>}/>

                        <Route path="/agents" element={<Agents {...ro}/>}/>
                        <Route path="/agent/:id" element={<Agent {...ro}/>}/>
                        <Route path="/agent/products" element={<AgentProducts {...ro}/>}/>
                        <Route path="/agent/customers" element={<AgentCustomers {...ro}/>}/>

                        <Route path="/monitoring/errors" element={<Errors {...ro}/>}/>
                        <Route path="/monitoring/logs" element={<Logs {...ro}/>}/>

                        <Route path="/vam/tarhs" element={<VamTarhs {...ro}/>}/>
                        <Route path="/vam/tarh/create" element={<CreateVamTarh {...ro}/>}/>
                        <Route path="/vam/tarh/:id" element={<EditVamTarh {...ro}/>}/>

                        <Route path="/hamkar/create" element={<CreateHamkar {...ro}/>}/>
                        <Route path="/hamkar/:id" element={<EditHamkar {...ro}/>}/>
                        <Route path="/hamkars" element={<Hamkars {...ro}/>}/>

                        <Route path="/sazmans" element={<Sazmans {...ro}/>}/>

                        <Route path="/majid/services" element={<MajidServices {...ro}/>}/>
                    </Routes>
                </div>
                <br/>
                <br/>
            </div>
        );
    }
}

export default gpn(Main);