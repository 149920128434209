import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import PostEditor from "../sections/PostEditor";
import Fab from "../sections/Fab";
import Modal from "../sections/Modal";
import {EditorState} from "draft-js";
import ContentState from "draft-js/lib/ContentState";

class EditProduct extends Component {


    state = {
        id: "",
        title: "",
        content: "",
        images: [],
        cover: "",
        selectedImages: [],
        editorState: "",
        info: [],
        meta: {
            description: "",
            keywords: ""
        },
        price: "0",
        inStock: "true",

        infoKey: "",
        infoValue: "",

        uploadModal: false,
        img: null,
        uploadUrl: "",


        keyword: ""
    };


    constructor(props) {
        super(props);
        this.infoKey = React.createRef();
        this.infoValue = React.createRef();
    }

    componentDidMount() {
        this.getProduct(this.props.params.id);
    }

    getProduct(id) {
        axios.get(`${config.api}/product?token=${config.token}&id=${id}`)
            .then(r => {
                const {title, content, images, cover, info, meta, price, inStock} = r.data;
                this.setState({
                    id,
                    title,
                    content,
                    images,
                    cover: cover ? cover.replace(config.apiV1, "uploads") : "",
                    info,
                    meta,
                    price,
                    inStock
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    handleInputChange(e) {
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({
            selectedImages: arrFiles
        });

    }

    uploadImage(img) {
        if (img) {
            toast.success("در حال آپلود ...")
            let params = new FormData();
            params.append('img', img);

            axios.post(`${config.api}/upload/image?token=${config.token}`, params, {
                header: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(r => {
                    this.setState({uploadUrl: r.data.url});
                    toast.success("با موفقیت آپلود شد.")
                })
                .catch(err => {
                    toast.error("خطا!")
                });
        }
    }

    handleInfoSubmit(e) {
        const {infoKey, infoValue} = this.state;
        let {info} = this.state;
        info.push({
            key: infoKey,
            value: infoValue
        });
        this.setState({info, infoKey: "", infoValue: ""});
    }

    handleSendProduct() {
        toast.success("در حال ارسال ...");
        let {id, title, content, selectedImages, info, meta, price, inStock} = this.state;


        let params = new FormData();
        params.append('id', id);
        params.append('title', title);
        params.append('content', content);
        params.append('info', JSON.stringify(info));
        params.append('meta', JSON.stringify(meta));
        params.append('price', price);
        params.append('inStock', inStock);

        selectedImages.map(img => {
            params.append('images', img);
        });

        axios.put(`${config.api}/product?token=${config.token}`, params, {
            header: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(r => {
                toast.success("با موفقیت ایجاد شد.");
                setTimeout(() => {
                    this.props.navigate(-1);
                })
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    handleInfoChange(e, index) {
        let {name, value} = e.target;
        name = name === "IK" ? "key" : "value";
        let {info} = this.state;
        info[index][name] = value;
        this.setState({info});
    }

    setCover(cover) {
        const {id} = this.state;
        let params = new URLSearchParams();
        params.append('id', id);
        params.append('cover', cover);

        axios.put(`${config.api}/product/cover?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });
    }

    render() {
        const {
            title,
            content,
            images,
            cover,
            selectedImages,
            uploadUrl,
            info,
            infoKey,
            infoValue,
            meta,
            keyword,
            price,
            inStock
        } = this.state;
        return (
            <div className="create-product">
                <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="title">عنوان:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       onChange={this.handleInputChange.bind(this)}
                                       value={title}
                                       placeholder="عنوان"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="images">تصاویر:

                                    <div className="images">
                                        {
                                            images.length > 0 ?
                                                images.map((img, i) =>
                                                    <img src={img} key={"img" + i} alt=""/>
                                                )
                                                :
                                                selectedImages.map((img, i) =>
                                                    <img src={URL.createObjectURL(img)} key={"img" + i} alt=""/>
                                                )
                                        }
                                    </div>
                                </label>
                                <input type="file"
                                       name="images"
                                       id="images"
                                       multiple
                                       onChange={this.handleFileChange.bind(this)}
                                       accept="image/*"
                                       style={{
                                           display: images.length > 0 ? "none" : "inline-block"
                                       }}/>
                            </div>
                            <div className="form-control">
                                <label htmlFor="cover">عکس کاور:</label>
                                <select name="cover"
                                        value={cover}
                                        onChange={(e) => {
                                            this.setState({cover: e.target.value});

                                            this.setCover(e.target.value);
                                        }}
                                        id="cover">
                                    {
                                        images?.map((img, i) =>
                                            <option value={img.replace(config.apiV1, "uploads")}
                                                    key={"option_img_" + i}>{i + 1}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="price">قیمت:</label>
                                <input type="number" name="price" id="price"
                                       value={price}
                                       onWheel={e => e.target.blur()}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="inStock">در انبار:</label>
                                <select name="inStock" id="inStock"
                                        onChange={(e) => {
                                            this.setState({
                                                inStock: e.target.value
                                            });
                                        }}>
                                    <option value="true" selected={inStock === "true"}>موجود</option>
                                    <option value="false" selected={inStock === "false"}>ناموجود</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            <br/>
                            <div>مشخصات:</div>
                            <div className="add-info">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-control">
                                            <label htmlFor="infoKey">مشخصه:</label>
                                            <input type="text"
                                                   name="infoKey"
                                                   id="infoKey"
                                                   ref={this.infoKey}
                                                   value={infoKey}
                                                   onChange={this.handleInputChange.bind(this)}
                                                   onKeyDown={e => {
                                                       if (e.code === "Enter") {
                                                           this.infoValue.current.focus();
                                                       }
                                                   }}
                                                   onKeyUp={e => {
                                                       if (e.ctrlKey && e.code === 'KeyV') {
                                                           this.infoValue.current.focus();
                                                       }
                                                   }}
                                                   placeholder="کلید"/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-control">
                                            <label htmlFor="infoValue">مقدار:</label>
                                            <input type="text"
                                                   name="infoValue"
                                                   id="infoValue"
                                                   ref={this.infoValue}
                                                   value={infoValue}
                                                   onChange={this.handleInputChange.bind(this)}
                                                   onKeyDown={e => {
                                                       if (e.code === "Enter") {
                                                           this.handleInfoSubmit();
                                                           this.infoKey.current.focus();
                                                       }
                                                   }}
                                                   onKeyUp={e => {
                                                       if (e.ctrlKey && e.code === 'KeyV') {
                                                           this.handleInfoSubmit();
                                                           this.infoKey.current.focus();
                                                       }
                                                   }}
                                                   placeholder="مقدار"/>
                                        </div>
                                    </div>
                                    <div className="col-auto"><span onClick={this.handleInfoSubmit.bind(this)}>+</span>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {
                                info.map((info, i) =>
                                    <div key={"info" + i} className="info">
                                        <div className="row">
                                            <div className="col key">
                                                <input type="text"
                                                       name="IK"
                                                       id="IK"
                                                       onChange={e => this.handleInfoChange(e, i)}
                                                       value={info.key}/>
                                            </div>
                                            <div className="col value">
                                                <input type="text"
                                                       name="IV"
                                                       onChange={e => this.handleInfoChange(e, i)}
                                                       id="IV" value={info.value}/>
                                            </div>
                                            <div className="col-auto">
                                                <span onClick={() => {
                                                    let mInfo = this.state.info;
                                                    mInfo.splice(i, 1);
                                                    this.setState({
                                                        info: mInfo
                                                    });
                                                    this.infoKey.current.focus();
                                                }
                                                }>-</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <br/><br/>
                        </div>
                        <div className="col-12">
                            <PostEditor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                onChange={(e) => {
                                    this.setState({content: e})
                                }}
                                content={content}
                                editorState={EditorState.createWithContent(ContentState.createFromText(content))}
                                toolbarClassName="toolbar-class"
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="description">توضیحات متا:</label>
                                <textarea name="description"
                                          id="description"
                                          rows="5"
                                          value={meta.description}
                                          onChange={
                                              (e) => {
                                                  let m = meta;
                                                  m.description = e.target.value;
                                                  this.setState({meta: m})
                                              }
                                          }
                                          placeholder="توضیحات ....">

                                    </textarea>
                            </div>


                        </div>

                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="keyword">کلیدواژه ها:</label>
                                <input type="text"
                                       name="keyword"
                                       id="keyword"
                                       value={keyword}
                                       onChange={this.handleInputChange.bind(this)}
                                       onKeyDown={e => {
                                           if (e.code === "Enter") {

                                               let keywordsStr = meta.keywords;
                                               keywordsStr = keywordsStr + keyword + ",";


                                               let m = meta;
                                               m.keywords = keywordsStr;

                                               this.setState({
                                                   meta: m,
                                                   keyword: ""
                                               });

                                           }
                                       }}
                                       placeholder="کلیدواژه"/>

                                <textarea value={meta.keywords}
                                          name="keywords"
                                          id="keywords"
                                          placeholder="کلیدواژه ها ....">

                                    </textarea>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <br/><br/><br/>
                            <button type="button" onClick={this.handleSendProduct.bind(this)}>ارسال
                                محصول
                            </button>
                        </div>
                    </div>
                </form>
                <Fab icon="cloud_upload" size={14} onClick={() => {
                    this.setState({
                        uploadModal: true
                    })
                }}/>
                <Modal show={this.state.uploadModal}
                       title="آپلود عکس"
                       onCloseClick={() => {
                           this.setState({
                               uploadModal: false
                           })
                       }}>
                    <div className="form-control">
                        <label htmlFor="img">عکس:</label>
                        <input type="file"
                               name="img"
                               id="img"
                               onChange={(e) => {
                                   const {files} = e.target;
                                   const arrFiles = Array.from(files);

                                   this.setState({
                                       img: arrFiles[0]
                                   });
                                   this.uploadImage(arrFiles[0])
                               }}/>
                        {
                            uploadUrl !== "" ?
                                <input type="text" name="uploadUrl" id="uploadUrl" value={uploadUrl}/>
                                : null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}


export default gpn(EditProduct);