import React, {Component} from 'react';
import {gpn} from "../../GPN"
import {toast} from "react-toastify";
import axios from "axios";
import config from "../../config";
import Field from "../sections/Field";

class CreateSlide extends Component {

    state = {
        title: "",
        content: "",
        link: "",
        image: null
    };

    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleFileChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);
        this.setState({
            image: arrFiles[0]
        });

    }

    handleSend() {
        const {title, link, content, image} = this.state;
        if (image) {
            if (title !== "" && link !== "") {
                let params = new FormData();
                params.append('title', title);
                params.append('content', content);
                params.append('link', link);
                params.append('image', image);

                axios.post(`${config.api}/slide?token=${config.token}`, params, {
                    header: {
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then(r => {
                        toast.success("با موفقیت ارسال شد.");
                        setTimeout(() => {
                            this.props.navigate(-1);
                        }, 2500)
                    })
                    .catch(err => {
                        toast.error("خطایی رخ داده است!");
                        console.error(err);
                    });

            } else {
                toast.error("عنوان و لینک را وارد کنید!");
            }
        } else {
            toast.error("عکس را انتخاب کنید!");
        }
    }

    render() {
        const {title, content, link} = this.state;

        return (
            <div className="create-slide">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="title">عنوان:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       value={title}
                                       onChange={this.handleInputChange.bind(this)}
                                       placeholder="عنوان اسلاید"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-control">
                                <label htmlFor="link">لینک اسلاید:</label>
                                <input type="text"
                                       name="link"
                                       id="link"
                                       onChange={this.handleInputChange.bind(this)}
                                       value={link}
                                       placeholder="لینک"
                                       style={{direction: "ltr", textAlign: "left"}}/>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-control">
                                <label htmlFor="image">عکس:</label>
                                <input type="file" name="image" id="image" onChange={this.handleFileChange.bind(this)}/>
                            </div>
                        </div>

                        <div className="col-12">
                            <Field
                                type="textarea"
                                name="content"
                                value={content}
                                oc={(name, value) => {
                                    this.setState({content: value})
                                }}
                                label="توضیحات"
                                placeholder="توضیحات ..."
                            />
                        </div>
                        <div className="col-md-6">
                            <br/><br/>
                            <button type="button" onClick={this.handleSend.bind(this)}>ارسال</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default gpn(CreateSlide);