import React, {Component} from 'react';
import {gpn} from "../../GPN"
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import config from "../../config";

class Calls extends Component {

    state = {
        services: [],
        calls: [],

        page: 1,
        next: null,
        last: null
    };

    componentDidMount() {
        this.getServices();
        this.getCalls(1);
    }

    getServices() {
        axios.get(`${config.api}/call/services?token=${config.token}`)
            .then(r => {
                this.setState({
                    services: r.data
                });
            })
            .catch(err => {
                console.error(err.stack);
            });
    }

    getCalls(page) {
        axios.get(`${config.api}/call/list?token=${config.token}&page=${page}`)
            .then(r => {
                const {calls, paginator} = r.data;

                this.setState({
                    calls: page > 1 ? [...this.state.calls, ...calls] : calls,
                    page: page + 1,
                    next: paginator.next,
                    last: paginator.last
                });

            })
            .catch(err => {
                console.error(err.stack);
            });
    }

    render() {
        const {page, next, last, calls, services} = this.state;

        return (
            <div className="calls">

                <div className="card">
                    <div className="services">
                        {
                            services.length > 0 ?
                                services.map((s, i) =>
                                    <div className="service" key={"s" + i}>
                                        <p className="service-id">{s.service_id}</p>
                                        <div className="extens">
                                            <h2>اپراتور ها</h2>
                                            {
                                                s.extens?.map((se, sei) =>
                                                    <div className="exten" key={"sei" + sei}>
                                                        <div className="row justify-content-between">
                                                            <p className="exten-name">{se.name}</p>
                                                            <p className="exten-number">{se.number}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="trunks">
                                            {
                                                s.trunks?.map((st, sti) =>
                                                    <div className="trunk" key={"sti" + sti}>
                                                        <h2>خط ها</h2>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="trunk-number">{st.number}</div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <p className="trunk-credit">
                                                                    <span className="value">{st.credit.value}</span>
                                                                    <span
                                                                        className="expired-at">{st.credit.expired_at}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                                : <div>دریافت سرویس ها ...</div>
                        }
                    </div>
                    <br/><br/>
                    <InfiniteScroll
                        pageStart={2}
                        loadMore={() => this.getCalls(page)}
                        hasMore={next !== null ? page < next : true}
                        loader={<div className="loader" key={0}>دریافت ...</div>}>
                        {calls.map((c, i) =>
                            <div key={"c" + i} className="call"
                                 style={{backgroundColor: this.statusBackColor(c.status)}}>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className="number">{(i + 1)}</p>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="source">
                                                    <span>مبدأ:</span><span>{"0" + c.call_source.number}</span>
                                                </p>
                                            </div>
                                            <div className="col-auto">
                                                <p className="dest">
                                                    <span>مقصد:</span><span>{"0" + c.call_dest.number}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                {this.renderStatus(c.status)}
                                            </div>
                                            <div className="col-auto">
                                                <p className="price">
                                                    <span>هزینه تماس:</span>
                                                    <span>{c.price}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <p className="time-wait">
                                                <span>انتظار:</span><span>{c.time_wait + " ثانیه"}</span>
                                            </p>
                                            <p className="time-talk">
                                                <span>مکالمه:</span><span>{c.time_talk + " ثانیه"}</span></p>
                                        </div>
                                        <div className="row">
                                            <p className="created-at"><span>شروع:</span><span>{c.created_at}</span></p>
                                            <p className="ended-at"><span>پایان:</span><span>{c.ended_at}</span></p>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {this.renderType(c.type)}
                                    </div>
                                </div>
                            </div>)}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }

    renderType(type) {
        return (
            <span className="material type">{type === "incoming" ? "arrow_downward" : "arrow_upward"}</span>
        )
    }

    statusBackColor(status) {
        return status === "ANSWERED" ? "rgba(0,255,0,0.04)" : status === "BUSY" ? "rgba(255,0,0,0.04)" : status === "NO ANSWER" ? "rgba(255,89,0,0.04)" : "rgba(49,49,49,0.04)"
    }

    renderStatus(status) {
        const color = status === "ANSWERED" ? "#148914" : status === "BUSY" ? "#ab0e0e" : status === "NO ANSWER" ? "#c55519" : "#545454"
        return (
            <p className="status">
                <span>وضعیت:</span>
                <span style={{color}}>
            {status === "ANSWERED" ? "پاسخ داده شده"
                : status === "BUSY" ? "اشغال"
                    : status === "NO ANSWER" ? "بی پاسخ"
                        : "شلوغی خط"
            }
            </span>
            </p>

        )
    }
}

export default gpn(Calls);