import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import noImage from "../../assets/images/no-image.jpg";
import SingleField from "../sections/AgentSingleField";
import {toast} from "react-toastify";
import ModalDelete from "../sections/ModalDelete";

class Agent extends Component {

    state = {
        id: this.props.params.id,
        phoneNumber: "",
        category: "",
        information: {
            fullName: {value: "", status: "novalue", rejectText: ""},
            fathersName: {value: "", status: "novalue", rejectText: ""},
            nationalCode: {value: "", status: "novalue", rejectText: ""},
            birthDate: {value: "", status: "novalue", rejectText: ""},
            address: {value: "", status: "novalue", rejectText: ""},
            postalCode: {value: "", status: "novalue", rejectText: ""},
            cardNumber: {value: "", status: "novalue", rejectText: ""},
            shaba: {value: "IR", status: "novalue", rejectText: ""},
            makan: {value: "انتخاب نشده", status: "novalue", rejectText: ""}
        },
        documents: [],
        profilePhoto: null,
        storeImage: null,
        storeName: "",
        storeUsername: "",
        logins: [],
        created_at: "",
        editMode: false,
        modalDelete: false
    };

    componentDidMount() {
        this.info();
    }


    info() {
        axios.get(`${config.api}/agent?token=${config.token}&id=${this.props.params.id}`)
            .then(r => {
                console.log('r.data: ', r.data);

                this.setState(r.data);

            })
            .catch(err => {

            });
    }

    handleInputChange(name, value) {

        let {information} = this.state;
        information[name].value = value;

        this.setState({information});

        let params = new URLSearchParams();
        params.append('id', this.props.params.id);
        params.append('name', name);
        params.append('value', value);

        axios.put(`${config.api}/agent/info?token=${config.token}`, params)
            .then(r => {
            })
            .catch(err => {
            });
    }

    opt(name, opt, rejectText = "") {
        const {id} = this.props.params;

        let params = new URLSearchParams();
        params.append('id', id);
        params.append('name', name);
        params.append('status', opt);
        params.append('rejectText', rejectText);

        axios.put(`${config.api}/agent?token=${config.token}`, params)
            .then(r => {
                toast.success("با موفقیت ذخیره شد.")
                this.info();
            })
            .catch(err => {
                toast.error("خطا!");
            });
    }

    deleteAgent() {
        const id = this.props.params.id;
        toast.info("در حال حذف ...")
        axios.delete(`${config.api}/agent?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد!");
                this.props.navigate(-1);
            })
            .catch(err => {
                toast.error("خطا");
            });
    }

    render() {
        const {
            phoneNumber,
            category,
            information,
            documents,
            profilePhoto,
            logins,
            created_at,
            editMode,
            storeName,
            storeUsername,
            storeImage,
            modalDelete
        } = this.state;

        const {
            fullName,
            fathersName,
            nationalCode,
            birthDate,
            address,
            postalCode,
            cardNumber,
            shaba,
            makan
        } = information;
        return (
            <div className="agent-info">

                {modalDelete ?
                    <ModalDelete
                        title="حذف نماینده"
                        description={`از حذف نماینده ${fullName?.value} اطمینان دارید؟`}
                        onNo={() => {
                            this.setState({modalDelete: false})
                        }
                        }
                        onYes={() => {
                            this.setState({modalDelete: false})
                            this.deleteAgent()
                        }}/>
                    : null}
                <div className="row">
                    <div className="col-md-auto">
                        <div className="profile-photo">
                            <img src={storeImage ? storeImage : profilePhoto ? profilePhoto : noImage} alt=""/>
                        </div>
                    </div>
                    <div className="col-md">
                        <p className="full-name">
                            <span>نام نماینده:</span><span>{fullName.value ? fullName.value : ""}</span></p>
                        <p className="store-name"><span>نام فروشگاه:</span><span>{storeName ? storeName : ""}</span></p>
                        <p className="store-username">
                            <span>نام کاربری فروشگاه:</span><span>{storeUsername ? storeUsername : ""}</span></p>
                        <p className="phone-number"><span>شماره موبایل:</span><span>{phoneNumber}</span></p>
                        <p className="category"><span>رسته شغلی:</span><span>{category}</span></p>
                        <p className="created-at"><span>تاریخ ثبت نام:</span><span
                            style={{direction: "ltr"}}>{created_at}</span></p>
                    </div>

                </div>
                <br/>
                <form onSubmit={(e) => {
                    e.preventDefault()
                }} autoComplete="off">
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-control">
                                <button type="button"
                                        onClick={() => {
                                            this.setState({editMode: !editMode})
                                        }}
                                        style={{backgroundColor: editMode ? "#1976D2" : "#858585"}}><span>ویرایش</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <SingleField name="fullName"
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="نام و نام خانوادگی"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}
                                     info={fullName}/>

                        <SingleField name="fathersName"
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="نام پدر"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}
                                     info={fathersName}/>

                        <SingleField name="nationalCode"
                                     info={nationalCode}
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="کد ملی"
                                     dir="ltr"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>

                        <SingleField name="birthDate"
                                     info={birthDate}
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="تاریخ تولد"
                                     dir="ltr"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>

                        <SingleField name="address"
                                     info={address}
                                     col="col-md-12"
                                     label="آدرس"
                                     disabled={!editMode}
                                     type="textarea"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>


                        <SingleField name="makan"
                                     info={makan}
                                     col="col-md-6"
                                     label="مالکیت"
                                     disabled={!editMode}
                                     dir="rtl"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>

                        <SingleField name="postalCode"
                                     info={postalCode}
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="کد پستی"
                                     dir="ltr"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>


                        <SingleField name="cardNumber"
                                     info={cardNumber}
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="شماره کارت"
                                     dir="ltr"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>


                        <SingleField name="shaba"
                                     info={shaba}
                                     col="col-md-6"
                                     disabled={!editMode}
                                     label="شبا"
                                     textAlign="left"
                                     dir="ltr"
                                     oc={this.handleInputChange.bind(this)}
                                     onAccept={(name) => this.opt(name, "accepted", "")}
                                     onPending={(name) => this.opt(name, "pending", "")}
                                     onReject={(name, rejectText) => this.opt(name, "rejected", rejectText)}/>
                    </div>
                </form>


                <br/><br/>

                <div className="row justify-content-end">
                    <div className="col-md-6">
                        <div className="form-control">
                            <button type="button"
                                    style={{backgroundColor: "#c13130"}}
                                    onClick={() => this.setState({modalDelete: true})}>حذف کردن
                            </button>
                        </div>
                    </div>
                </div>

                <br/><br/><br/><br/>
            </div>
        );
    }
}

export default gpn(Agent);