import React, {Component} from 'react';
import {gpn} from "../../GPN"
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import noImage from "../../assets/images/no-image.jpg";

class EditBox extends Component {


    state = {
        id: this.props.params.id,
        title: "",
        image: null,
        strImage: "",
        col: "",
        height: "",
        type: "main",
        url: "/"
    };

    componentDidMount() {
        this.getBox();
    }

    getBox() {
        axios.get(`${config.api}/box?token=${config.token}&id=${this.state.id}`)
            .then(r => {
                const {title, image, col, height, type, url} = r.data.box;
                this.setState({
                    title,
                    strImage: image,
                    col,
                    height,
                    type,
                    url
                });

            })
            .catch(err => {

            });
    }


    handleInputChange(e) {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    }

    handleImageChange(e) {
        const {files} = e.target;
        const arrFiles = Array.from(files);

        this.setState({image: arrFiles[0]});

    }

    handleSubmit() {
        const {id, title, image, strImage, col, height, type, url} = this.state;
        let params = new FormData();
        params.append('id', id);
        params.append('title', title);
        params.append('col', col);
        params.append('height', height);
        params.append('type', type);
        params.append('url', url);

        if (image) {
            params.append('image', image);
        }

        axios.put(`${config.api}/box?token=${config.token}`, params, {
            header: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(r => {
                toast.success("با موفقیت ذخیره شد.");
                this.getBox();
                this.getCategories();
            })
            .catch(err => {
                console.error(err);
            });


    }

    render() {
        const {title, image, strImage, col, height, type, url} = this.state;

        return (
            <div className="create-box">

                <form onSubmit={e => e.preventDefault()}>
                    <div className="row">

                        <div className="col-8">
                            <div className="form-control">
                                <label htmlFor="title">عنوان:</label>
                                <input type="text"
                                       name="title"
                                       id="title"
                                       placeholder="عنوان"
                                       value={title}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-control">
                                <label htmlFor="image">
                                    <div>عکس:</div>
                                    <div><img
                                        alt=""
                                        src={image ? URL.createObjectURL(image) : strImage.length > 0 ? strImage : noImage}/>
                                    </div>
                                </label>
                                <input type="file"
                                       name="image"
                                       style={{display: "none"}}
                                       onChange={this.handleImageChange.bind(this)}
                                       accept="image/*"
                                       id="image"/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="col">ستون:</label>
                                <input type="text"
                                       name="col"
                                       id="col"
                                       style={{direction: "ltr", textAlign: "left"}}
                                       placeholder="col-auto"
                                       value={col}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="height">ارتفاع:</label>
                                <input type="text"
                                       name="height"
                                       id="height"
                                       style={{direction: "ltr", textAlign: "left"}}
                                       placeholder="50px"
                                       value={height}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="url">لینک:</label>
                                <input type="text"
                                       name="url"
                                       id="url"
                                       style={{direction: "ltr", textAlign: "left"}}
                                       placeholder="/"
                                       value={url}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-control">
                                <label htmlFor="type">بخش:</label>
                                <input type="text"
                                       name="type"
                                       id="type"
                                       style={{direction: "ltr", textAlign: "left"}}
                                       placeholder="main"
                                       value={type}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <br/><br/><br/><br/>
                            <div className="form-control">
                                <button type="button" onClick={this.handleSubmit.bind(this)}>ذخیره و ارسال
                                </button>
                            </div>
                        </div>
                    </div>
                </form>


            </div>
        );
    }
}


export default gpn(EditBox);