import React, {Component} from 'react';
import {gpn} from "../../GPN"
import InfiniteScroll from "react-infinite-scroller";
import noImage from "../../assets/images/no-image.jpg";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";

class News extends Component {

    state = {
        news: [],

        page: 1,
        hasNextPage: true,
        totalPages: null
    }


    getNewsList(page) {
        axios.get(`${config.apiV1}/news/all?page=${page}`)
            .then(r => {
                const {news} = r.data;

                this.setState({
                    news: [...this.state.news, ...news.docs],
                    page: page + 1,
                    hasNextPage: news.hasNextPage,
                    totalPages: news.totalPages
                });
            })
            .catch(err => {

            });
    }

    deleteNews(id) {
        toast.success("در حال حذف ...");

        axios.delete(`${config.api}/news?token=${config.token}&id=${id}`)
            .then(r => {
                toast.success("با موفقیت حذف شد.");
                setTimeout(() => {
                    document.location.href = "/news"
                }, 1500)
            })
            .catch(err => {

            });
    }

    render() {
        const {page, hasNextPage, news} = this.state;

        return (
            <div className="news-list">
                <div className="row">
                    <div className="col-auto">
                        <div className="new-news-button" onClick={() => this.props.navigate("/news/parse")}>
                            <span>+</span><span>خبر جدید</span>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={() => this.getNewsList(page)}
                    hasMore={hasNextPage}
                    loader={<div className="loader" key={0}>دریافت ...</div>}>
                    {
                        news ? news.map((p, i) =>
                            <div key={"p" + i} className="news-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <img draggable={false}
                                             src={p.image ? p.image : noImage} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="body">
                                            <div className="title">{p.title}</div>
                                            <div className="author">{p.author.fullName}</div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="delete"
                                              onDoubleClick={this.deleteNews.bind(this, p.id)}>حذف</span>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </InfiniteScroll>
            </div>
        );
    }
}

export default gpn(News);